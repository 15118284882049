import ProjectDataService from '../../services/ProjectDataService'
import { contractTerm } from './contractTerm.module'

export const project = {
  namespaced: true,
  modules: {
    contractTerm
  },
  state: {
    project: {
      id: 0,
      projectNumber: '',
      name: '',
      clientDisplayDto: {
        id: 0,
        clientName: ''
      },
      endDate: '',
      projectType: '',
      allowedUsers: [],
      voucherName: 'ROYALTEK CONSTRUCTION DEV. CORP.'
    },
    editedProject: {
      id: 0,
      name: '',
      clientDisplayDto: {
        id: 0,
        clientName: ''
      },
      endDate: '',
      projectType: '',
      allowedUsers: [],
      voucherName: 'ROYALTEK CONSTRUCTION DEV. CORP.'
    },
    defaultProject: {
      id: 0,
      name: '',
      clientDisplayDto: {
        id: 0,
        clientName: ''
      },
      startDate: '',
      endDate: '',
      projectType: '',
      allowedUsers: [],
      voucherName: 'ROYALTEK CONSTRUCTION DEV. CORP.'
    },
    projects: [],
    totalProjects: 0,
    editProjectDialog: false,
    renderKey: 0
  },
  actions: {
    getProjects ({ state, commit }, payload) {
      return ProjectDataService.retrieveAllProjects(payload.options)
    },
    getProject ({ state, commit }, id) {
      return ProjectDataService.getProjectById(id)
    },
    createProject ({ state, commit, rootState }, payload) {
      return ProjectDataService.createProject(payload)
    },
    updateProject ({ state, commit, rootState }, payload) {
      return ProjectDataService.updateProject(payload)
    },
    deleteProject ({ state, commit, rootState }, id) {
      return ProjectDataService.deleteProject(id)
    }
  },
  mutations: {
    setRenderKey (state) {
      state.renderKey += 1
    },
    setProjectSuccess (state, data) {
      state.project = data
    },
    setProjectFailure (state) {
      state.project = state.defaultProject
    },
    setProjectListSuccess (state, data) {
      state.projects = data.content
      state.totalProjects = data.totalElements
    },
    setProjectListFailure (state) {
      state.projects = []
      state.totalProjects = 0
    },
    toggleDialog (state) {
      state.editProjectDialog = !state.editProjectDialog
    },
    showDialog (state) {
      state.editProjectDialog = true
    },
    hideDialog (state) {
      state.editProjectDialog = false
    },
    setEditedProject (state, data) {
      state.editedProject = data
    }
  },
  getters: {
    project (state) {
      return state.project
    },
    totalProjects (state) {
      return state.totalProjects
    },
    projects (state) {
      return state.projects
    },
    editedProject (state) {
      return state.editedProject
    },
    renderKey (state) {
      return state.renderKey
    },
    defaultProject (state) {
      return state.defaultProject
    }
  }
}
