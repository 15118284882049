import { AXIOS } from './Axios-Common'

const URL = '/users'
class UserDataService {
  retrieveAllUsers () {
    return AXIOS.get(`${URL}`)
  }
  retrieveUsersPaginated (params) {
    return AXIOS.get(`${URL}/searchusers`, {
      params: {
        offset: params.page - 1,
        limit: params.itemsPerPage,
        searchTerm: params.searchTerm,
        sortBy: params.sortBy,
        sortDesc: params.sortDesc
      }
    })
  }
  createUser (user) {
    return AXIOS.post(`${URL}/user`, user)
  }
  updateUser (user) {
    return AXIOS.put(`${URL}/user`, user)
  }
  deleteUser (id) {
    return AXIOS.delete(`${URL}/user/${id}`)
  }
  getRoles () {
    return AXIOS.get(`${URL}/roles`)
  }
}

export default new UserDataService()
