<template>
  <v-container>
    <app-navigation></app-navigation>
    <v-container fluid>
      <router-view></router-view>
    </v-container>
    <v-footer app padless fixed inset>
      <v-row dense>
        <v-col class="text-center" cols="12">
          <span>Copyright 2018-2021 ROYALTEK CONSTRUCTION DEVELOPMENT CORPORATION</span>
        </v-col>
      </v-row>
    </v-footer>
    <overlay></overlay>
    <snackbar></snackbar>
  </v-container>
</template>

<script>
import AppNavigation from '../components/AppNavigation'
import Overlay from '../components/global/Overlay'
import Snackbar from '../components/global/Snackbar'
export default {
  name: 'ApplicationHome',
  components: {
    AppNavigation, Overlay, Snackbar
  }
}
</script>

<style scoped>

</style>
