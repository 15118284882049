import Vue from 'vue'
import VueRouter from 'vue-router'
import ProjectList from '../views/ProjectList.vue'
import ApplicationHome from '../views/ApplicationHome'
import BOMDetails from '../views/BOMDetails'
import Login from '../views/Login'
import ListSuppliersComponent from '../components/supplier/ListSuppliersComponent'
import ListClientsComponent from '../components/client/ListClientsComponent'
import ListPaymentsComponent from '../components/payment/ListPaymentsComponent'
import ListPettyCashComponent from '../components/pettycash/ListPettyCashComponent'
import ListPettyCashItemComponent from '../components/pettycash/ListPettyCashItemComponent'
import ListBOMComponent from '../components/bom/ListBOMComponent'
import VoucherSearchComponent from '../components/voucher/VoucherSearchComponent'
import ListEmployeesComponent from '../components/employee/ListEmployeesComponent'
import ListUsersComponent from '../components/user/ListUsersComponent'
import { auth } from '../store/auth/auth.module'
import LandingPage from '../views/LandingPage'
import DisplayProjectSummariesComponent from '../components/project/DisplayProjectSummariesComponent'
import ListVouchersComponent from '../components/voucher/ListVouchersComponent'
import DisplayProjectDetailsComponent from '../components/project/DisplayProjectDetailsComponent'
import ProjectHome from '../views/ProjectHome'
import LocationList from '@/views/LocationList'
import InventoryList from '@/views/InventoryList'
import InventoryItemHome from '@/views/InventoryItemHome'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/index.html',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: ApplicationHome,
    children: [
      {
        path: '',
        name: 'landingpage',
        component: LandingPage,
        meta: { title: 'Royaltek Software' }
      },
      {
        path: '/projects',
        name: 'projectList',
        component: ProjectList
      },
      {
        path: '/project/:projectnumber',
        name: 'project',
        component: ProjectHome,
        props: true,
        meta: {
          breadCrumb: 'Details'
        },
        children: [
          {
            path: '',
            component: DisplayProjectDetailsComponent,
            props: true
          },
          {
            path: '/:projectnumber/vouchers',
            name: 'vouchers',
            component: ListVouchersComponent,
            props: true
          },
          {
            path: '/:projectnumber/payments',
            name: 'payments',
            component: ListPaymentsComponent,
            props: true
          },
          {
            path: '/:projectnumber/pettycash',
            name: 'pettycash',
            component: ListPettyCashComponent,
            props: true
          },
          {
            path: '/:pettycashnumber/pettycashitems',
            component: ListPettyCashItemComponent,
            props: true
          },
          {
            path: '/:projectnumber/billofmaterials',
            component: ListBOMComponent,
            props: true
          },
          {
            path: '/:bomnumber/details',
            component: BOMDetails,
            props: true
          }
        ]
      },
      {
        path: '/suppliers',
        name: 'suppliers',
        component: ListSuppliersComponent
      },
      {
        path: '/clients',
        name: 'clients',
        component: ListClientsComponent
      },
      {
        path: '/vouchersearch',
        component: VoucherSearchComponent
      },
      {
        path: '/employees',
        component: ListEmployeesComponent
      },
      {
        path: '/users',
        component: ListUsersComponent
      },
      {
        path: '/summaries',
        component: DisplayProjectSummariesComponent
      },
      {
        path: '/locations',
        component: LocationList
      },
      {
        path: '/inventorylist',
        component: InventoryList
      },
      {
        path: '/inventoryitem/:inventoryid',
        component: InventoryItemHome,
        props: true
      }
    ]
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})
router.beforeEach((to, from, next) => {
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = auth.getters.isLoggedIn

  if (authRequired && !loggedIn) {
    next('/login')
  } else {
    next()
  }
})
export default router
