import { AXIOS } from './Axios-Common'

const URL = '/vouchers'
class VoucherDataService {
  retrieveVouchersByProjectNumber (projectNumber) {
    return AXIOS.get(`${URL}?projectnumber=${projectNumber}`)
  }
  getVoucherById (id) {
    return AXIOS.get(`${URL}/voucher/${id}`)
  }
  createVoucher (voucher) {
    return AXIOS.post(`${URL}/voucher`, voucher)
  }
  updateVoucher (voucher) {
    return AXIOS.put(`${URL}/voucher`, voucher)
  }
  deleteVoucher (id) {
    return AXIOS.delete(`${URL}/voucher/${id}`)
  }
  retrieveVoucherCategories () {
    return AXIOS.get(`${URL}/vouchercategories`)
  }
  retrieveVouchersByProjectNumberPaginated (projectNumber, params) {
    return AXIOS.get(`${URL}/searchvouchers`, {
      params: {
        offset: params.page - 1,
        limit: params.itemsPerPage,
        searchTerm: params.searchTerm,
        projectNumber: projectNumber,
        sortBy: params.sortBy,
        sortDesc: params.sortDesc
      }
    })
  }
  printSelectedVouchers (selectedVouchers) {
    return AXIOS.post(`${URL}/printvouchers`, selectedVouchers, {
      responseType: 'blob'
    })
  }
  generateExpenseReport (dateRange) {
    return AXIOS.post(`${URL}/createexpensereport`, dateRange, {
      responseType: 'blob'
    })
  }
  generateExpenseReportForProject (projectNumber) {
    return AXIOS.get(`${URL}/createexpensereport`, {
      params: {
        projectNumber: projectNumber
      },
      responseType: 'blob'
    })
  }
  generateAnnualExpenseReport (year) {
    return AXIOS.post(`${URL}/createannualexpensereport/${year}`, null, {
      responseType: 'blob'
    })
  }
  printVouchersForDate (dateRange) {
    return AXIOS.post(`${URL}/printvouchersbydate`, dateRange, {
      responseType: 'blob'
    })
  }
  getBiggestExpenses (projectNumber) {
    return AXIOS.get(`${URL}/biggestexpenses`, {
      params: {
        projectNumber: projectNumber
      }
    })
  }
  getLatestExpenses (projectNumber) {
    return AXIOS.get(`${URL}/latestexpenses`, {
      params: {
        projectNumber: projectNumber
      }
    })
  }
  getTotalExpenses (projectNumber) {
    return AXIOS.get(`${URL}/totalspend`, {
      params: {
        projectNumber: projectNumber
      }
    })
  }
  getSpendByCategory (projectNumber) {
    return AXIOS.get(`${URL}/spendbycategory`, {
      params: {
        projectNumber: projectNumber
      }
    })
  }
  searchUnpaidSuppliers (params) {
    return AXIOS.get(`${URL}/unpaidvouchers`, {
      params: {
        offset: params.page - 1,
        limit: params.itemsPerPage,
        searchTerm: params.searchTerm,
        projectNumber: params.projectNumber,
        sortBy: params.sortBy,
        sortDesc: params.sortDesc
      }
    })
  }
  getUnpaidVouchersTotalAmount (projectNumber) {
    return AXIOS.get(`${URL}/unpaidtotal`, {
      params: {
        projectNumber: projectNumber
      }
    })
  }
}

export default new VoucherDataService()
