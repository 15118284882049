import { AXIOS } from './Axios-Common'

const URL = '/employees'
class EmployeeDataService {
  getEmployeeById (id) {
    return AXIOS.get(`${URL}/employee/${id}`)
  }
  retrieveEmployeesPaginated (params) {
    return AXIOS.get(`${URL}/searchemployees`, {
      params: {
        offset: params.page - 1,
        limit: params.itemsPerPage,
        searchTerm: params.searchTerm,
        sortBy: params.sortBy,
        sortDesc: params.sortDesc
      }
    })
  }
  createEmployee (employee) {
    return AXIOS.post(`${URL}/employee`, employee)
  }
  updateEmployee (employee) {
    return AXIOS.put(`${URL}/employee`, employee)
  }
  deleteEmployee (id) {
    return AXIOS.delete(`${URL}/employee/${id}`)
  }
  getEmployeeIdTypes () {
    return AXIOS.get(`${URL}/employeeidtypes`)
  }
  findByName (name) {
    return AXIOS.get(`${URL}/findbyname`, {
      params: {
        name: name
      }
    })
  }
}
export default new EmployeeDataService()
