<template>
  <div>
    <v-card>
      <v-card-title>
        <v-sheet color="primary lighten-1" dark width="100%" max-width="100%">
          <span class="headline ma-2">Inventory List</span>
        </v-sheet>
      </v-card-title>
      <v-card-text>
        <v-data-table fixed-header
          height="400"
          :headers="headers"
          :key="renderKey"
          :items="$store.getters['inventoryitem/inventoryItems']"
          :options.sync="options"
          :server-items-length="$store.getters['inventoryitem/totalInventoryItems']"
          :loading="loading"
          :footer-props="{ 'items-per-page-options':[5,10,30,100,300] }"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" @keyup="searchItemsDebounced" single-line hide-details />
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="primary" dark class="ma-2" @click="openDialog">
                    <v-icon>mdi-plus-box</v-icon>
                  </v-btn>
                </template>
                <span>Add New Inventory Item</span>
              </v-tooltip>
            </v-toolbar>
          </template>
          <template v-slot:item.dateAcquired="{ item }">
            {{ $d(new Date(item.dateAcquired), 'short') }}
          </template>
          <template v-slot:item.item="{ item }">
            <router-link :to="'/inventoryitem/' + item.id"  class="body-1 font-weight-bold">{{ item.item }}</router-link>
          </template>
          <template v-slot:item.action="{item}">
            <v-icon small class="mr-2" @click="editInventoryItem(item)">mdi-pencil</v-icon>
            <v-icon small @click="deleteInventoryItem(item.id)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <save-inventory-item-dialog></save-inventory-item-dialog>
    <overlay></overlay>
    <snackbar></snackbar>
  </div>
</template>

<script>
import SaveInventoryItemDialog from '@/components/inventory/item/SaveInventoryItemDialog'
import Overlay from '@/components/global/Overlay'
import Snackbar from '@/components/global/Snackbar'
export default {
  name: 'InventoryItemsList',
  components: { Snackbar, Overlay, SaveInventoryItemDialog },
  data () {
    return {
      search: '',
      loading: true,
      options: {},
      headers: [
        { text: 'Item #', align: 'center', sortable: true, value: 'inventoryId' },
        { text: 'Item Name', align: 'center', sortable: true, value: 'item' },
        { text: 'Quantity', align: 'left', sortable: true, value: 'quantity' },
        { text: 'Date Acquired', align: 'left', sortable: true, value: 'dateAcquired' },
        { text: 'Actions', sortable: false, value: 'action' }
      ],
      searchTimer: null
    }
  },
  computed: {
    renderKey () {
      return this.$store.getters['inventoryitem/renderKey']
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    options: {
      handler () {
        this.getAllInventoryItems()
      },
      deep: true
    }
  },
  methods: {
    searchItemsDebounced () {
      clearTimeout(this.searchTimer)
      if (this.search === '' || this.search == null || this.search.length >= 3) {
        this.searchTimer = setTimeout(() => {
          this.getAllInventoryItems()
        }, 500)
      }
    },
    async getAllInventoryItems () {
      this.loading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      if (itemsPerPage > 0) {
        let searchParams = {}
        searchParams.page = page
        searchParams.itemsPerPage = itemsPerPage
        if (sortBy.length > 0) {
          searchParams.sortBy = sortBy[0]
        }
        if (sortDesc.length > 0) {
          searchParams.sortDesc = sortDesc[0]
        }
        if (this.search) {
          searchParams.searchTerm = this.search
        }
        let payload = {}
        payload.options = searchParams

        try {
          let response = await this.$store.dispatch('inventoryitem/getInventoryItems', payload)
          this.$store.commit('inventoryitem/setInventoryItemListSuccess', response.data)
        } catch (err) {
          console.error(err)
          this.$store.commit('inventoryitem/setInventoryItemListFailure')
        } finally {
          this.loading = false
        }
      }
    },
    async deleteInventoryItem (id) {
      if (confirm('Do you want to delete this Item?')) {
        try {
          this.$store.commit('setOverlay', { text: 'Deleting Item...', overlayState: true })
          let response = await this.$store.dispatch('inventoryitem/deleteInventoryItem', id)
          this.$store.commit('setOverlay', { text: 'Deleting Item...', overlayState: false })
          this.$store.commit('showMessage', { message: response.data, color: 'success' })
        } catch (err) {
          console.error(err)
          this.$store.commit('setOverlay', { text: 'Deleting Item...', overlayState: false })
          this.$store.commit('showMessage', { message: 'Delete Item: ' + err.response.status + ': ' + err.response.data, color: 'error' })
        } finally {
          this.$store.commit('inventoryitem/setRenderKey')
        }
      }
    },
    async editInventoryItem (item) {
      try {
        this.$store.commit('setOverlay', { text: 'Retrieving Item Details', overlayState: true })
        let response = await this.$store.dispatch('inventoryitem/getInventoryItem', item.id)
        this.$store.commit('inventoryitem/setInventoryItemSuccess', response.data)
        this.$store.commit('inventoryitem/setEditedInventoryItem', response.data)
        this.$store.commit('inventoryitem/showDialog')
      } catch (err) {
        console.error(err)
        this.$store.commit('inventoryitem/setInventoryItemFailure')
      } finally {
        this.$store.commit('setOverlay', { text: 'Retrieving Item Details', overlayState: false })
      }
    },
    openDialog () {
      this.$store.commit('inventoryitem/setEditedInventoryItem', Object.assign({}, this.$store.getters['inventoryitem/defaultInventoryItem']))
      this.$store.commit('inventoryitem/showDialog')
    }
  }
}
</script>

<style scoped>

</style>
