<template>
  <v-container>
    <v-dialog persistent v-model="dialog" max-width="1500px">
      <v-card>
        <v-form>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6">
                  <v-card>
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="12">
                          <v-select required v-model="editedPayment.contractTermId" :items="contractTerms" autofocus
                            item-text="description" item-value="id" label="Select a Contract Term" :error-messages="contractTermErrors"
                            @input="$v.editedPayment.contractTermId.$touch()" @blur="$v.editedPayment.contractTermId.$touch()"
                          ></v-select>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="editedPayment.rcdcORNumber" label="RCDC OR Number"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-menu v-model="paymentDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                                  offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field label="Payment Date" prepend-inner-icon="mdi-calendar" readonly :value="paymentDateDisp"
                                            required :error-messages="dateErrors" @input="$v.editedPayment.paymentDate.$touch()"
                                            @blur="$v.editedPayment.paymentDate.$touch()" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="editedPayment.paymentDate" no-title @input="paymentDateMenu = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="editedPayment.description" label="Description"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card>
                    <v-card-text>
                      <v-col cols="12">
                        <v-autocomplete v-model="editedPayment.clientDto" :items="clients" item-text="clientName"
                                        item-value="id" :loading="loadingClients" :search-input.sync="searchClient"
                                        :error-messages="clientErrors" @input="$v.editedPayment.clientDto.id.$touch()"
                                        @blur="$v.editedPayment.clientDto.id.$touch()" filled label="Choose a client" return-object>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-row dense>
                          <v-col cols="12" sm="4">
                            <v-switch v-model="editedPayment.taxWithheld" label="Tax Withheld"></v-switch>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-currency-field readonly v-model="editedPayment.tax" label="Total Withheld"></v-currency-field>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-currency-field readonly v-model="editedPayment.taxableAmount" label="Taxable Amount"></v-currency-field>
                          </v-col>
                        </v-row>
                        <v-currency-field label="Amount" :error-messages="errors.rate" v-model.number="editedPayment.amount"></v-currency-field>
                      </v-col>
                      <v-row dense>
                        <v-col cols="12" sm="6">
                          <v-file-input v-model="fileToUpload" label="Attach scanned receipt" clearable></v-file-input>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="editedPayment.scannedReceipt">
                          <v-btn color="primary lighten-1" @click="scannedReceiptDialog = !scannedReceiptDialog">View Scanned Receipt</v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" dark @click="save">Save</v-btn>
            <v-btn color="orange darken-1" dark @click="clear">Clear</v-btn>
            <v-btn color="red darken-1" dark @click="close">Cancel</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="scannedReceiptDialog" width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Scanned Receipt</span>
        </v-card-title>
        <v-card-text>
          <div style="width: 400px">
            <zoom-on-hover :img-normal="'data:' + editedPayment.contentType + ';base64,' + editedPayment.scannedReceipt"></zoom-on-hover>
            <!--<v-img max-width="50%" max-height="50%" :src="'data:' + editedVoucher.contentType + ';base64,' + editedVoucher.scannedReceipt"></v-img>-->
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" @click="scannedReceiptDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { minValue, required } from 'vuelidate/lib/validators'
import ClientDataService from '../../services/ClientDataService'

export default {
  name: 'SaveCollectionDialog',
  data () {
    return {
      paymentDateMenu: false,
      fileToUpload: null,
      errors: {},
      searchClientTimer: null,
      clients: [],
      loadingClients: false,
      searchClient: null,
      scannedReceiptDialog: false
    }
  },
  validations: {
    editedPayment: {
      paymentDate: { required },
      contractTermId: { minValue: minValue(1) },
      clientDto: {
        id: { minValue: minValue(1) }
      }
    }
  },
  created () {
    this.findClientsByName('')
  },
  computed: {
    contractTerms () {
      return this.$store.getters['project/contractTerm/unPaginated/contractTerms']
    },
    editedPayment: {
      get () {
        return this.$store.state.collections.editedPayment
      },
      set (value) {
        this.$store.commit('collections/setEditedPaymentSuccess', value)
      }
    },
    formTitle () {
      if (this.editedPayment.id > 0) {
        return 'Edit Collection ' + this.editedPayment.receiptNumber
      } else {
        return 'New Collection for Project ' + this.editedPayment.projectNumber
      }
    },
    dialog: {
      get () {
        return this.$store.state.collections.editPaymentDialog
      },
      set () {
        this.$store.commit('expenses/toggleDialog')
      }
    },
    paymentDateDisp () {
      return this.editedPayment.paymentDate
    },
    contractTermErrors () {
      const errors = []
      if (!this.$v.editedPayment.contractTermId.$dirty) return errors
      !this.$v.editedPayment.contractTermId.minValue && errors.push('Please select a Contract Term')
      return errors
    },
    dateErrors () {
      const errors = []
      if (!this.$v.editedPayment.paymentDate.$dirty) return errors
      !this.$v.editedPayment.paymentDate.required && errors.push('Please enter payment date')
      return errors
    },
    clientErrors () {
      const errors = []
      if (!this.$v.editedPayment.clientDto.id.$dirty) return errors
      !this.$v.editedPayment.clientDto.id.minValue && errors.push('Please select a client')
      return errors
    }
  },
  watch: {
    searchClient (value) {
      if (!value) {
        value = ''
      }
      this.searchClientsDebounced(value)
    }
  },
  methods: {
    searchClientsDebounced (value) {
      clearTimeout(this.searchClientTimer)
      if (this.searchClient === '' || this.searchClient == null || this.searchClient.length >= 3) {
        this.searchClientTimer = setTimeout(() => {
          this.findClientsByName(value)
        }, 500)
      }
    },
    findClientsByName (value) {
      if (!value) {
        this.clients = []
      }

      this.loadingClients = true
      return ClientDataService.findByName(value).then(response => {
        this.clients = response.data
        this.loadingClients = false
      }, reject => {
        this.loadingClients = false
        this.$store.commit('showMessage', { message: 'Search Client failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
      })
    },
    save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let createFormData = new FormData()
        createFormData.append('file', this.fileToUpload)
        if (this.editedPayment.id > 0) {
          createFormData.append('paymentUpdateDTO', new Blob([JSON.stringify(this.editedPayment)], { type: 'application/json' }))
          this.$store.dispatch('collections/updateCollection', createFormData).then(response => {
            this.close()
            this.$store.dispatch('collections/getTotalPaymentAmount', this.$store.getters['project/project'].projectNumber)
          }, reject => {
            this.close()
          })
        } else {
          createFormData.append('paymentCreateDTO', new Blob([JSON.stringify(this.editedPayment)], { type: 'application/json' }))
          this.$store.dispatch('collections/createCollection', createFormData).then(response => {
            this.close()
            this.$store.dispatch('collections/getTotalPaymentAmount', this.$store.getters['project/project'].projectNumber)
          }, reject => {
            this.close()
          })
        }
      }
    },
    clear () {
      this.$v.$reset()
      this.editedPayment = this.$store.getters['collections/defaultPayment']
      this.editedPayment.projectNumber = this.$store.getters['collections/getPaymentProjectNumber']
      this.fileToUpload = null
      this.searchClient = null
    },
    close () {
      this.clear()
      this.$store.commit('collections/hideDialog')
    }
  }
}
</script>

<style scoped>

</style>
