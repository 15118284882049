<template>
  <v-container class="fill-height royaltek-background-blue" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" align="center">
        <v-card outlined align="center" max-width="375px">
          <v-img class="black--text align-end" src="../assets/images/logo.jpg">
          </v-img>
          <v-form>
            <v-card-text>
              <v-text-field
                label="Login" v-model="user.username" :error-messages="usernameErrors" @input="$v.user.username.$touch()" @keyup.enter="handleLogin"
                @blur="$v.user.username.$touch()" type="text" prepend-icon="mdi-account" autofocus required></v-text-field>
              <v-text-field
                label="Password" v-model="user.password" :error-messages="passwordErrors" @input="$v.user.password.$touch()"
                @blur="$v.user.password.$touch()" type="password" prepend-icon="mdi-lock" @keyup.enter="handleLogin" required></v-text-field>
              <div class="form-group">
                <div v-if="message" class="alert alert-danger" role="alert">{{message}}</div>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" :loading="loading" @keyup.enter="handleLogin" @click="handleLogin">Login</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import User from '../models/User'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'Login',
  data () {
    return {
      user: new User('', ''),
      loading: false,
      message: ''
    }
  },
  validations: {
    user: {
      username: { required },
      password: { required }
    }
  },
  computed: {
    loggedIn () {
      return this.$store.state.auth.isLoggedIn
    },
    usernameErrors () {
      const errors = []
      if (!this.$v.user.username.$dirty) return errors
      !this.$v.user.username.required && errors.push('Username is required')
      return errors
    },
    passwordErrors () {
      const errors = []
      if (!this.$v.user.password.$dirty) return errors
      !this.$v.user.password.required && errors.push('Password is required')
      return errors
    }
  },
  created () {
    if (this.loggedIn) {
      this.$router.push('/home')
    }
  },
  methods: {
    handleLogin () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.loading = true
        if (this.user.username && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
            (result) => {
              this.$store.commit('auth/loginSuccess', result)
              this.$router.push('/home')
              this.loading = false
            },
            error => {
              this.loading = false
              this.message = (error.response && error.response.data) || error.message || error.toString()
            }
          )
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
