import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './auth/auth.module'
import { expenses } from './expense/expenses.module'
import { collections } from './collection/collections.module'
import { project } from './project/project.module'
import { pettyCash } from './pettycash/pettyCash.module'
import { location } from '@/store/location/location.module'
import { inventoryitem } from '@/store/inventory/inventoryitem.module'

Vue.use(Vuex)
Vue.config.devtools = true

export default new Vuex.Store({
  modules: {
    auth,
    expenses,
    collections,
    project,
    pettyCash,
    location,
    inventoryitem
  },
  state: {
    projectNumber: '',
    clients: [],
    users: [],
    voucherCategories: [],
    bomCategories: [],
    units: [],
    snackbarColor: '',
    snackbarMessage: '',
    drawer: false,
    overlayState: false,
    overlayText: '',
    years: [],
    employeeIdTypes: [],
    roles: [],
    totalSpend: 0,
    totalPayment: 0,
    locations: [],
    companyInfo: {
      id: 0,
      companyName: '',
      buildingNumber: '',
      street: '',
      town: '',
      city: '',
      country: {
        countryName: '',
        abbreviation: ''
      },
      sssNumber: '',
      tinNumber: '',
      philhealthNumber: '',
      hdmfNumber: '',
      pcabLicenseNumber: '',
      secRegistrationNumber: '',
      phoneNumber: ''
    },
    packageVersion: process.env.PACKAGE_VERSION || '0.0.0'
  },
  mutations: {
    setProjectNumber (state, projectNumber) {
      state.projectNumber = projectNumber
    },
    setUsers (state, users) {
      state.users = users
    },
    setVoucherCategories (state, categories) {
      state.voucherCategories = categories
    },
    setBOMCategories (state, categories) {
      state.bomCategories = categories
    },
    setUnits (state, units) {
      state.units = units
    },
    showMessage (state, payload) {
      state.snackbarMessage = payload.message
      state.snackbarColor = payload.color
    },
    setDrawer (state, drawer) {
      state.drawer = drawer
    },
    setOverlay (state, payload) {
      state.overlayText = payload.text
      state.overlayState = payload.overlayState
    },
    setYears (state, years) {
      state.years = years
    },
    setEmployeeIdTypes (state, idTypes) {
      state.employeeIdTypes = idTypes
    },
    setRoles (state, roles) {
      state.roles = roles
    },
    setTotalSpend (state, spend) {
      state.totalSpend = spend
    },
    setTotalPayment (state, payment) {
      state.totalPayment = payment
    },
    setCompanyInfo (state, company) {
      state.companyInfo = company
    },
    setClients (state, clients) {
      state.clients = clients
    },
    setLocations (state, locations) {
      state.locations = locations
    }
  },
  getters: {
    projectNumber: state => state.projectNumber,
    users: state => state.users,
    voucherCategories: state => state.voucherCategories,
    bomCategories: state => state.bomCategories,
    units: state => state.units,
    snackBarMessage: state => state.snackbarMessage,
    snackBarColor: state => state.snackbarColor,
    drawer: state => state.drawer,
    overlayState: state => state.overlayState,
    overlayText: state => state.overlayText,
    years: state => state.years,
    employeeIdTypes: state => state.employeeIdTypes,
    roles: state => state.roles,
    totalSpend: state => state.totalSpend,
    totalPayment: state => state.totalPayment,
    companyInfo: state => state.companyInfo,
    clients: state => state.clients,
    appVersion: state => state.packageVersion,
    locations: state => state.locations
  }
})
