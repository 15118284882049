import VoucherDataService from '../../services/VoucherDataService'

export const expenses = {
  namespaced: true,
  state: {
    totalExpenses: 0,
    expenses: [],
    editVoucherDialog: false,
    editedVoucher: {
      id: 0,
      projectNumber: '',
      voucherNumber: '',
      voucherDate: '',
      amount: 0,
      description: '',
      tin: '',
      city: '',
      vatableAmount: 0,
      vat: 0,
      receiptNo: '',
      voucherCategory: {
        category: 0,
        description: ''
      },
      paidTo: '',
      vatable: false,
      supplierDto: {
        id: 0,
        name: '',
        address: '',
        tin: '',
        email: '',
        contactNumber: '',
        contactPerson: '',
        city: ''
      },
      contentType: '',
      scannedReceipt: '',
      paid: true
    },
    defaultVoucher: {
      id: 0,
      projectNumber: '',
      voucherNumber: '',
      voucherDate: '',
      amount: 0,
      description: '',
      tin: '',
      city: '',
      vatableAmount: 0,
      vat: 0,
      receiptNo: '',
      voucherCategory: {
        category: 0,
        description: ''
      },
      paidTo: '',
      vatable: false,
      supplierDto: {
        id: 0,
        name: '',
        address: '',
        tin: '',
        email: '',
        contactNumber: '',
        contactPerson: '',
        city: ''
      },
      contentType: '',
      scannedReceipt: '',
      paid: true
    },
    renderKey: 0,
    totalSpendAmount: 0
  },
  actions: {
    getDataFromApi ({ state, commit }, payload) {
      return VoucherDataService.retrieveVouchersByProjectNumberPaginated(payload.projectNumber, payload.options).then(response => {
        commit('setExpensesSuccess', response.data)
      }, reject => {
        commit('setExpensesFailure')
      })
    },
    getTotalSpendAmount ({ state, commit }, projectNumber) {
      return VoucherDataService.getTotalExpenses(projectNumber).then(response => {
        commit('setTotalSpendAmount', response.data)
      }, reject => {
        commit('setTotalSpendAmount', 0)
      })
    },
    getExpense ({ state, commit }, id) {
      commit('setOverlay', { text: 'Fetching Expense Details', overlayState: true }, { root: true })
      return VoucherDataService.getVoucherById(id).then(response => {
        commit('setOverlay', { text: 'Fetching Expense Details', overlayState: false }, { root: true })
        commit('setEditedVoucherSuccess', response.data)
      }, reject => {
        commit('setOverlay', { text: 'Fetching Expense Details', overlayState: false }, { root: true })
        commit('setEditedVoucherFailure')
      })
    },
    createExpense ({ state, commit, rootState }, payload) {
      commit('setOverlay', { text: 'Creating Expense...', overlayState: true }, { root: true })
      return VoucherDataService.createVoucher(payload).then(response => {
        commit('setOverlay', { text: 'Creating Expense...', overlayState: false }, { root: true })
        commit('showMessage', { message: response.data, color: 'success' }, { root: true })
        commit('setRenderKey')
        commit('setEditedVoucherSuccess', Object.assign({}, state.defaultVoucher))
        commit('setExpenseProjectNumber', rootState.project.project.projectNumber)
      }, reject => {
        commit('setOverlay', { text: 'Creating Expense...', overlayState: false }, { root: true })
        commit('showMessage', { message: 'Create Expense failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' }, { root: true })
        commit('setRenderKey')
        commit('setEditedVoucherSuccess', Object.assign({}, state.defaultVoucher))
        commit('setExpenseProjectNumber', rootState.project.project.projectNumber)
      })
    },
    updateExpense ({ state, commit, rootState }, payload) {
      commit('setOverlay', { text: 'Updating Expense...', overlayState: true }, { root: true })
      return VoucherDataService.updateVoucher(payload).then(response => {
        commit('setOverlay', { text: 'Updating Expense...', overlayState: false }, { root: true })
        commit('showMessage', { message: response.data, color: 'success' }, { root: true })
        commit('setRenderKey')
        commit('setEditedVoucherSuccess', Object.assign({}, state.defaultVoucher))
        commit('setExpenseProjectNumber', rootState.project.project.projectNumber)
      }, reject => {
        commit('setOverlay', { text: 'Updating Expense...', overlayState: false }, { root: true })
        commit('showMessage', { message: 'Update Expense failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' }, { root: true })
        commit('setRenderKey')
        commit('setEditedVoucherSuccess', Object.assign({}, state.defaultVoucher))
        commit('setExpenseProjectNumber', rootState.project.project.projectNumber)
      })
    },
    deleteExpense ({ state, commit }, id) {
      commit('setOverlay', { text: 'Deleting Expense...', overlayState: true }, { root: true })
      return VoucherDataService.deleteVoucher(id).then(response => {
        commit('setOverlay', { text: 'Deleting Expense...', overlayState: false }, { root: true })
        commit('showMessage', { message: response.data, color: 'success' }, { root: true })
        commit('setRenderKey')
      }, reject => {
        commit('setOverlay', { text: 'Deleting Expense...', overlayState: false }, { root: true })
        commit('showMessage', { message: 'Delete Expense failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' }, { root: true })
        commit('setRenderKey')
      })
    }
  },
  mutations: {
    setRenderKey (state) {
      state.renderKey += 1
    },
    setExpensesSuccess (state, data) {
      state.expenses = data.content
      state.totalExpenses = data.totalElements
    },
    setExpensesFailure (state) {
      state.expenses = []
      state.totalExpenses = 0
    },
    setEditedVoucherSuccess (state, voucher) {
      state.editedVoucher = voucher
    },
    setEditedVoucherFailure (state, getters, rootState) {
      state.editedVoucher = Object.assign({}, state.defaultVoucher)
      state.editedVoucher.projectNumber = rootState.project.project.projectNumber
    },
    toggleDialog (state) {
      state.editVoucherDialog = !state.editVoucherDialog
    },
    setDialog (state, dialog) {
      state.editVoucherDialog = dialog
    },
    showDialog (state) {
      state.editVoucherDialog = true
    },
    hideDialog (state) {
      state.editVoucherDialog = false
    },
    setTotalSpendAmount (state, amount) {
      state.totalSpendAmount = amount
    },
    setExpenseProjectNumber (state, data) {
      state.editedVoucher.projectNumber = data
    }
  },
  getters: {
    totalExpenses (state) {
      return state.totalExpenses
    },
    expenses (state) {
      return state.expenses
    },
    editedVoucher (state) {
      return state.editedVoucher
    },
    getEditVoucherDialog (state) {
      return state.editVoucherDialog
    },
    getVoucherProjectNumber (state) {
      return state.editedVoucher.projectNumber
    },
    renderKey (state) {
      return state.renderKey
    },
    defaultVoucher (state) {
      return state.defaultVoucher
    },
    getTotalSpendAmount (state) {
      return state.totalSpendAmount
    }
  }
}
