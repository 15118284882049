<template>
  <v-col cols="12">
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">Category</th>
          <th class="text-left">Amount</th>
          <th class="text-left">%</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in expensesByCategory" :key="index">
          <td>{{ item.voucherCategory.description }}</td>
          <td>{{ $n(item.total, 'currency') }}</td>
          <td>{{ $n(item.percentage, 'percentage') }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-col>
</template>

<script>
import VoucherDataService from '../../services/VoucherDataService'
export default {
  name: 'DisplayProjectExpensesComponent',
  props: {
    projectNumber: String
  },
  data: function () {
    return {
      expensesByCategory: [],
      totalSpend: 0
    }
  },
  created () {
    this.getSpendByCategory(this.projectNumber)
  },
  methods: {
    getSpendByCategory (projectNumber) {
      VoucherDataService.getSpendByCategory(projectNumber).then(response => {
        this.expensesByCategory = response.data
      })
    }
  }
}
</script>

<style scoped>

</style>
