<template>
  <div>
    <div>
      <v-sheet color="primary lighten-1" dark width="100%" max-width="100%">
        <span class="ma-2 headline">Petty Cash List for {{project.name}}</span>
      </v-sheet>
    </div>
    <div>
      <v-data-table
        fixed-header
        height="400"
        v-model="selectedPettyCash"
        :headers="headers"
        :items="pettyCash"
        :options.sync="options"
        :server-items-length="totalPettyCash"
        :loading="loading"
        :footer-props="{
        'items-per-page-options':[5,10,30,100,300],
        'showFirstLastPage': true,
        'showCurrentPage': true
      }"
        show-select
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-btn color="primary" dark class="ma-2" @click="generatePettyCashReportFromSelectedItems">Generate Petty Cash Report For Selected Items</v-btn>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details @keyup="searchDebounced" />
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-dialog persistent v-model="dialog" max-width="750px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2" v-on="on">New Petty Cash</v-btn>
              </template>
              <v-card>
                <v-form v-model="valid">
                  <v-card-title>
                    <span class="headline">{{formTitle}}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row dense>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="editedPettyCash.employeeDto"
                            :items="employees"
                            autofocus
                            item-text="fullName"
                            item-value="id"
                            :error-messages="employeeErrors"
                            :loading="loadingEmployees"
                            :search-input.sync="searchEmployee"
                            cache-items
                            @input="$v.editedPettyCash.employeeDto.id.$touch()"
                            @blur="$v.editedPettyCash.employeeDto.id.$touch()"
                            filled label="Choose an employee"
                            return-object>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <v-menu v-model="pettyCashDateMenu"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field label="Date"
                                            prepend-inner-icon="mdi-calendar"
                                            readonly
                                            required
                                            :error-messages="dateErrors"
                                            @input="$v.editedPettyCash.date.$touch()"
                                            @blur="$v.editedPettyCash.date.$touch()"
                                            :value="pettyCashDateDisp"
                                            v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="editedPettyCash.date" no-title @input="pettyCashDateMenu = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="editedPettyCash.description" label="Description"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-currency-field label="Amount" :error-messages="errors.rate" v-model.number="editedPettyCash.amount"></v-currency-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            required
                            :error-messages="paymentSourceErrors"
                            @input="$v.editedPettyCash.paymentSource.$touch()"
                            @blur="$v.editedPettyCash.paymentSource.$touch()"
                            v-model="editedPettyCash.paymentSource" label="Payment Source"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" dark @click="save">Save</v-btn>
                    <v-btn color="orange darken-1" dark @click="clear">Clear</v-btn>
                    <v-btn color="red darken-1" dark @click="close">Cancel</v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.pettyCashNumber="{ item }">
          <router-link :to="'/' + item.id + '/pettycashitems'" class="body-1 font-weight-bold">{{ item.pettyCashNumber }}</router-link>
        </template>
        <template v-slot:item.amount="{ item }">
          {{$n(item.amount, 'currency')}}
        </template>
        <template v-slot:item.totalReceiptsAmount="{ item }">
          {{$n(item.totalReceiptsAmount, 'currency')}}
        </template>
        <template v-slot:item.balance="{ item }">
          {{$n(item.balance, 'currency')}}
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon small class="mr-2" @click="editPettyCash(item)">mdi-pencil</v-icon>
          <v-icon small @click="deletePettyCash(item.id)">mdi-delete</v-icon>
        </template>
        <template v-slot:item.date="{ item }">
          {{ $d(new Date(item.date), 'short') }}
        </template>
      </v-data-table>
    </div>
    <div>
      <v-row dense>
        <v-col cols="12" sm="6" md="6">
          <v-btn color="primary lighten-1" dark class="ma-2" @click="generatePettyCashReportForProject">Generate Petty Cash Report for Project</v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import PettyCashDataService from '../../services/PettyCashDataService'
import EmployeeDataService from '../../services/EmployeeDataService'
import { required, minValue } from 'vuelidate/lib/validators'

export default {
  name: 'ListPettyCashComponent',
  props: {
    parentProject: Object
  },
  data () {
    return {
      project: this.parentProject,
      search: '',
      valid: false,
      loading: true,
      totalPettyCash: 0,
      pettyCash: [],
      selectedPettyCash: [],
      options: {},
      errors: {},
      headers: [
        { text: 'Petty Cash Number', align: 'center', sortable: true, value: 'pettyCashNumber' },
        { text: 'Paid To', align: 'center', sortable: true, value: 'employeeName' },
        { text: 'Description', align: 'center', sortable: true, value: 'description' },
        { text: 'Date', align: 'center', sortable: true, value: 'date' },
        { text: 'Amount', align: 'right', sortable: true, value: 'amount' },
        { text: 'Total Receipts', align: 'right', sortable: true, value: 'totalReceiptsAmount' },
        { text: 'Balance', align: 'right', sortable: true, value: 'balance' },
        { text: 'Actions', align: 'center', sortable: false, value: 'action' }
      ],
      dialog: false,
      pettyCashDateMenu: false,
      editedIndex: -1,
      editedPettyCash: {
        id: 0,
        projectId: this.parentProject.id,
        projectNumber: this.parentProject.projectNumber,
        pettyCashNumber: '',
        amount: 0,
        description: '',
        date: '',
        paymentSource: '',
        employeeDto: {
          id: 0,
          firstName: '',
          lastName: '',
          fullName: ''
        }
      },
      defaultPettyCash: {
        id: 0,
        projectId: this.parentProject.id,
        projectNumber: this.parentProject.projectNumber,
        pettyCashNumber: '',
        amount: 0,
        description: '',
        date: '',
        paymentSource: '',
        employeeDto: {
          id: 0,
          firstName: '',
          lastName: '',
          fullName: ''
        }
      },
      searchTimer: null,
      searchEmployeeTimer: null,
      employees: [],
      loadingEmployees: false,
      searchEmployee: null
    }
  },
  validations: {
    editedPettyCash: {
      date: { required },
      paymentSource: { required },
      employeeDto: {
        id: { minValue: minValue(1) }
      }
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1
        ? 'New Petty Cash for ' + this.project.name : 'Edit PettyCash ' + this.editedPettyCash.pettyCashNumber
    },
    pettyCashDateDisp () {
      return this.editedPettyCash.date
    },
    dateErrors () {
      const errors = []
      if (!this.$v.editedPettyCash.date.$dirty) return errors
      !this.$v.editedPettyCash.date.required && errors.push('Please enter a date')
      return errors
    },
    paymentSourceErrors () {
      const errors = []
      if (!this.$v.editedPettyCash.paymentSource.$dirty) return errors
      !this.$v.editedPettyCash.paymentSource.required && errors.push('Please enter payment source')
      return errors
    },
    employeeErrors () {
      const errors = []
      if (!this.$v.editedPettyCash.employeeDto.id.$dirty) return errors
      !this.$v.editedPettyCash.employeeDto.id.minValue && errors.push('Please select an employee')
      return errors
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    options: {
      handler () {
        this.getDataFromApi(this.project.projectNumber)
      },
      deep: true
    },
    searchEmployee (value) {
      if (!value) {
        return
      }
      this.searchEmployeesDebounced(value)
    }
  },
  created () {
    this.getDataFromApi(this.project.projectNumber)
  },
  methods: {
    searchEmployeesDebounced (value) {
      clearTimeout(this.searchEmployeeTimer)
      if (this.searchEmployee === '' || this.searchEmployee == null || this.searchEmployee.length >= 3) {
        this.searchEmployeeTimer = setTimeout(() => {
          this.findEmployeesByName(value)
        }, 500)
      }
    },
    searchDebounced () {
      clearTimeout(this.searchTimer)
      if (this.search === '' || this.search == null || this.search.length >= 3) {
        this.searchTimer = setTimeout(() => {
          this.getDataFromApi(this.project.projectNumber)
        }, 500)
      }
    },
    findEmployeesByName (value) {
      if (!value) {
        this.employees = []
      }

      this.loadingEmployees = true
      return EmployeeDataService.findByName(value).then(response => {
        this.employees = response.data
        this.loadingEmployees = false
      }, reject => {
        this.loadingEmployees = false
        this.$store.commit('showMessage', { message: 'Search Employee failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
      })
    },
    getDataFromApi (projectNumber) {
      this.loading = true
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (itemsPerPage > 0) {
          let searchParams = {}
          searchParams.page = page
          searchParams.itemsPerPage = itemsPerPage
          if (sortBy.length > 0) {
            searchParams.sortBy = sortBy[0]
          }
          if (sortDesc.length > 0) {
            searchParams.sortDesc = sortDesc[0]
          }
          searchParams.projectNumber = projectNumber
          if (this.search) {
            searchParams.searchTerm = this.search
          }
          return PettyCashDataService.retrievePettyCashPaginated(projectNumber, searchParams).then(response => {
            this.pettyCash = response.data.content
            this.totalPettyCash = response.data.totalElements
            this.loading = false
          })
        }
      })
    },
    close () {
      this.dialog = false
      this.$v.$reset()
      setTimeout(() => {
        this.editedPettyCash = Object.assign({}, this.defaultPettyCash)
        this.editedIndex = -1
        this.searchEmployee = null
      }, 300)
    },
    save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.editedIndex > -1) {
          this.$store.commit('setOverlay', { text: 'Updating Petty Cash', overlayState: true })
          return PettyCashDataService.updatePettyCash(this.editedPettyCash).then(response => {
            this.$store.commit('setOverlay', { text: 'Updating Petty Cash', overlayState: false })
            this.$store.commit('showMessage', { message: response.data, color: 'success' })
            this.getDataFromApi(this.project.projectNumber)
            this.close()
          }, reject => {
            this.$store.commit('setOverlay', { text: 'Updating Petty Cash', overlayState: false })
            this.$store.commit('showMessage', { message: 'Update Petty Cash failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
            this.close()
          })
        } else {
          this.$store.commit('setOverlay', { text: 'Creating Petty Cash', overlayState: true })
          return PettyCashDataService.createPettyCash(this.editedPettyCash).then(response => {
            this.$store.commit('setOverlay', { text: 'Creating Petty Cash', overlayState: false })
            this.$store.commit('showMessage', { message: response.data, color: 'success' })
            this.getDataFromApi(this.project.projectNumber)
            this.close()
          }, reject => {
            this.$store.commit('setOverlay', { text: 'Creating Petty Cash', overlayState: false })
            this.$store.commit('showMessage', { message: 'Create Petty Cash failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
            this.close()
          })
        }
      }
    },
    editPettyCash (item) {
      this.editedIndex = this.pettyCash.indexOf(item)
      this.dialog = true
      PettyCashDataService.getPettyCashById(item.id).then(response => {
        this.editedPettyCash = response.data
        this.employees = []
        if (this.editedPettyCash.employeeDto.id > 0) {
          this.employees.push(this.editedPettyCash.employeeDto)
        }
      })
    },
    deletePettyCash (id) {
      if (confirm('Do you want to delete this Petty Cash?')) {
        this.$store.commit('setOverlay', { text: 'Deleting Petty Cash', overlayState: true })
        PettyCashDataService.deletePettyCash(id).then(response => {
          this.$store.commit('setOverlay', { text: 'Deleting Petty Cash', overlayState: false })
          this.$store.commit('showMessage', { message: response.data, color: 'success' })
          this.getDataFromApi(this.project.projectNumber)
        }, reject => {
          this.$store.commit('setOverlay', { text: 'Deleting Petty Cash', overlayState: false })
          this.$store.commit('showMessage', { message: 'Delete Petty Cash failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
          this.close()
        })
      }
    },
    employeeFilter (item, queryText, itemText) {
      const employeeFirstName = item.firstName.toLowerCase()
      const employeeLastName = item.lastName.toLowerCase()
      const searchText = queryText.toLowerCase()

      return searchText.length > 2 && (employeeFirstName.indexOf(searchText) > -1 || employeeLastName.indexOf(searchText) > -1)
    },
    generatePettyCashReportFromSelectedItems () {
      this.$store.commit('setOverlay', { text: 'Generating Petty Cash Report', overlayState: true })
      return PettyCashDataService.generatePettyCashReportForSelectedItems(this.selectedPettyCash).then(response => {
        this.$store.commit('setOverlay', { text: 'Generating Petty Cash Report', overlayState: false })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.project.projectNumber + '_pettycashreport.xlsx')
        document.body.appendChild(link)
        link.click()
      }, reject => {
        this.$store.commit('setOverlay', { text: 'Generating Petty Cash Report', overlayState: false })
        this.$store.commit('showMessage', { message: 'Generate Petty Cash Report failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
        this.close()
      })
    },
    generatePettyCashReportForProject () {
      this.$store.commit('setOverlay', { text: 'Generating Petty Cash Report for Project', overlayState: true })
      return PettyCashDataService.generatePettyCashReportForProject(this.project.projectNumber).then(response => {
        this.$store.commit('setOverlay', { text: 'Generating Petty Cash Report for Project', overlayState: false })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.project.projectNumber + '_all_pettycashreport.xlsx')
        document.body.appendChild(link)
        link.click()
      }, reject => {
        this.$store.commit('setOverlay', { text: 'Generating Petty Cash Report for Project', overlayState: false })
        this.$store.commit('showMessage', { message: 'Generate Petty Cash Report failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
        this.close()
      })
    },
    clear () {
      this.$v.$reset()
      this.editedPettyCash = this.defaultPettyCash
      this.searchEmployee = null
    }
  }
}
</script>

<style scoped>

</style>
