<template>
  <v-container>
    <v-dialog persistent v-model="dialog" max-width="1500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <v-card>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <v-text-field
                          @input="$v.editedLocation.locationName.$touch()" @blur="$v.editedLocation.locationName.$touch()"
                          v-model="editedLocation.locationName"
                          label="Location Name" required :error-messages="locationErrors">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea dense v-model="editedLocation.address" label="Address" rows="4" clearable outlined>
                        </v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" dark @click="save">Save</v-btn>
          <v-btn color="orange darken-1" dark @click="clear">Clear</v-btn>
          <v-btn color="red darken-1" dark @click="close">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import LocationDataService from '@/services/LocationDataService'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'SaveLocationDialog',
  data () {
    return {
      errors: {}
    }
  },
  validations: {
    editedLocation: {
      locationName: { required }
    }
  },
  computed: {
    editedLocation: {
      get () {
        return this.$store.state.location.editedLocation
      },
      set (value) {
        this.$store.commit('location/setEditedLocation', value)
      }
    },
    dialog: {
      get () {
        return this.$store.state.location.editLocationDialog
      },
      set () {
        this.$store.commit('location/toggleDialog')
      }
    },
    formTitle () {
      if (this.editedLocation.id > 0) {
        return 'Edit Location ' + this.editedLocation.locationName
      } else {
        return 'New Location'
      }
    },
    locationErrors () {
      const errors = []
      if (!this.$v.editedLocation.locationName.$dirty) return errors
      !this.$v.editedLocation.locationName.required && errors.push('Please put a location name')
      return errors
    }
  },
  methods: {
    async save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.editedLocation.id > 0) {
          try {
            this.$store.commit('setOverlay', { text: 'Updating Location...', overlayState: true })
            let response = await this.$store.dispatch('location/updateLocation', this.editedLocation)
            this.$store.commit('setOverlay', { text: 'Updating Location...', overlayState: false })
            this.$store.commit('showMessage', { message: response.data, color: 'success' })
          } catch (err) {
            console.error(err)
            this.$store.commit('setOverlay', { text: 'Updating Location...', overlayState: false })
            this.$store.commit('showMessage', { message: 'Update Location failed: ' + err.response.status + ': ' + err.response.data, color: 'error' })
          } finally {
            this.close()
            this.$store.commit('location/setRenderKey')
            this.$store.commit('location/setEditedLocation', Object.assign({}, this.$store.getters['location/defaultLocation']))
            await this.refreshLocations()
          }
        } else {
          try {
            this.$store.commit('setOverlay', { text: 'Creating Location...', overlayState: true })
            let response = await this.$store.dispatch('location/createLocation', this.editedLocation)
            this.$store.commit('setOverlay', { text: 'Creating Location...', overlayState: false })
            this.$store.commit('showMessage', { message: response.data, color: 'success' })
          } catch (err) {
            console.error(err)
            this.$store.commit('setOverlay', { text: 'Creating Location...', overlayState: false })
            this.$store.commit('showMessage', { message: 'Create Location failed: ' + err.response.status + ': ' + err.response.data, color: 'error' })
          } finally {
            this.close()
            this.$store.commit('location/setRenderKey')
            this.$store.commit('location/setEditedLocation', Object.assign({}, this.$store.getters['location/defaultLocation']))
            await this.refreshLocations()
          }
        }
      }
    },
    clear () {
      this.$v.$reset()
      this.editedLocation = this.$store.getters['location/defaultLocation']
    },
    close () {
      this.clear()
      this.$store.commit('location/hideDialog')
    },
    async refreshLocations () {
      let response = await LocationDataService.allLocations()
      this.$store.commit('setLocations', response.data)
    }
  }
}
</script>

<style scoped>

</style>
