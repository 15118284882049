<template>
  <v-container>
    <v-dialog persistent v-model="dialog" max-width="1500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-menu v-model="dateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field label="Date"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                :value="dateDisp"
                                required
                                :error-messages="dateErrors"
                                @input="$v.editedContractTerm.date.$touch()"
                                @blur="$v.editedContractTerm.date.$touch()"
                                v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="editedContractTerm.date" no-title @input="dateMenu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                required v-model="editedContractTerm.description" label="Item"
                :error-messages="descriptionErrors"
                @input="$v.editedContractTerm.description.$touch()"
                @blur="$v.editedContractTerm.description.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-currency-field
                label="Amount"
                :error-messages="errors.rate"
                v-model.number="editedContractTerm.amount" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" dark @click="save">Save</v-btn>
          <v-btn color="orange darken-1" dark @click="clear">Clear</v-btn>
          <v-btn color="red darken-1" dark @click="close">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'SaveContractTermDialog',
  data () {
    return {
      errors: {},
      dateMenu: false
    }
  },
  validations: {
    editedContractTerm: {
      date: { required },
      description: { required }
    }
  },
  computed: {
    editedContractTerm: {
      get () {
        return this.$store.state.project.contractTerm.editedContractTerm
      },
      set (value) {
        this.$store.commit('project/contractTerm/setEditedContractTerm', value)
      }
    },
    formTitle () {
      return this.editedContractTerm.id === 0
        ? 'Add Contract Term ' : 'Edit Contract Term '
    },
    dateDisp () {
      return this.editedContractTerm.date
    },
    dateErrors () {
      const errors = []
      if (!this.$v.editedContractTerm.date.$dirty) return errors
      !this.$v.editedContractTerm.date.required && errors.push('Date is required')
      return errors
    },
    descriptionErrors () {
      const errors = []
      if (!this.$v.editedContractTerm.description.$dirty) return errors
      !this.$v.editedContractTerm.description.required && errors.push('Description is required')
      return errors
    },
    dialog: {
      get () {
        return this.$store.state.project.contractTerm.editContractTermDialog
      },
      set () {
        this.$store.commit('project/contractTerm/toggleContractTermDialog')
      }
    }
  },
  methods: {
    save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.editedContractTerm.id > 0) {
          this.$store.dispatch('project/contractTerm/updateContractTerm', this.editedContractTerm).then(response => {
            this.$store.dispatch('project/contractTerm/unPaginated/getContractTerms', this.editedContractTerm.projectNumber)
            this.$store.dispatch('project/contractTerm/getTotalContractTermAmount', this.editedContractTerm.projectNumber)
            this.close()
          }, reject => {
            this.close()
          })
        } else {
          this.$store.dispatch('project/contractTerm/createContractTerm', this.editedContractTerm).then(response => {
            this.$store.dispatch('project/contractTerm/unPaginated/getContractTerms', this.editedContractTerm.projectNumber)
            this.$store.dispatch('project/contractTerm/getTotalContractTermAmount', this.editedContractTerm.projectNumber)
            this.close()
          }, reject => {
            this.close()
          })
        }
      }
    },
    close () {
      this.clear()
      this.$store.commit('project/contractTerm/hideContractTermDialog')
    },
    clear () {
      this.$v.$reset()
      this.editedContractTerm = this.$store.getters['project/contractTerm/defaultContractTerm']
      this.editedContractTerm.projectNumber = this.$store.getters['project/project'].projectNumber
    }
  }
}
</script>

<style scoped>

</style>
