<template>
  <div>
    <div>
      <v-sheet color="primary lighten-1" dark width="100%" max-width="100%">
        <span class="ma-2 headline">Bill of Materials List for {{ project.name }}</span>
      </v-sheet>
    </div>
    <div>
      <v-data-table
        fixed-header
        height="400"
        :headers="headers"
        :items="bom"
        :options.sync="options"
        :server-items-length="totalBom"
        :loading="loading"
        :footer-props="{ 'items-per-page-options':[5,10,30,100,300] }"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details @keyup="searchDebounced"></v-text-field>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-dialog persistent v-model="dialog" max-width="750px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2" v-on="on">New Bill of Materials</v-btn>
              </template>
              <v-card>
                <v-form v-model="valid">
                  <v-card-title>
                    <span class="headline">{{formTitle}}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            required
                            autofocus
                            :error-messages="descriptionErrors"
                            @input="$v.editedBom.description.$touch()"
                            @blur="$v.editedBom.description.$touch()"
                            v-model="editedBom.description" label="Description"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-currency-field label="Markup in %" :error-messages="errors.rate" v-model.number="editedBom.markup"></v-currency-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" dark @click="save">Save</v-btn>
                    <v-btn color="orange darken-1" dark @click="clear">Clear</v-btn>
                    <v-btn color="red darken-1" dark @click="close">Cancel</v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.action="{item}">
          <v-icon small class="mr-2" @click="editBOM(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteBOM(item.id)">mdi-delete</v-icon>
        </template>
        <template v-slot:item.bomNumber="{item}">
          <router-link :to="'/' + item.id + '/details'" class="body-1 font-weight-bold">{{ item.bomNumber }}</router-link>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import BOMDataService from '../../services/BOMDataService'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ListBOMComponent',
  props: {
    parentProject: Object
  },
  data () {
    return {
      project: this.parentProject,
      search: '',
      valid: false,
      loading: true,
      totalBom: 0,
      bom: [],
      options: {},
      errors: {},
      headers: [
        { text: 'BOM Number', align: 'center', sortable: true, value: 'bomNumber' },
        { text: 'Description', align: 'center', sortable: true, value: 'description' },
        { text: 'Markup (in %)', align: 'center', sortable: true, value: 'markup' },
        { text: 'Actions', align: 'center', sortable: false, value: 'action' }
      ],
      dialog: false,
      editedIndex: -1,
      editedBom: {
        id: 0,
        projectId: this.parentProject.id,
        projectNumber: this.parentProject.projectNumber,
        bomNumber: '',
        description: '',
        markup: 0
      },
      defaultBom: {
        id: 0,
        projectId: this.parentProject.id,
        projectNumber: this.parentProject.projectNumber,
        bomNumber: '',
        description: '',
        markup: 0
      },
      searchTimer: null
    }
  },
  validations: {
    editedBom: {
      description: { required }
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1
        ? 'New Bill of Materials for ' + this.project.name : 'Edit Bill of Materials ' + this.editedBom.bomNumber
    },
    descriptionErrors () {
      const errors = []
      if (!this.$v.editedBom.description.$dirty) return errors
      !this.$v.editedBom.description.required && errors.push('Please enter description')
      return errors
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    options: {
      handler () {
        this.getDataFromApi(this.project.projectNumber)
      },
      deep: true
    }
  },
  created () {
    this.getDataFromApi(this.project.projectNumber)
  },
  methods: {
    searchDebounced () {
      clearTimeout(this.searchTimer)
      if (this.search === '' || this.search == null || this.search.length >= 3) {
        this.searchTimer = setTimeout(() => {
          this.getDataFromApi(this.project.projectNumber)
        }, 500)
      }
    },
    getDataFromApi (projectNumber) {
      this.loading = true
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (itemsPerPage > 0) {
          let searchParams = {}
          searchParams.page = page
          searchParams.itemsPerPage = itemsPerPage
          if (sortBy.length > 0) {
            searchParams.sortBy = sortBy[0]
          }
          if (sortDesc.length > 0) {
            searchParams.sortDesc = sortDesc[0]
          }
          searchParams.projectNumber = projectNumber
          if (this.search) {
            searchParams.searchTerm = this.search
          }
          return BOMDataService.retrieveBOMPaginated(projectNumber, searchParams).then(response => {
            this.bom = response.data.content
            this.totalBom = response.data.totalElements
            this.loading = false
          })
        }
      })
    },
    close () {
      this.dialog = false
      this.$v.$reset()
      setTimeout(() => {
        this.editedBom = Object.assign({}, this.defaultBom)
        this.editedIndex = -1
      }, 300)
    },
    save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.editedIndex > -1) {
          this.$store.commit('setOverlay', { text: 'Updating Bill of Materials', overlayState: true })
          return BOMDataService.updateBOM(this.editedBom).then(response => {
            this.$store.commit('setOverlay', { text: 'Updating Bill of Materials', overlayState: false })
            this.$store.commit('showMessage', { message: response.data, color: 'success' })
            this.getDataFromApi(this.project.projectNumber)
            this.close()
          }, reject => {
            this.$store.commit('setOverlay', { text: 'Updating Bill of Materials', overlayState: false })
            this.$store.commit('showMessage', { message: 'Update Bill of Materials failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
            this.close()
          })
        } else {
          this.$store.commit('setOverlay', { text: 'Creating Bill of Materials', overlayState: true })
          return BOMDataService.createBOM(this.editedBom).then(response => {
            this.$store.commit('setOverlay', { text: 'Creating Bill of Materials', overlayState: false })
            this.$store.commit('showMessage', { message: response.data, color: 'success' })
            this.getDataFromApi(this.project.projectNumber)
            this.close()
          }, reject => {
            this.$store.commit('setOverlay', { text: 'Creating Bill of Materials', overlayState: false })
            this.$store.commit('showMessage', { message: 'Create Bill of Materials failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
            this.close()
          })
        }
      }
    },
    editBOM (item) {
      this.editedIndex = this.bom.indexOf(item)
      this.dialog = true
      this.$store.commit('setOverlay', { text: 'Retrieving BOM Details', overlayState: true })
      BOMDataService.getBomById(item.id).then(response => {
        this.$store.commit('setOverlay', { text: 'Retrieving BOM Details', overlayState: false })
        this.editedBom = response.data
      }, reject => {
        this.$store.commit('setOverlay', { text: 'Retrieving BOM Details', overlayState: false })
        this.$store.commit('showMessage', { message: 'Get BOM details failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
      })
    },
    deleteBOM (id) {
      if (confirm('Do you want to delete this BOM?')) {
        this.$store.commit('setOverlay', { text: 'Deleting Bill of Materials', overlayState: true })
        BOMDataService.deleteBOM(id).then(response => {
          this.$store.commit('setOverlay', { text: 'Deleting Bill of Materials', overlayState: false })
          this.$store.commit('showMessage', { message: response.data, color: 'success' })
          this.getDataFromApi(this.project.projectNumber)
        }, reject => {
          this.$store.commit('setOverlay', { text: 'Deleting Bill of Materials', overlayState: false })
          this.$store.commit('showMessage', { message: 'Delete Bill of Materials failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
          this.close()
        })
      }
    },
    clear () {
      this.$v.$reset()
      this.editedBom = this.defaultBom
    }
  }
}
</script>

<style scoped>

</style>
