import { render, staticRenderFns } from "./DisplayProjectExpensesComponent.vue?vue&type=template&id=889dbe9c&scoped=true&"
import script from "./DisplayProjectExpensesComponent.vue?vue&type=script&lang=js&"
export * from "./DisplayProjectExpensesComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "889dbe9c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCol,VSimpleTable})
