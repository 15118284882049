var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_c('router-link',{staticClass:"headline text-none",attrs:{"to":'/project/' + _vm.project.id}},[_vm._v(_vm._s(_vm.project.name))]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.project.projectType))])],1),_c('v-card-text',[_c('div',{staticClass:"subtitle-1"},[_vm._v("Client: "+_vm._s(_vm.project.clientDisplayDto.clientName))]),_c('div',{staticClass:"subtitle-1"},[_vm._v("Start Date: "+_vm._s(_vm.$d(new Date(_vm.project.startDate), 'short')))]),_c('div',{staticClass:"subtitle-1"},[_vm._v("End Date: "+_vm._s(_vm.$d(new Date(_vm.project.endDate), 'short')))])]),_c('v-card-actions',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","to":'/' + _vm.project.id + '/vouchers'}},on),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-cash-minus")]),_vm._v("Expenses ")],1)]}}])},[_c('span',[_vm._v("Expenses")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","to":'/' + _vm.project.id + '/payments'}},on),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-cash-plus")]),_vm._v("Collections ")],1)]}}])},[_c('span',[_vm._v("Collections")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","to":'/' + _vm.project.id + '/pettycash'}},on),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-note-outline")]),_vm._v("Petty Cash ")],1)]}}])},[_c('span',[_vm._v("Petty Cash")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","to":'/' + _vm.project.id + '/billofmaterials'}},on),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-clipboard-text-outline")]),_vm._v("BOM ")],1)]}}])},[_c('span',[_vm._v("Bill of Materials")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }