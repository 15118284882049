import LocationDataService from '@/services/LocationDataService'

export const location = {
  namespaced: true,
  state: {
    location: {
      id: 0,
      locationName: '',
      address: ''
    },
    editedLocation: {
      id: 0,
      locationName: '',
      address: ''
    },
    defaultLocation: {
      id: 0,
      locationName: '',
      address: ''
    },
    locations: [],
    totalLocations: 0,
    editLocationDialog: false,
    renderKey: 0
  },
  actions: {
    getLocations ({ state, commit }, payload) {
      return LocationDataService.retrieveLocations(payload.options)
    },
    getLocation ({ state, commit }, id) {
      return LocationDataService.getLocationById(id)
    },
    createLocation ({ state, commit, rootState }, payload) {
      return LocationDataService.createLocation(payload)
    },
    updateLocation ({ state, commit, rootState }, payload) {
      return LocationDataService.updateLocation(payload)
    },
    deleteLocation ({ state, commit, rootState }, id) {
      return LocationDataService.deleteLocation(id)
    }
  },
  mutations: {
    setRenderKey (state) {
      state.renderKey += 1
    },
    setLocationSuccess (state, data) {
      state.location = data
    },
    setLocationFailure (state) {
      state.location = state.defaultLocation
    },
    setLocationListSuccess (state, data) {
      state.locations = data.content
      state.totalLocations = data.totalElements
    },
    setLocationListFailure (state) {
      state.locations = []
      state.totalLocations = 0
    },
    toggleDialog (state) {
      state.editLocationDialog = !state.editLocationDialog
    },
    showDialog (state) {
      state.editLocationDialog = true
    },
    hideDialog (state) {
      state.editLocationDialog = false
    },
    setEditedLocation (state, data) {
      state.editedLocation = data
    }
  },
  getters: {
    location (state) {
      return state.location
    },
    totalLocations (state) {
      return state.totalLocations
    },
    locations (state) {
      return state.locations
    },
    editedLocation (state) {
      return state.editedLocation
    },
    renderKey (state) {
      return state.renderKey
    },
    defaultLocation (state) {
      return state.defaultLocation
    }
  }
}
