import { AXIOS } from './Axios-Common'

const URL = '/inventoryitems'

class InventoryItemDataService {
  retrieveInventoryItems (params) {
    return AXIOS.get(`${URL}/searchitems`, {
      params: {
        offset: params.page - 1,
        limit: params.itemsPerPage,
        searchTerm: params.searchTerm,
        sortBy: params.sortBy,
        sortDesc: params.sortDesc
      }
    })
  }
  getInventoryItemById (id) {
    return AXIOS.get(`${URL}/inventoryitem/${id}`)
  }
  createInventoryItem (item) {
    return AXIOS.post(`${URL}/inventoryitem`, item)
  }
  updateInventoryItem (item) {
    return AXIOS.put(`${URL}/inventoryitem`, item)
  }
  deleteInventoryItem (id) {
    return AXIOS.delete(`${URL}/inventoryitem/${id}`)
  }
  addInventoryLog (log) {
    return AXIOS.post(`${URL}/inventorylog`, log)
  }
  getLogsForInventoryItem (id) {
    return AXIOS.get(`${URL}/logs/${id}`)
  }
}

export default new InventoryItemDataService()
