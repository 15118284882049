<template>
  <div>
    <v-data-table
      fixed-header
      height="400"
      :headers="headers"
      :items="vouchers"
      :options.sync="options"
      :server-items-length="totalVouchers"
      :loading="loading"
      :footer-props="{
        'items-per-page-options':[5,10,30,100,300],
        'showFirstLastPage': true,
        'showCurrentPage': true
      }"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat color="primary lighten-1" dark>
          <v-toolbar-title class="title">Voucher Search</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" @keyup="searchDebounced" single-line hide-details />
          <v-dialog persistent v-model="dialog" max-width="1500px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ editedVoucher.voucherNumber }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-card>
                        <v-card-title>
                          <h4>General Information</h4>
                        </v-card-title>
                        <v-card-text>
                          <v-col cols="12">
                            <v-select readonly v-model="editedVoucher.voucherCategory"
                                      :items="$store.getters.voucherCategories"
                                      hint="Select Category"
                                      persistent-hint
                                      item-text="description"
                                      item-value="category"
                                      return-object
                                      label="Category">Category</v-select>
                          </v-col>
                          <v-col cols="12">
                            <v-menu
                              readonly
                              v-model="voucherDateMenu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y

                              max-width="290px"
                              min-width="290px">
                              <template v-slot:activator="{on}">
                                <v-text-field
                                  label="Voucher Date"
                                  prepend-inner-icon="mdi-calendar"
                                  readonly
                                  :value="voucherDateDisp"
                                  v-on="on"
                                  required
                                ></v-text-field>
                              </template>
                              <v-date-picker readonly v-model="editedVoucher.voucherDate" no-title @input="voucherDateMenu = false"></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12">
                            <v-textarea
                              readonly
                              v-model="editedVoucher.description"
                              label="Description"
                              clearable
                              required
                              outlined></v-textarea>
                          </v-col>
                          <v-row>
                            <v-col cols="12" md="4">
                              <v-container fluid>
                                <v-switch readonly v-model="editedVoucher.vatable" label="Vatable?"></v-switch>
                              </v-container>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-currency-field v-model="editedVoucher.vat" label="VAT" readonly/>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-currency-field v-model="editedVoucher.vatableAmount" label="VATable Amount" readonly/>
                            </v-col>
                          </v-row>
                          <v-col cols="12">
                            <v-currency-field
                              contenteditable="false"
                              readonly
                              label="Amount"
                              :error-messages="errors.rate"
                              v-model.number="editedVoucher.amount"></v-currency-field>
                          </v-col>
                          <v-col cols="12" v-if="editedVoucher.scannedReceipt">
                            <v-img :src="'data:' + editedVoucher.contentType + ';base64,' + editedVoucher.scannedReceipt"></v-img>
                          </v-col>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-card>
                        <v-card-title>
                          <h4>Supplier Information</h4>
                        </v-card-title>
                        <v-card-text>
                          <v-col cols="12">
                            <v-text-field readonly v-model="editedVoucher.paidTo" label="Supplier"></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field readonly v-model="editedVoucher.tin" label="TIN #"></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field readonly v-model="editedVoucher.receiptNo" label="Receipt #"></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field readonly v-model="editedVoucher.city" label="City"></v-text-field>
                          </v-col>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer/>
                <v-btn color="primary darken-1" dark @click="close">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.voucherNumber="{ item }">
        <v-btn color="primary lighten-1" small text @click="viewVoucher(item)">
          <v-icon left v-if="item.contentType">mdi-paperclip</v-icon>
          {{ item.voucherNumber }}
        </v-btn>
      </template>
      <template v-slot:item.amount="{ item }">
        {{$n(item.amount, 'currency')}}
      </template>
      <template v-slot:item.voucherDate="{ item }">
        {{ $d(new Date(item.voucherDate), 'short') }}
      </template>
      <!--<template v-slot:footer>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-select
              class="ma-2"
              v-model="annualExpenseReportYear"
              :items="$store.getters.years"
              hint="Select Category"
              persistent-hint
              label="Select Year"
            >
              Year
            </v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn color="primary lighten-1" dark class="ma-2" @click="generateAnnualExpenseReport(annualExpenseReportYear)">Generate Annual Report</v-btn>
          </v-col>
        </v-row>
      </template>-->
    </v-data-table>
    <v-row dense>
      <v-col cols="12" sm="6" md="3">
        <v-select
          required
          :items="allProjects"
          item-text="name"
          item-value="projectNumber"
          v-model="expenseReportDates.projectNumbers"
          label="Projects"
          multiple
          chips
          hint="Projects to be included in the Expense Report"
        >Projects</v-select>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-menu
          v-model="expenseReportDateStartMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y

          max-width="290px"
          min-width="290px">
          <template v-slot:activator="{on}">
            <v-text-field
              label="Date From"
              prepend-inner-icon="mdi-calendar"
              readonly
              :value="expenseReportDateStartDisp"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="expenseReportDates.dateStart" no-title @input="expenseReportDateStartMenu = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-menu
          v-model="expenseReportDateEndMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y

          max-width="290px"
          min-width="290px">
          <template v-slot:activator="{on}">
            <v-text-field
              label="Date To"
              prepend-inner-icon="mdi-calendar"
              readonly
              :value="expenseReportDateEndDisp"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="expenseReportDates.dateEnd" no-title @input="expenseReportDateEndMenu = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-btn color="primary lighten-1" class="ma-2" dark @click="generateExpenseReportForProjects">Generate Expense Report</v-btn>
      </v-col>
    </v-row>
    <snackbar></snackbar>
    <overlay></overlay>
  </div>
</template>

<script>
import VoucherDataService from '../../services/VoucherDataService'
import Snackbar from '../global/Snackbar'
import Overlay from '../global/Overlay'
import ProjectDataService from '../../services/ProjectDataService'
export default {
  name: 'VoucherSearchComponent',
  components: { Overlay, Snackbar },
  data () {
    return {
      allProjects: [],
      search: '',
      valid: false,
      loading: true,
      totalVouchers: 0,
      vouchers: [],
      options: {},
      errors: {},
      headers: [
        { text: 'Voucher Number', align: 'left', sortable: true, value: 'voucherNumber' },
        { text: 'Project Name', align: 'left', sortable: true, value: 'name' },
        { text: 'Paid To', align: 'left', sortable: true, value: 'paidTo' },
        { text: 'Description', align: 'left', sortable: true, value: 'description' },
        { text: 'Date', align: 'left', sortable: true, value: 'voucherDate' },
        { text: 'Category', align: 'left', sortable: true, value: 'categoryDescription' },
        { text: 'Amount', align: 'right', sortable: true, value: 'amount' }
      ],
      dialog: false,
      voucherDateMenu: false,
      editedIndex: -1,
      editedVoucher: {
        id: 0,
        projectId: 0,
        projectNumber: '',
        voucherNumber: '',
        voucherDate: '',
        amount: 0,
        description: '',
        tin: '',
        city: '',
        vatableAmount: 0,
        vat: 0,
        receiptNo: '',
        voucherCategory: {
          category: 0,
          description: ''
        },
        paidTo: '',
        vatable: false,
        supplierDto: {
          id: 0,
          name: '',
          address: '',
          tin: '',
          email: '',
          contactNumber: '',
          contactPerson: '',
          city: ''
        },
        contentType: '',
        scannedReceipt: ''
      },
      defaultVoucher: {
        id: 0,
        projectId: 0,
        projectNumber: '',
        voucherNumber: '',
        voucherDate: '',
        amount: 0,
        description: '',
        tin: '',
        city: '',
        vatableAmount: 0,
        vat: 0,
        receiptNo: '',
        voucherCategory: {
          category: 0,
          description: ''
        },
        paidTo: '',
        vatable: false,
        supplierDto: {
          id: 0,
          name: '',
          address: '',
          tin: '',
          email: '',
          contactNumber: '',
          contactPerson: '',
          city: ''
        },
        contentType: '',
        scannedReceipt: ''
      },
      annualExpenseReportYear: 2020,
      expenseReportGenerationDialog: false,
      expenseReportDateStartMenu: false,
      expenseReportDateEndMenu: false,
      expenseReportDates: {
        projectNumbers: [],
        dateStart: '',
        dateEnd: ''
      }
    }
  },
  created () {
    this.getAllProjects()
  },
  computed: {
    expenseReportDateStartDisp () {
      return this.expenseReportDates.dateStart
    },
    expenseReportDateEndDisp () {
      return this.expenseReportDates.dateEnd
    },
    voucherDateDisp () {
      return this.editedVoucher.voucherDate
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true
    }
  },
  methods: {
    searchDebounced () {
      clearTimeout(this.searchTimer)
      if (this.search === '' || this.search == null || this.search.length >= 3) {
        this.searchTimer = setTimeout(() => {
          this.getDataFromApi()
        }, 500)
      }
    },
    getDataFromApi () {
      this.loading = true
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        if (itemsPerPage > 0) {
          let searchParams = {}
          searchParams.page = page
          searchParams.itemsPerPage = itemsPerPage
          if (sortBy.length > 0) {
            searchParams.sortBy = sortBy[0]
          }
          if (sortDesc.length > 0) {
            searchParams.sortDesc = sortDesc[0]
          }
          if (this.search) {
            searchParams.searchTerm = this.search
          }
          return VoucherDataService.retrieveVouchersByProjectNumberPaginated(null, searchParams).then(response => {
            this.vouchers = response.data.content
            this.totalVouchers = response.data.totalElements
            this.loading = false
          })
        }
      })
    },
    viewVoucher (item) {
      this.editedIndex = this.vouchers.indexOf(item)
      this.dialog = true
      this.$store.commit('setOverlay', { text: 'Retrieving Voucher Details', overlayState: true })
      VoucherDataService.getVoucherById(item.id).then(response => {
        this.$store.commit('setOverlay', { text: 'Retrieving Voucher Details', overlayState: false })
        this.editedVoucher = response.data
      }, reject => {
        this.$store.commit('setOverlay', { text: 'Retrieving Voucher Details', overlayState: false })
        this.$store.commit('showMessage', { message: 'Get Voucher details failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
      })
    },
    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedVoucher = Object.assign({}, this.defaultVoucher)
        this.editedIndex = -1
      }, 300)
    },
    generateAnnualExpenseReport (year) {
      if (!year) {
        year = 2020
      }

      this.$store.commit('setOverlay', { text: 'Generating Expense Report', overlayState: true })
      return VoucherDataService.generateAnnualExpenseReport(year).then(response => {
        this.$store.commit('setOverlay', { text: 'Generating Expense Report', overlayState: false })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', year + '_royaltek_annualexpensereport.xlsx')
        document.body.appendChild(link)
        link.click()
      }, reject => {
        this.$store.commit('setOverlay', { text: 'Generating Expense Report', overlayState: false })
        this.$store.commit('showMessage', { message: 'Generate Annual Expense Report failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
        this.close()
      })
    },
    getAllProjects () {
      return ProjectDataService.getAllProjects().then(response => {
        this.allProjects = response.data
      })
    },
    generateExpenseReportForProjects () {
      this.$store.commit('setOverlay', { text: 'Generating Expense Report for selected Projects', overlayState: true })
      return ProjectDataService.createExpenseReportPerProject(this.expenseReportDates).then(response => {
        this.$store.commit('setOverlay', { text: 'Generating Expense Report for selected Projects', overlayState: false })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'expensereportperproject.xlsx')
        document.body.appendChild(link)
        link.click()
      }, reject => {
        this.$store.commit('setOverlay', { text: 'Generating Expense Report for selected Projects', overlayState: false })
        this.$store.commit('showMessage', { message: 'Generate Expense Report per Project failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
      })
    }
  }
}
</script>

<style scoped>

</style>
