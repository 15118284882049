<template>
  <v-container v-if="inventoryHasBeenFetched">
    <v-card class="mx-auto" flat>
      <v-card-title>
        <v-sheet width="100%">
          <span class="display-1">{{ inventoryItem.item }}</span>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="title">Inventory ID: {{ inventoryItem.inventoryId }}</v-list-item-title>
                    <v-list-item-title class="title">Quantity: {{ inventoryItem.quantity }}</v-list-item-title>
                    <v-list-item-title class="title">Acquisition Date: {{ inventoryItem.dateAcquired }}</v-list-item-title>
                    <v-list-item-title class="title">S/N: {{ inventoryItem.serialNumber }}</v-list-item-title>
                    <v-list-item-title class="title">Manufacturer: {{ inventoryItem.manufacturer }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="6">
<!--              <zoom-on-hover :img-normal="'data:' + inventoryItem.contentType + ';base64,' + inventoryItem.picture"></zoom-on-hover>-->
              <v-img v-if="inventoryItem.picture" max-height="350px" contain :src="'data:' + inventoryItem.contentType + ';base64,' + inventoryItem.picture"></v-img>
            </v-col>
          </v-row>
        </v-sheet>
      </v-card-title>
      <v-card-text>
        <v-data-table class="elevation-1" :items="$store.getters['inventoryitem/inventoryLogs']" :headers="headers">
          <template v-slot:top>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="ma-2" @click="openDialog">
              Add to Inventory Log
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <save-inventory-log-dialog></save-inventory-log-dialog>
    <snackbar></snackbar>
    <overlay></overlay>
  </v-container>
</template>

<script>
import Snackbar from '@/components/global/Snackbar'
import Overlay from '@/components/global/Overlay'
import SaveInventoryLogDialog from '@/components/inventory/log/SaveInventoryLogDialog'
export default {
  name: 'InventoryItemHome',
  components: { SaveInventoryLogDialog, Overlay, Snackbar },
  props: {
    inventoryid: String
  },
  data () {
    return {
      headers: [
        { text: 'Date', align: 'left', sortable: true, value: 'dateMoved' },
        { text: 'From', align: 'left', sortable: true, value: 'origin' },
        { text: 'To', align: 'left', sortable: true, value: 'destination' },
        { text: 'Sender', align: 'left', sortable: true, value: 'sender' },
        { text: 'Receiver', align: 'left', sortable: true, value: 'receiver' },
        { text: 'Quantity Moved', align: 'left', sortable: true, value: 'quantity' }
      ]
    }
  },
  created () {
    this.getInventoryItem(this.$route.params.inventoryid)
  },
  computed: {
    inventoryItem () {
      return this.$store.getters['inventoryitem/inventoryItem']
    },
    inventoryHasBeenFetched () {
      return this.inventoryItem.id > 0
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  methods: {
    async getInventoryItem (id) {
      this.$store.commit('setOverlay', { text: 'Retrieving Inventory Item...', overlayState: true })
      let response = await this.$store.dispatch('inventoryitem/getInventoryItem', id)
      this.$store.commit('inventoryitem/setInventoryItemSuccess', response.data)
      // this.inventoryLog = response.data.inventoryLog
      this.$store.commit('setOverlay', { text: 'Retrieving Inventory Item...', overlayState: false })
    },
    openDialog () {
      this.$store.commit('inventoryitem/setInventoryLog', Object.assign({}, this.$store.getters['inventoryitem/defaultInventoryLog']))
      this.$store.commit('inventoryitem/showInventoryLogDialog')
      this.$store.commit('inventoryitem/setInventoryItemIdForLog', this.inventoryid)
      this.$store.commit('inventoryitem/setOriginForLog', this.inventoryItem.currentLocation)
    }
  }
}
</script>

<style scoped>

</style>
