<template>
  <div>
    <v-container>
      <v-row dense>
        <v-col cols="12" sm="4">
          <v-sheet color="primary lighten-1" dark width="100%" max-width="100%">
            <span class="headline ma-2">{{ 'Upload File for ' + bom.description }}</span>
          </v-sheet>
          <v-file-input v-model="fileToUpload" @click="uploadFile" label="Attach BOM File" clearable />
          <v-btn color="green darken-1" @click="uploadFile" dark>Upload BOM File to add Items</v-btn>
          <v-btn color="blue darken-1" @click="generateBomFile" dark>Generate BOM File</v-btn>
        </v-col>
        <v-col cols="12" sm="8">
          <v-sheet color="primary lighten-1" dark width="100%" max-width="100%">
            <span class="headline ma-2">Budget</span>
          </v-sheet>
          <display-b-o-m-budget-table ref="budgetTable" :bom-number="bom.bomNumber"></display-b-o-m-budget-table>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <v-sheet color="primary lighten-1" dark width="100%" max-width="100%">
        <span class="headline ma-2">BOM Item List for {{ bom.description }}</span>
      </v-sheet>
      <v-container>
        <v-data-table
          fixed-header
          height="400"
          :headers="headers"
          :items="bomItems"
          :options.sync="options"
          :server-items-length="totalBomItems"
          :loading="loading"
          :footer-props="{ 'items-per-page-options':[5,10,30,100,300] }"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details @keyup="searchDebounced" />
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-dialog persistent v-model="dialog" max-width="750px">
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" dark class="mb-2" v-on="on">New BOM Item</v-btn>
                </template>
                <v-card>
                  <v-form v-model="valid">
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row dense>
                          <v-col cols="12">
                            <v-select
                              v-model="editedBomItem.bomCategory"
                              :items="$store.getters.bomCategories"
                              autofocus
                              hint="Select Category"
                              persistent-hint
                              item-text="description"
                              item-value="description"
                              return-object
                              :error-messages="bomCategoryErrors"
                              @input="$v.editedBomItem.bomCategory.description.$touch()"
                              @blur="$v.editedBomItem.bomCategory.description.$touch()"
                              label="BOM Category"
                            >
                              BOM Category
                            </v-select>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field v-model="editedBomItem.description" label="Description"></v-text-field>
                          </v-col>
                          <v-row dense>
                            <v-col cols="12" sm="6">
                              <v-currency-field
                                label="Quantity"
                                :error-messages="errors.rate"
                                v-model.number="editedBomItem.quantity"></v-currency-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-select
                                v-model="editedBomItem.unit"
                                :items="$store.getters.units"
                                hint="Select Unit"
                                persistent-hint
                                item-text="description"
                                item-value="description"
                                return-object
                                label="Unit"
                              >
                                Unit
                              </v-select>
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="12" sm="6">
                              <v-currency-field
                                label="Unit Price"
                                :error-messages="errors.rate"
                                v-model.number="editedBomItem.unitPrice"></v-currency-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-currency-field
                                label="Labor Unit Price"
                                :error-messages="errors.rate"
                                v-model.number="editedBomItem.laborUnitPrice"></v-currency-field>
                            </v-col>
                          </v-row>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary darken-1" dark @click="save">Save</v-btn>
                      <v-btn color="orange darken-1" dark @click="clear">Clear</v-btn>
                      <v-btn color="red darken-1" dark @click="close">Cancel</v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.action="{item}">
            <v-icon small class="mr-2" @click="editBomItem(item)">mdi-pencil</v-icon>
            <v-icon small @click="deleteBomItem(item.id)">mdi-delete</v-icon>
          </template>
          <template v-slot:item.unitPrice="{item}">
            {{$n(item.unitPrice, 'currency')}}
          </template>
          <template v-slot:item.totalMaterialPrice="{item}">
            {{$n(item.totalMaterialPrice, 'currency')}}
          </template>
          <template v-slot:item.laborPrice="{item}">
            {{$n(item.laborPrice, 'currency')}}
          </template>
          <template v-slot:item.totalLaborPrice="{item}">
            {{$n(item.totalLaborPrice, 'currency')}}
          </template>
          <template v-slot:item.totalPrice="{item}">
            {{$n(item.totalPrice, 'currency')}}
          </template>
        </v-data-table>
      </v-container>
    </div>
  </div>
</template>

<script>
import BOMItemDataService from '../../services/BOMItemDataService'
import { required } from 'vuelidate/lib/validators'
import BOMDataService from '../../services/BOMDataService'
import DisplayBOMBudgetTable from './DisplayBOMBudgetTable'

export default {
  name: 'ListBOMItemsComponent',
  props: { bomNumber: String },
  components: {
    DisplayBOMBudgetTable
  },
  data () {
    return {
      bom: {},
      search: '',
      valid: false,
      loading: true,
      totalBomItems: 0,
      bomItems: [],
      options: {},
      errors: {},
      headers: [
        { text: 'Item Number', align: 'center', sortable: true, value: 'itemNumber' },
        { text: 'Category', align: 'center', sortable: false, value: 'bomCategory.description' },
        { text: 'Description', align: 'center', sortable: true, value: 'description' },
        { text: 'Quantity', align: 'center', sortable: true, value: 'quantity' },
        { text: 'Unit', align: 'center', sortable: true, value: 'unit.description' },
        { text: 'Unit Price', align: 'center', sortable: true, value: 'unitPrice' },
        { text: 'Total Material Price', align: 'center', sortable: true, value: 'totalMaterialPrice' },
        { text: 'Labor Price', align: 'center', sortable: true, value: 'laborPrice' },
        { text: 'Total Labor Price', align: 'center', sortable: true, value: 'totalLaborPrice' },
        { text: 'Total Price', align: 'center', sortable: true, value: 'totalPrice' },
        { text: 'Purchased Quantity', align: 'center', sortable: false, value: 'purchasedQuantity' },
        { text: 'Remaining Quantity', align: 'center', sortable: false, value: 'remainingQuantity' },
        { text: 'Actions', align: 'center', sortable: false, value: 'action' },
        { text: 'Actions', align: 'center', sortable: false, value: 'action2' },
        { text: 'Actions', align: 'center', sortable: false, value: 'action3' },
        { text: 'Actions', align: 'center', sortable: false, value: 'action4' },
        { text: 'Actions', align: 'center', sortable: false, value: 'action5' },
        { text: 'Actions', align: 'center', sortable: false, value: 'action6' },
        { text: 'Actions', align: 'center', sortable: false, value: 'action7' }
      ],
      dialog: false,
      editedIndex: -1,
      editedBomItem: {
        id: 0,
        projectNumber: '',
        bomNumber: '',
        itemNumber: '',
        description: '',
        bomCategory: {
          description: ''
        },
        quantity: 0,
        unit: {
          description: '',
          abbreviation: ''
        },
        unitPrice: 0,
        laborUnitPrice: 0
      },
      defaultBomItem: {
        id: 0,
        projectNumber: '',
        bomNumber: '',
        itemNumber: '',
        description: '',
        bomCategory: {
          description: ''
        },
        quantity: 0,
        unit: {
          description: '',
          abbreviation: ''
        },
        unitPrice: 0,
        laborUnitPrice: 0
      },
      bomFileUploadDto: {
        bomNumber: ''
      },
      fileToUpload: null,
      searchTimer: null
    }
  },
  validations: {
    editedBomItem: {
      bomCategory: {
        description: { required }
      }
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1
        ? 'New BOM Item for ' + this.bom.description : 'Edit Bill of Materials ' + this.editedBomItem.itemNumber
    },
    bomCategoryErrors () {
      const errors = []
      if (!this.$v.editedBomItem.bomCategory.description.$dirty) return errors
      !this.$v.editedBomItem.bomCategory.description.required && errors.push('BOM Category is required')
      return errors
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    options: {
      handler () {
        this.getDataFromApi(this.bom.bomNumber)
      },
      deep: true
    }
  },
  methods: {
    setBom (bom) {
      this.bom = bom
      this.editedBomItem.bomNumber = this.bom.bomNumber
      this.editedBomItem.projectNumber = this.bom.projectNumber
      this.defaultBomItem.bomNumber = this.bom.bomNumber
      this.defaultBomItem.projectNumber = this.bom.projectNumber
      this.bomFileUploadDto.bomNumber = this.bom.bomNumber
    },
    searchDebounced () {
      clearTimeout(this.searchTimer)
      if (this.search === '' || this.search == null || this.search.length >= 3) {
        this.searchTimer = setTimeout(() => {
          this.getDataFromApi(this.bom.bomNumber)
        }, 500)
      }
    },
    getDataFromApi (bomNumber) {
      this.loading = true
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (itemsPerPage > 0 && bomNumber) {
          let searchParams = {}
          searchParams.page = page
          searchParams.itemsPerPage = itemsPerPage
          if (sortBy.length > 0) {
            searchParams.sortBy = sortBy[0]
          }
          if (sortDesc.length > 0) {
            searchParams.sortDesc = sortDesc[0]
          }
          searchParams.projectNumber = bomNumber
          if (this.search) {
            searchParams.searchTerm = this.search
          }
          return BOMItemDataService.retrieveBOMItemsPaginated(bomNumber, searchParams).then(response => {
            this.bomItems = response.data.content
            this.totalBomItems = response.data.totalElements
            this.loading = false
            this.$refs.budgetTable.getData(this.bom.bomNumber)
          })
        }
      })
    },
    close () {
      this.dialog = false
      this.$v.$reset()
      setTimeout(() => {
        this.editedBomItem = Object.assign({}, this.defaultBomItem)
        this.editedIndex = -1
      }, 300)
    },
    save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.editedIndex > -1) {
          this.$store.commit('setOverlay', { text: 'Updating BOM Item', overlayState: true })
          return BOMItemDataService.updateBOMItem(this.editedBomItem).then(response => {
            this.$store.commit('setOverlay', { text: 'Updating BOM Item', overlayState: false })
            this.$store.commit('showMessage', { message: response.data, color: 'success' })
            this.getDataFromApi(this.bom.bomNumber)
            this.$refs.budgetTable.getData(this.bom.bomNumber)
            this.updatePurchaseComponent()
            this.close()
          }, reject => {
            this.$store.commit('setOverlay', { text: 'Updating BOM Item', overlayState: false })
            this.$store.commit('showMessage', { message: 'Update BOM Item failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
            this.close()
          })
        } else {
          this.$store.commit('setOverlay', { text: 'Creating BOM Item', overlayState: true })
          return BOMItemDataService.createBOMItem(this.editedBomItem).then(response => {
            this.$store.commit('setOverlay', { text: 'Creating BOM Item', overlayState: false })
            this.$store.commit('showMessage', { message: response.data, color: 'success' })
            this.getDataFromApi(this.bom.bomNumber)
            this.$refs.budgetTable.getData(this.bom.bomNumber)
            this.updatePurchaseComponent()
            this.close()
          }, reject => {
            this.$store.commit('setOverlay', { text: 'Creating BOM Item', overlayState: false })
            this.$store.commit('showMessage', { message: 'Create BOM Item failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
            this.close()
          })
        }
      }
    },
    editBomItem (item) {
      this.editedIndex = this.bomItems.indexOf(item)
      this.dialog = true
      this.$store.commit('setOverlay', { text: 'Retrieving BOM Item Details', overlayState: true })
      BOMItemDataService.getBomItemById(item.id).then(response => {
        this.$store.commit('setOverlay', { text: 'Retrieving BOM Item Details', overlayState: false })
        this.editedBomItem = response.data
      }, reject => {
        this.$store.commit('setOverlay', { text: 'Retrieving BOM Item Details', overlayState: false })
        this.$store.commit('showMessage', { message: 'Get BOM Item details failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
      })
    },
    deleteBomItem (id) {
      if (confirm('Do you want to delete this BOM Item?')) {
        this.$store.commit('setOverlay', { text: 'Deleting BOM Item', overlayState: true })
        BOMItemDataService.deleteBOMItem(id).then(response => {
          this.$store.commit('setOverlay', { text: 'Deleting BOM Item', overlayState: false })
          this.$store.commit('showMessage', { message: response.data, color: 'success' })
          this.getDataFromApi(this.bom.bomNumber)
          this.updatePurchaseComponent()
        }, reject => {
          this.$store.commit('setOverlay', { text: 'Deleting BOM Item', overlayState: false })
          this.$store.commit('showMessage', { message: 'Delete BOM Item failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
          this.close()
        })
      }
    },
    clear () {
      this.$v.$reset()
      this.editedBomItem = this.defaultBomItem
    },
    uploadFile () {
      if (this.fileToUpload != null) {
        this.$store.commit('setOverlay', { text: 'Uploading BOM Item File', overlayState: true })
        let createFormData = new FormData()
        createFormData.append('bomFileUploadDTO', new Blob([JSON.stringify(this.bomFileUploadDto)], { type: 'application/json' }))
        createFormData.append('file', this.fileToUpload)
        return BOMDataService.uploadFile(createFormData).then(response => {
          this.$store.commit('setOverlay', { text: 'Uploading BOM Item File', overlayState: false })
          this.$store.commit('showMessage', { message: response.data, color: 'success' })
          this.getDataFromApi(this.bom.bomNumber)
          this.updatePurchaseComponent()
        }, reject => {
          this.$store.commit('setOverlay', { text: 'Uploading BOM Item File', overlayState: false })
          this.$store.commit('showMessage', { message: 'Upload BOM Items via File failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
          this.close()
        })
      }
    },
    updatePurchaseComponent () {
      this.$refs.budgetTable.getData(this.bom.bomNumber)
      this.$emit('purchases')
    },
    generateBomFile () {
      this.$store.commit('setOverlay', { text: 'Generating BOM File', overlayState: true })
      return BOMDataService.generateBOMFile(this.bom.bomNumber).then(response => {
        this.$store.commit('setOverlay', { text: 'Generating BOM File', overlayState: false })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.bom.bomNumber + '_BOM.xlsx')
        document.body.appendChild(link)
        link.click()
      })
    }
  }
}
</script>

<style scoped>
</style>
