<template>
  <v-dialog persistent v-model="dialog" max-width="1500px">
    <v-card>
      <v-card-title>
        <span class="headline">Add Inventory Log</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <v-card>
                <!--<v-card-title>
                  <span class="font-weight-medium">Current Location: {{ inventoryLog.origin.locationName }}</span>
                </v-card-title>-->
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-menu v-model="dateMovedMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                              offset-y max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field dense label="Date Moved" prepend-inner-icon="mdi-calendar" readonly :value="dateMovedDisp"
                                        v-on="on" required :error-messages="dateErrors" @input="$v.inventoryLog.dateMoved.$touch()"
                                        @blur="$v.inventoryLog.dateMoved.$touch()"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="inventoryLog.dateMoved" no-title @input="dateMovedMenu = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12">
                      <v-select v-model="inventoryLog.origin" :items="inventoryItem.currentLocations" item-text="locationDisplayDTO.locationName"
                                item-value="locationDisplayDTO.id" persistent-hint :error-messages="originErrors"
                                hint="Origin"
                                @input="$v.inventoryLog.origin.locationDisplayDTO.id.$touch()" @blur="$v.inventoryLog.origin.locationDisplayDTO.id.$touch()"
                                return-object label="Origin">
                        <template slot="item" slot-scope="data">
                          {{ data.item.locationDisplayDTO.locationName }} - contains {{ data.item.quantity }} items
                        </template>
                        Origin</v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-select v-model="inventoryLog.destination" :items="$store.getters.locations" item-text="locationName"
                                item-value="id" hint="Select Destination" persistent-hint :error-messages="destinationErrors"
                                @input="$v.inventoryLog.destination.id.$touch()" @blur="$v.inventoryLog.destination.id.$touch()"
                                return-object label="Destination">Destination</v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-currency-field dense v-model="inventoryLog.quantity" label="Quantity" @input="$v.inventoryLog.quantity.$touch()"
                                        @blur="$v.inventoryLog.quantity.$touch()" :error-messages="quantityErrors" required/>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="inventoryLog.sender" @input="$v.inventoryLog.sender.$touch()"
                                    @blur="$v.inventoryLog.sender.$touch()" label="Sender" required
                                    :error-messages="senderErrors">Sender</v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="inventoryLog.receiver" @input="$v.inventoryLog.receiver.$touch()"
                                    @blur="$v.inventoryLog.receiver.$touch()" label="Receiver" required
                                    :error-messages="receiverErrors">Receiver</v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" dark @click="save">Save</v-btn>
        <v-btn color="orange darken-1" dark @click="clear">Clear</v-btn>
        <v-btn color="red darken-1" dark @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, minValue, not, sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'SaveInventoryLogDialog',
  data () {
    return {
      errors: {},
      dateMovedMenu: false
    }
  },
  validations: {
    inventoryLog: {
      inventoryItemId: { minValue: minValue(1) },
      dateMoved: { required },
      origin: {
        locationDisplayDTO: {
          id: { minValue: minValue(1),
            notSameDestination: not(sameAs(function () {
              return this.inventoryLog.destination.id
            }))
          }
        }
      },
      destination: {
        id: { minValue: minValue(1),
          notSameOrigin: not(sameAs(function () {
            return this.inventoryLog.origin.id
          }))
        }
      },
      quantity: { minValue: minValue(1) },
      receiver: { required },
      sender: { required }
    }
  },
  computed: {
    inventoryItem: {
      get () {
        return this.$store.state.inventoryitem.inventoryItem
      }
    },
    inventoryLog: {
      get () {
        return this.$store.state.inventoryitem.inventoryLog
      },
      set (value) {
        this.$store.commit('inventoryitem/setInventoryLog', value)
      }
    },
    dialog: {
      get () {
        return this.$store.state.inventoryitem.addInventoryLogDialog
      },
      set () {
        this.$store.commit('inventoryitem/toggleInventoryLogDialog')
      }
    },
    dateMovedDisp () {
      return this.inventoryLog.dateMoved
    },
    dateErrors () {
      const errors = []
      if (!this.$v.inventoryLog.dateMoved.$dirty) return errors
      !this.$v.inventoryLog.dateMoved.required && errors.push('Date is required')
      return errors
    },
    receiverErrors () {
      const errors = []
      if (!this.$v.inventoryLog.receiver.$dirty) return errors
      !this.$v.inventoryLog.receiver.required && errors.push('Receiver is required')
      return errors
    },
    senderErrors () {
      const errors = []
      if (!this.$v.inventoryLog.sender.$dirty) return errors
      !this.$v.inventoryLog.sender.required && errors.push('Sender is required')
      return errors
    },
    originErrors () {
      const errors = []
      if (!this.$v.inventoryLog.origin.locationDisplayDTO.id.$dirty) return errors
      !this.$v.inventoryLog.origin.locationDisplayDTO.id.notSameDestination && errors.push('Origin cannot be same as destination')
      !this.$v.inventoryLog.origin.locationDisplayDTO.id.minValue && errors.push('Please select an origin')
      return errors
    },
    destinationErrors () {
      const errors = []
      if (!this.$v.inventoryLog.destination.id.$dirty) return errors
      !this.$v.inventoryLog.destination.id.notSameOrigin && errors.push('Destination cannot be same as origin')
      !this.$v.inventoryLog.destination.id.minValue && errors.push('Please select a destination')
      return errors
    },
    quantityErrors () {
      const errors = []
      if (!this.$v.inventoryLog.quantity.$dirty) return errors
      !this.$v.inventoryLog.quantity.minValue && errors.push('Please enter quantity to move')
      return errors
    }
  },
  methods: {
    async getInventoryItem (id) {
      this.$store.commit('setOverlay', { text: 'Retrieving Inventory Item...', overlayState: true })
      let response = await this.$store.dispatch('inventoryitem/getInventoryItem', id)
      this.$store.commit('inventoryitem/setInventoryItemSuccess', response.data)
      // this.inventoryLog = response.data.inventoryLog
      this.$store.commit('setOverlay', { text: 'Retrieving Inventory Item...', overlayState: false })
    },
    async getInventoryLog () {
      try {
        let response = await this.$store.dispatch('inventoryitem/getLogForItem', this.inventoryLog.inventoryItemId)
        this.$store.commit('inventoryitem/setInventoryLogs', response.data)
      } catch (err) {
        console.error(err)
        this.$store.commit('inventoryitem/setInventoryLogs', [])
      }
    },
    async save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          this.$store.commit('setOverlay', { text: 'Adding to inventory log...', overlayState: true })
          let response = await this.$store.dispatch('inventoryitem/addInventoryLog', this.inventoryLog)
          this.$store.commit('setOverlay', { text: 'Adding to inventory log...', overlayState: false })
          this.$store.commit('showMessage', { message: response.data, color: 'success' })
          await this.getInventoryItem(this.inventoryLog.inventoryItemId)
          await this.getInventoryLog()
        } catch (err) {
          console.error(err)
          this.$store.commit('setOverlay', { text: 'Adding to inventory log...', overlayState: false })
          this.$store.commit('showMessage', { message: 'Adding inventory log failed: ' + err.response.status + ': ' + err.response.data, color: 'error' })
        } finally {
          this.close()
        }
      }
    },
    clear () {
      this.$v.$reset()
      this.inventoryLog = this.$store.getters['inventoryitem/defaultInventoryLog']
    },
    close () {
      this.clear()
      this.$store.commit('inventoryitem/hideInventoryLogDialog')
    }
  }
}
</script>

<style scoped>

</style>
