import PaymentDataService from '../../services/PaymentDataService'

export const collections = {
  namespaced: true,
  state: {
    totalCollections: 0,
    collections: [],
    editPaymentDialog: false,
    editedPayment: {
      id: 0,
      projectId: 0,
      projectNumber: '',
      receiptNumber: '',
      paymentDate: '',
      amount: 0,
      description: '',
      contractTermId: 0,
      clientDto: {
        id: 0,
        clientName: '',
        address: '',
        tin: '',
        representative: '',
        email: '',
        contactNumber: ''
      },
      taxWithheld: false,
      taxableAmount: 0,
      tax: 0,
      rcdcORNumber: ''
    },
    defaultPayment: {
      id: 0,
      projectId: 0,
      projectNumber: '',
      receiptNumber: '',
      paymentDate: '',
      amount: 0,
      description: '',
      contractTermId: 0,
      clientDto: {
        id: 0,
        clientName: '',
        address: '',
        tin: '',
        representative: '',
        email: '',
        contactNumber: ''
      },
      taxWithheld: false,
      taxableAmount: 0,
      tax: 0
    },
    renderKey: 0,
    totalPaymentAmount: 0,
    rcdcORNumber: ''
  },
  actions: {
    getDataFromApi ({ state, commit }, payload) {
      return PaymentDataService.retrievePaymentsPaginated(payload.projectNumber, payload.options).then(response => {
        commit('setCollectionsSuccess', response.data)
      }, reject => {
        commit('setCollectionsFailure')
      })
    },
    getTotalPaymentAmount ({ state, commit }, projectNumber) {
      return PaymentDataService.getTotalPayment(projectNumber).then(response => {
        commit('setTotalPaymentAmount', response.data)
      }, reject => {
        commit('setTotalPaymentAmount', 0)
      })
    },
    getCollection ({ state, commit }, id) {
      commit('setOverlay', { text: 'Fetching Collection Details...', overlayState: true }, { root: true })
      return PaymentDataService.getPaymentById(id).then(response => {
        commit('setOverlay', { text: 'Fetching Collection Details...', overlayState: false }, { root: true })
        commit('setEditedPaymentSuccess', response.data)
      }, reject => {
        commit('setOverlay', { text: 'Fetching Collection Details...', overlayState: false }, { root: true })
        commit('showMessage', { message: 'Fetching Collection failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' }, { root: true })
        commit('setEditedPaymentFailure')
      })
    },
    createCollection ({ state, commit, rootState }, payload) {
      commit('setOverlay', { text: 'Creating Collection...', overlayState: true }, { root: true })
      return PaymentDataService.createPayment(payload).then(response => {
        commit('setOverlay', { text: 'Creating Collection...', overlayState: false }, { root: true })
        commit('showMessage', { message: response.data, color: 'success' }, { root: true })
        commit('setRenderKey')
        commit('setEditedPaymentSuccess', Object.assign({}, state.defaultPayment))
        commit('setCollectionProjectNumber', rootState.project.project.projectNumber)
      }, reject => {
        commit('setOverlay', { text: 'Creating Collection...', overlayState: false }, { root: true })
        commit('showMessage', { message: 'Creating Collection failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' }, { root: true })
        commit('setEditedPaymentSuccess', Object.assign({}, state.defaultPayment))
        commit('setCollectionProjectNumber', rootState.project.project.projectNumber)
      })
    },
    updateCollection ({ state, commit, rootState }, payload) {
      commit('setOverlay', { text: 'Updating Collection...', overlayState: true }, { root: true })
      return PaymentDataService.updatePayment(payload).then(response => {
        commit('setOverlay', { text: 'Updating Collection...', overlayState: false }, { root: true })
        commit('showMessage', { message: response.data, color: 'success' }, { root: true })
        commit('setRenderKey')
        commit('setEditedPaymentSuccess', Object.assign({}, state.defaultPayment))
        commit('setCollectionProjectNumber', rootState.project.project.projectNumber)
      }, reject => {
        commit('setOverlay', { text: 'Updating Collection...', overlayState: false }, { root: true })
        commit('showMessage', { message: 'Updating Collection failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' }, { root: true })
        commit('setEditedPaymentSuccess', Object.assign({}, state.defaultPayment))
        commit('setCollectionProjectNumber', rootState.project.project.projectNumber)
      })
    },
    deleteCollection ({ state, commit, rootState }, id) {
      commit('setOverlay', { text: 'Deleting Collection...', overlayState: true }, { root: true })
      return PaymentDataService.deletePayment(id).then(response => {
        commit('setOverlay', { text: 'Deleting Collection...', overlayState: false }, { root: true })
        commit('showMessage', { message: response.data, color: 'success' }, { root: true })
        commit('setRenderKey')
      }, reject => {
        commit('showMessage', { message: 'Deleting Collection failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' }, { root: true })
        commit('setOverlay', { text: 'Deleting Collection...', overlayState: false }, { root: true })
      })
    }
  },
  mutations: {
    setRenderKey (state) {
      state.renderKey += 1
    },
    setCollectionsSuccess (state, data) {
      state.collections = data.content
      state.totalCollections = data.totalElements
    },
    setCollectionsFailure (state) {
      state.collections = []
      state.totalCollections = 0
    },
    setEditedPaymentSuccess (state, payment) {
      state.editedPayment = payment
    },
    setEditedPaymentFailure (state, getters, rootState, rootGetters) {
      state.editedPayment = Object.assign({}, state.defaultPayment)
      state.editedPayment.projectNumber = rootState.project.project.projectNumber
    },
    toggleDialog (state) {
      state.editPaymentDialog = !state.editPaymentDialog
    },
    showDialog (state) {
      state.editPaymentDialog = true
    },
    hideDialog (state) {
      state.editPaymentDialog = false
    },
    setTotalPaymentAmount (state, payment) {
      state.totalPaymentAmount = payment
    },
    setCollectionProjectNumber (state, data) {
      state.editedPayment.projectNumber = data
    }
  },
  getters: {
    totalCollections (state) {
      return state.totalCollections
    },
    collections (state) {
      return state.collections
    },
    editedPayment (state) {
      return state.editedPayment
    },
    getPaymentProjectNumber (state) {
      return state.editedPayment.projectNumber
    },
    renderKey (state) {
      return state.renderKey
    },
    defaultPayment (state) {
      return state.defaultPayment
    },
    totalPaymentAmount (state) {
      return state.totalPaymentAmount
    }
  }
}
