var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',[_c('v-sheet',{attrs:{"color":"primary lighten-1","dark":"","width":"100%","max-width":"100%"}},[_c('span',{staticClass:"ma-1 headline"},[_vm._v("Collections for "+_vm._s(_vm.$store.getters['project/project'].name))])])],1),_c('div',[_c('v-data-table',{key:_vm.renderKey,staticClass:"elevation-1",attrs:{"fixed-header":"","height":"400","headers":_vm.headers,"items":_vm.payments,"options":_vm.options,"server-items-length":_vm.totalPayments,"loading":_vm.loading,"footer-props":{
      'items-per-page-options':[5,10,30,100,300],
       'showFirstLastPage': true,
        'showCurrentPage': true }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.getCollectionReportForProject(_vm.projectNumber)}}},[_vm._v("Generate Report")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},on:{"keyup":_vm.searchDebounced},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.openDialog}},on),[_c('v-icon',[_vm._v("mdi-plus-box")])],1)]}}])},[_c('span',[_vm._v("Add New Collection")])])],1)]},proxy:true},{key:"item.paymentDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(new Date(item.paymentDate), 'short'))+" ")]}},{key:"item.receiptNumber",fn:function(ref){
        var item = ref.item;
return [(item.contentType)?_c('v-icon',[_vm._v("mdi-paperclip")]):_vm._e(),_vm._v(" "+_vm._s(item.receiptNumber)+" ")]}},{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.amount, 'currency'))+" ")]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editPayment(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deletePayment(item.id)}}},[_vm._v("mdi-delete")])]}}])}),_c('save-collection-dialog')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }