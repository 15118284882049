<template>
  <inventory-items-list></inventory-items-list>
</template>

<script>
import InventoryItemsList from '../components/inventory/item/ListInventoryItemComponent'
export default {
  name: 'InventoryList',
  components: { InventoryItemsList }
}
</script>

<style scoped>

</style>
