var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":"400","headers":_vm.headers,"items":_vm.summaries,"options":_vm.options,"server-items-length":_vm.totalSummaries,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"primary lighten-1","dark":""}},[_c('v-toolbar-title',{staticClass:"title"},[_vm._v("Project Summary Page")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.getCollectionsReport}},[_vm._v("Generate Collections Report")])],1)]},proxy:true},{key:"item.contractAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.contractAmount, 'currency'))+" ")]}},{key:"item.collectedAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.collectedAmount, 'currency'))+" ")]}},{key:"item.contractBalance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.contractBalance, 'currency'))+" ")]}},{key:"item.totalExpense",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.totalExpense, 'currency'))+" ")]}},{key:"item.balanceFromCollection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.balanceFromCollection, 'currency'))+" ")]}}])}),_c('overlay')],1)}
var staticRenderFns = []

export { render, staticRenderFns }