<template>
  <v-container>
    <v-dialog persistent v-model="dialog" max-width="1500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="6">
                <v-card>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedInventoryItem.item" @input="$v.editedInventoryItem.item.$touch()" @blur="$v.editedInventoryItem.item.$touch()"
                          label="Item Name" required :error-messages="itemErrors"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="6">
                        <v-textarea dense v-model="editedInventoryItem.description" label="Description" rows="4" clearable outlined></v-textarea>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-menu v-model="dateAcquiredMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field dense label="Date Acquired" prepend-inner-icon="mdi-calendar" readonly :value="dateAcquiredDisp"
                                          v-on="on" required :error-messages="dateErrors" @input="$v.editedInventoryItem.dateAcquired.$touch()"
                                          @blur="$v.editedInventoryItem.dateAcquired.$touch()"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="editedInventoryItem.dateAcquired" no-title @input="dateAcquiredMenu = false"></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="6">
                        <v-text-field dense v-model="editedInventoryItem.serialNumber" label="Serial #"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field dense v-model="editedInventoryItem.manufacturer" label="Manufacturer"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-currency-field dense v-model="editedInventoryItem.quantity" label="Quantity" />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-currency-field dense v-model="editedInventoryItem.purchaseAmount" label="Purchase Amount" />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6">
                <!--picture goes here-->
                <v-card>
                  <v-card-title>
                    <h4>Picture</h4>
                  </v-card-title>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <v-file-input dense v-model="fileToUpload" label="Attach Picture" clearable></v-file-input>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" v-if="editedInventoryItem.picture">
                        <div style="width: 544px">
                          <zoom-on-hover :img-normal="'data:' + editedInventoryItem.contentType + ';base64,' + editedInventoryItem.picture"></zoom-on-hover>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" dark @click="save">Save</v-btn>
          <v-btn color="orange darken-1" dark @click="clear">Clear</v-btn>
          <v-btn color="red darken-1" dark @click="close">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'SaveInventoryItemDialog',
  data () {
    return {
      fileToUpload: null,
      dateAcquiredMenu: false,
      errors: {}
    }
  },
  validations: {
    editedInventoryItem: {
      item: { required },
      dateAcquired: { required }
    }
  },
  computed: {
    editedInventoryItem: {
      get () {
        return this.$store.state.inventoryitem.editedInventoryItem
      },
      set (value) {
        this.$store.commit('inventoryitem/setInventoryItemSuccess', value)
      }
    },
    dialog: {
      get () {
        return this.$store.state.inventoryitem.editInventoryItemDialog
      },
      set () {
        this.$store.commit('inventoryitem/toggleDialog')
      }
    },
    formTitle () {
      if (this.editedInventoryItem.id > 0) {
        return 'Edit Item ' + this.editedInventoryItem.item
      } else {
        return 'New Inventory Item '
      }
    },
    dateAcquiredDisp () {
      return this.editedInventoryItem.dateAcquired
    },
    itemErrors () {
      const errors = []
      if (!this.$v.editedInventoryItem.item.$dirty) return errors
      !this.$v.editedInventoryItem.item.required && errors.push('Item name is required')
      return errors
    },
    dateErrors () {
      const errors = []
      if (!this.$v.editedInventoryItem.dateAcquired.$dirty) return errors
      !this.$v.editedInventoryItem.dateAcquired.required && errors.push('Date acquired is required')
      return errors
    }
  },
  methods: {
    async save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let createFormData = new FormData()
        createFormData.append('file', this.fileToUpload)
        if (this.editedInventoryItem.id > 0) {
          try {
            createFormData.append('inventoryItemUpdateDTO', new Blob([JSON.stringify(this.editedInventoryItem)], { type: 'application/json' }))
            this.$store.commit('setOverlay', { text: 'Updating Inventory Item...', overlayState: true })
            let response = await this.$store.dispatch('inventoryitem/updateInventoryItem', createFormData)
            this.$store.commit('setOverlay', { text: 'Updating Inventory Item...', overlayState: false })
            this.$store.commit('showMessage', { message: response.data, color: 'success' })
          } catch (err) {
            console.error(err)
            this.$store.commit('setOverlay', { text: 'Updating Inventory Item...', overlayState: false })
            this.$store.commit('showMessage', { message: 'Update Inventory Item failed: ' + err.response.status + ': ' + err.response.data, color: 'error' })
          } finally {
            this.close()
            this.$store.commit('inventoryitem/setRenderKey')
            this.$store.commit('inventoryitem/setEditedInventoryItem', Object.assign({}, this.$store.getters['inventoryitem/defaultInventoryItem']))
          }
        } else {
          try {
            createFormData.append('inventoryItemCreateDTO', new Blob([JSON.stringify(this.editedInventoryItem)], { type: 'application/json' }))
            this.$store.commit('setOverlay', { text: 'Creating Inventory Item...', overlayState: true })
            let response = await this.$store.dispatch('inventoryitem/createInventoryItem', createFormData)
            this.$store.commit('setOverlay', { text: 'Creating Inventory Item...', overlayState: false })
            this.$store.commit('showMessage', { message: response.data, color: 'success' })
          } catch (err) {
            console.error(err)
            this.$store.commit('setOverlay', { text: 'Creating Inventory Item...', overlayState: false })
            this.$store.commit('showMessage', { message: 'Create Inventory Item failed: ' + err.response.status + ': ' + err.response.data, color: 'error' })
          } finally {
            this.close()
            this.$store.commit('inventoryitem/setRenderKey')
            this.$store.commit('inventoryitem/setEditedInventoryItem', Object.assign({}, this.$store.getters['inventoryitem/defaultInventoryItem']))
          }
        }
      }
    },
    clear () {
      this.$v.$reset()
      this.editedInventoryItem = this.$store.getters['inventoryitem/defaultInventoryItem']
    },
    close () {
      this.clear()
      this.$store.commit('inventoryitem/hideDialog')
    }
  }
}
</script>

<style scoped>

</style>
