<template>
  <v-container>
    <v-dialog persistent v-model="dialog" max-width="1500px">
      <v-card>
        <v-form>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6">
                  <v-card>
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="12" sm="6">
                          <v-select dense v-model="editedVoucher.voucherCategory" :items="$store.getters.voucherCategories"
                                    persistent-hint autofocus item-text="description" item-value="category" return-object
                                    :error-messages="voucherCategoryErrors" @input="$v.editedVoucher.voucherCategory.category.$touch()"
                                    @blur="$v.editedVoucher.voucherCategory.category.$touch()" label="Category">Category</v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-menu v-model="voucherDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                            offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{on}">
                              <v-text-field dense label="Voucher Date" prepend-inner-icon="mdi-calendar" readonly :value="voucherDateDisp"
                                            v-on="on" required :error-messages="dateErrors" @input="$v.editedVoucher.voucherDate.$touch()"
                                            @blur="$v.editedVoucher.voucherDate.$touch()"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="editedVoucher.voucherDate" no-title @input="voucherDateMenu = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12">
                          <v-textarea dense v-model="editedVoucher.description" label="Description" rows="3" clearable
                                      required :error-messages="descriptionErrors" @input="$v.editedVoucher.description.$touch()"
                                      @blur="$v.editedVoucher.description.$touch()" outlined></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" md="4">
                          <v-currency-field dense readonly v-model="editedVoucher.vat" label="VAT" />
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-currency-field dense readonly v-model="editedVoucher.vatableAmount" label="VATable Amount" />
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-container fluid>
                            <v-switch dense v-model="editedVoucher.vatable" label="Vatable"></v-switch>
                          </v-container>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" md="6">
                          <v-currency-field dense label="Amount" :error-messages="errors.rate" v-model.number="editedVoucher.amount" />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card>
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="12" sm="6">
                          <v-autocomplete dense v-model="editedVoucher.supplierDto" :items="suppliers" label="Select a supplier"
                                          :loading="loadingSuppliers" :search-input.sync="searchSupplier" item-text="name" item-value="id"
                                          @change="onSupplierSelect" filled return-object>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-container fluid>
                            <v-switch dense v-model="editedVoucher.paid" label="Paid"></v-switch>
                          </v-container>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="6">
                          <v-text-field dense v-model="editedVoucher.paidTo" label="Paid To"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field dense v-model="editedVoucher.tin" label="TIN #"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="6">
                          <v-text-field dense v-model="editedVoucher.receiptNo" label="Receipt #"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field dense v-model="editedVoucher.city" label="City"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <v-card>
                    <v-card-title>
                      <h4>Scanned Receipt</h4>
                    </v-card-title>
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="12" sm="6">
                          <v-file-input dense v-model="fileToUpload" label="Attach scanned receipt" clearable></v-file-input>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="editedVoucher.scannedReceipt">
                          <v-btn color="primary lighten-1" @click="scannedReceiptDialog = !scannedReceiptDialog">View Scanned Receipt</v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" dark @click="save">Save</v-btn>
            <v-btn color="orange darken-1" dark @click="clear">Clear</v-btn>
            <v-btn color="red darken-1" dark @click="close">Cancel</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="scannedReceiptDialog" width="600px">
      <v-card>
        <v-card-text>
          <div style="width: 400px">
            <zoom-on-hover :img-normal="'data:' + editedVoucher.contentType + ';base64,' + editedVoucher.scannedReceipt"></zoom-on-hover>
            <!--<v-img max-width="50%" max-height="50%" :src="'data:' + editedVoucher.contentType + ';base64,' + editedVoucher.scannedReceipt"></v-img>-->
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" @click="scannedReceiptDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { required, minValue } from 'vuelidate/lib/validators'
import SupplierDataService from '../../services/SupplierDataService'
export default {
  name: 'SaveVoucherDialog',
  props: {
    title: String,
    voucherToEdit: Object
  },
  data () {
    return {
      voucherDateMenu: false,
      fileToUpload: null,
      errors: {},
      searchSupplierTimer: null,
      suppliers: [],
      loadingSuppliers: false,
      searchSupplier: null,
      scannedReceiptDialog: false
    }
  },
  validations: {
    editedVoucher: {
      voucherDate: { required },
      description: { required },
      voucherCategory: {
        category: { minValue: minValue(1) }
      }
    }
  },
  computed: {
    editedVoucher: {
      get () {
        return this.$store.state.expenses.editedVoucher
      },
      set (value) {
        this.$store.commit('expenses/setEditedVoucherSuccess', value)
      }
    },
    dialog: {
      get () {
        return this.$store.state.expenses.editVoucherDialog
      },
      set () {
        this.$store.commit('expenses/toggleDialog')
      }
    },
    formTitle () {
      if (this.editedVoucher.id > 0) {
        return 'Edit Voucher ' + this.editedVoucher.voucherNumber
      } else {
        return 'New Voucher for Project ' + this.editedVoucher.projectNumber
      }
    },
    voucherDateDisp () {
      return this.editedVoucher.voucherDate
    },
    dateErrors () {
      const errors = []
      if (!this.$v.editedVoucher.voucherDate.$dirty) return errors
      !this.$v.editedVoucher.voucherDate.required && errors.push('Voucher Date is required')
      return errors
    },
    descriptionErrors () {
      const errors = []
      if (!this.$v.editedVoucher.description.$dirty) return errors
      !this.$v.editedVoucher.description.required && errors.push('Description is required')
      return errors
    },
    voucherCategoryErrors () {
      const errors = []
      if (!this.$v.editedVoucher.voucherCategory.category.$dirty) return errors
      !this.$v.editedVoucher.voucherCategory.category.minValue && errors.push('Voucher Category is required')
      return errors
    }
  },
  created () {
    this.findSuppliersByName('')
  },
  watch: {
    searchSupplier (value) {
      if (!value) {
        value = ''
      }
      this.searchSuppliersDebounced(value)
    }
  },
  methods: {
    searchSuppliersDebounced (value) {
      clearTimeout(this.searchSupplierTimer)
      if (this.searchSupplier === '' || this.searchSupplier == null || this.searchSupplier.length >= 3) {
        this.searchSupplierTimer = setTimeout(() => {
          this.findSuppliersByName(value)
        }, 500)
      }
    },
    findSuppliersByName (value) {
      if (!value) {
        this.suppliers = []
      }
      this.loadingSuppliers = true
      return SupplierDataService.findByName(value).then(response => {
        this.suppliers = response.data
        this.loadingSuppliers = false
      }, reject => {
        this.loadingSuppliers = false
        this.$store.commit('showMessage', { message: 'Search Supplier failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
      })
    },
    save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let createFormData = new FormData()
        createFormData.append('file', this.fileToUpload)
        if (this.editedVoucher.id > 0) {
          createFormData.append('voucherUpdateDTO', new Blob([JSON.stringify(this.editedVoucher)], { type: 'application/json' }))
          this.$store.commit('setOverlay', { text: 'Updating Expense...', overlayState: true })
          this.$store.dispatch('expenses/updateExpense', createFormData).then(response => {
            this.$store.dispatch('expenses/getTotalSpendAmount', this.$store.getters['project/project'].projectNumber)
            this.close()
          }, reject => {
            this.close()
          })
        } else {
          createFormData.append('voucherCreateDTO', new Blob([JSON.stringify(this.editedVoucher)], { type: 'application/json' }))
          this.$store.dispatch('expenses/createExpense', createFormData).then(response => {
            this.$store.dispatch('expenses/getTotalSpendAmount', this.$store.getters['project/project'].projectNumber)
            this.close()
          }, reject => {
            this.close()
          })
        }
      }
    },
    onSupplierSelect () {
      this.editedVoucher.paidTo = this.editedVoucher.supplierDto.name ? this.editedVoucher.supplierDto.name : ''
      this.editedVoucher.tin = this.editedVoucher.supplierDto.tin ? this.editedVoucher.supplierDto.tin : ''
      this.editedVoucher.city = this.editedVoucher.supplierDto.city ? this.editedVoucher.supplierDto.city : ''
    },
    supplierFilter (item, queryText, itemText) {
      const supplierName = item.name.toLowerCase()
      const searchText = queryText.toLowerCase()
      return searchText.length > 2 && supplierName.indexOf(searchText) > -1
    },
    clear () {
      this.$v.$reset()
      this.editedVoucher = this.$store.getters['expenses/defaultVoucher']
      this.editedVoucher.projectNumber = this.$store.getters['project/project'].projectNumber
      this.searchSupplier = null
      this.fileToUpload = null
    },
    close () {
      this.clear()
      this.$store.commit('expenses/hideDialog')
    }
  }
}
</script>

<style scoped>
</style>
