<template>
  <div>
    <v-data-table
      fixed-header
      height="400"
      :headers="headers"
      :items="summaries"
      :options.sync="options"
      :server-items-length="totalSummaries"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat color="primary lighten-1" dark>
          <v-toolbar-title class="title">Project Summary Page</v-toolbar-title>
          <v-spacer />
          <v-btn color="primary" @click="getCollectionsReport">Generate Collections Report</v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.contractAmount="{ item }">
        {{ $n(item.contractAmount, 'currency') }}
      </template>
      <template v-slot:item.collectedAmount="{ item }">
        {{ $n(item.collectedAmount, 'currency') }}
      </template>
      <template v-slot:item.contractBalance="{ item }">
        {{ $n(item.contractBalance, 'currency') }}
      </template>
      <template v-slot:item.totalExpense="{ item }">
        {{ $n(item.totalExpense, 'currency') }}
      </template>
      <template v-slot:item.balanceFromCollection="{ item }">
        {{ $n(item.balanceFromCollection, 'currency') }}
      </template>
    </v-data-table>
    <overlay></overlay>
  </div>
</template>

<script>
import ProjectDataService from '../../services/ProjectDataService'
import Overlay from '../global/Overlay'

export default {
  name: 'DisplayProjectSummariesComponent',
  components: { Overlay },
  data () {
    return {
      loading: true,
      totalSummaries: 0,
      summaries: [],
      options: {},
      headers: [
        { text: 'Project Number', align: 'left', sortable: true, value: 'projectNumber' },
        { text: 'Project Name', align: 'left', sortable: true, value: 'name' },
        { text: 'Contract Amount', align: 'left', sortable: false, value: 'contractAmount' },
        { text: 'Collected Amount', align: 'left', sortable: false, value: 'collectedAmount' },
        { text: 'Contract Balance', align: 'left', sortable: false, value: 'contractBalance' },
        { text: 'Total Expense', align: 'left', sortable: false, value: 'totalExpense' },
        { text: 'Balance from Collection', align: 'left', sortable: false, value: 'balanceFromCollection' }
      ]
    }
  },
  watch: {
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true
    }
  },
  methods: {
    getDataFromApi () {
      this.loading = true
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        if (itemsPerPage > 0) {
          let searchParams = {}
          searchParams.page = page
          searchParams.itemsPerPage = itemsPerPage
          if (sortBy.length > 0) {
            searchParams.sortBy = sortBy[0]
          }
          if (sortDesc.length > 0) {
            searchParams.sortDesc = sortDesc[0]
          }

          return ProjectDataService.getSummaries(searchParams).then(response => {
            this.summaries = response.data.content
            this.totalSummaries = response.data.totalElements
            this.loading = false
          })
        }
      })
    },
    getCollectionsReport () {
      this.$store.commit('setOverlay', { text: 'Generating Collections Report', overlayState: true })
      return ProjectDataService.getCollectionsReport().then(response => {
        this.$store.commit('setOverlay', { text: 'Generating Collections Report', overlayState: false })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'collectionreport.xlsx')
        document.body.appendChild(link)
        link.click()
      })
    }
  }
}
</script>

<style scoped>

</style>
