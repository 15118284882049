import ProjectDataService from '../../services/ProjectDataService'

export const contractTerm = {
  namespaced: true,
  modules: {
    paginated: {
      namespaced: true,
      state: {
        contractTerms: [],
        totalContractTerms: 0,
        contractTermsRenderKey: 0
      },
      actions: {
        searchContractTerms ({ state, commit, rootState }, payload) {
          return ProjectDataService.searchContractTerms(payload.options).then(response => {
            commit('setContractTermsSuccess', response.data)
          }, reject => {
            commit('setContractTermsFailure')
          })
        }
      },
      mutations: {
        setContractTermsSuccess (state, data) {
          state.contractTerms = data.content
          state.totalContractTerms = data.totalElements
        },
        setContractTermsFailure (state) {
          state.contractTerms = []
          state.totalContractTerms = 0
        },
        setContractTermsRenderKey (state) {
          state.contractTermsRenderKey += 1
        }
      },
      getters: {
        contractTerms (state) {
          return state.contractTerms
        },
        totalContractTerms (state) {
          return state.totalContractTerms
        },
        contractTermsRenderKey (state) {
          return state.contractTermsRenderKey
        }
      }
    },
    unPaginated: {
      namespaced: true,
      state: {
        contractTerms: []
      },
      actions: {
        getContractTerms ({ state, commit }, projectNumber) {
          return ProjectDataService.getContractTerms(projectNumber).then(response => {
            commit('setContractTerms', response.data)
          })
        }
      },
      mutations: {
        setContractTerms (state, data) {
          state.contractTerms = data
        }
      },
      getters: {
        contractTerms (state) {
          return state.contractTerms
        }
      }
    }
  },
  state: {
    editedContractTerm: {
      id: 0,
      projectNumber: '',
      description: '',
      amount: 0,
      date: ''
    },
    defaultContractTerm: {
      id: 0,
      projectNumber: '',
      description: '',
      amount: 0,
      date: ''
    },
    totalContractTermAmount: 0,
    editContractTermDialog: false
  },
  actions: {
    getContractTerm ({ state, commit }, id) {
      return ProjectDataService.getContractTermById(id).then(response => {
        commit('setEditedContractTerm', response.data)
      })
    },
    createContractTerm ({ state, commit }, payload) {
      return ProjectDataService.createContractTerm(payload).then(response => {
        commit('showMessage', { message: response.data, color: 'success' }, { root: true })
        commit('setContractTermsRenderKey')
      })
    },
    updateContractTerm ({ state, commit }, payload) {
      return ProjectDataService.updateContractTerm(payload).then(response => {
        commit('showMessage', { message: response.data, color: 'success' }, { root: true })
        commit('setContractTermsRenderKey')
      })
    },
    deleteContractTerm ({ state, commit }, id) {
      return ProjectDataService.deleteContractTerm(id).then(response => {
        commit('showMessage', { message: response.data, color: 'success' }, { root: true })
        commit('setContractTermsRenderKey')
      })
    },
    getTotalContractTermAmount ({ state, commit }, projectNumber) {
      return ProjectDataService.getTotalContractAmount(projectNumber).then(response => {
        commit('setTotalContractTermAmount', response.data)
      }, reject => {
        commit('setTotalContractTermAmount', 0)
      })
    }
  },
  mutations: {
    setEditedContractTerm (state, data) {
      state.editedContractTerm = data
    },
    setTotalContractTermAmount (state, data) {
      state.totalContractTermAmount = data
    },
    setContractTermProjectNumber (state, data) {
      state.editedContractTerm.projectNumber = data
    },
    toggleContractTermDialog (state) {
      state.editContractTermDialog = !state.editContractTermDialog
    },
    showContractTermDialog (state) {
      state.editContractTermDialog = true
    },
    hideContractTermDialog (state) {
      state.editContractTermDialog = false
    }
  },
  getters: {
    editedContractTerm (state) {
      return state.editedContractTerm
    },
    defaultContractTerm (state) {
      return state.defaultContractTerm
    },
    totalContractTermAmount (state) {
      return state.totalContractTermAmount
    }
  }
}
