var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{key:_vm.renderKey,staticClass:"elevation-1",attrs:{"fixed-header":"","height":"300","headers":_vm.headers,"items":_vm.contractTerms,"options":_vm.options,"server-items-length":_vm.totalContractTerms,"loading":_vm.loading,"footer-props":{
      'items-per-page-options':[5,10,30,100,300],
      'showFirstLastPage': true,
      'showCurrentPage': true
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},on:{"keyup":_vm.searchDebounced},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.openDialog}},[_c('v-icon',[_vm._v("mdi-plus-box")])],1)]}}])},[_c('span',[_vm._v("Add New Contract Term")])])],1)]},proxy:true},{key:"item.amount",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.amount, 'currency'))+" ")]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editContractTerm(item)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Edit Contract Term")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.deleteContractTerm(item.id)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Delete Contract Term")])])]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('span',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v("Total:")])]),_c('td',[_c('p',{staticClass:"text-right font-weight-bold subtitle-1"},[_vm._v(_vm._s(_vm.$n(_vm.totalContract, 'currency')))])])])]},proxy:true},{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(new Date(item.date), 'short'))+" ")]}}])}),_c('save-contract-term-dialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }