<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">Supplier List</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          fixed-header
          height="400"
          :headers="headers"
          :items="suppliers" :options.sync="options"
          :server-items-length="totalSuppliers"
          :loading="loading"
          :footer-props="{
            'items-per-page-options':[5,10,30,100,300],
            'showFirstLastPage': true,
            'showCurrentPage': true }"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat color="primary lighten-1" dark>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details @keyup="searchDebounced" />
              <v-divider class="mx-4" inset vertical/>
              <v-dialog persistent v-model="dialog" max-width="750px">
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" dark class="mb-2" v-on="on">New Supplier</v-btn>
                </template>
                <v-card>
                  <v-form>
                    <v-card-title>
                      <span class="headline">{{formTitle}}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row dense>
                          <v-col cols="12">
                            <v-text-field
                              required
                              autofocus
                              :error-messages="nameErrors"
                              @input="$v.editedSupplier.name.$touch()"
                              @blur="$v.editedSupplier.name.$touch()"
                              v-model="editedSupplier.name" label="Name"></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field v-model="editedSupplier.address" label="Address"></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field v-model="editedSupplier.tin" label="TIN #"></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field v-model="editedSupplier.city" label="City"></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field v-model="editedSupplier.contactPerson" label="Contact Person"></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field v-model="editedSupplier.contactNumber" label="Contact Number"></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              :error-messages="emailErrors"
                              @input="$v.editedSupplier.email.$touch()"
                              @blur="$v.editedSupplier.email.$touch()"
                              v-model="editedSupplier.email" label="E-Mail" type="email"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary darken-1" dark @click="save">Save</v-btn>
                      <v-btn color="orange darken-1" dark @click="clear">Clear</v-btn>
                      <v-btn color="red darken-1" dark @click="close">Cancel</v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.action="{item}">
            <v-icon small class="mr-2" @click="editSupplier(item)">mdi-pencil</v-icon>
            <v-icon small @click="deleteSupplier(item.id)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <snackbar></snackbar>
    <overlay></overlay>
  </div>
</template>

<script>
import SupplierDataService from '../../services/SupplierDataService'
import { required, email } from 'vuelidate/lib/validators'
import Snackbar from '../global/Snackbar'
import Overlay from '../global/Overlay'

export default {
  name: 'ListSuppliersComponent',
  components: { Overlay, Snackbar },
  data () {
    return {
      search: '',
      loading: false,
      options: {},
      errors: {},
      suppliers: [],
      message: '',
      totalSuppliers: 0,
      headers: [
        { text: 'Supplier', align: 'center', sortable: true, value: 'name' },
        { text: 'Address', align: 'center', sortable: true, value: 'address' },
        { text: 'TIN', align: 'center', sortable: true, value: 'tin' },
        { text: 'City', align: 'center', sortable: true, value: 'city' },
        { text: 'Contact Person', align: 'center', sortable: true, value: 'contactPerson' },
        { text: 'Contact Number', align: 'center', sortable: true, value: 'contactNumber' },
        { text: 'E-Mail', align: 'center', sortable: true, value: 'email' },
        { text: 'Actions', align: 'center', sortable: false, value: 'action' }
      ],
      dialog: false,
      editedIndex: -1,
      editedSupplier: {
        id: 0,
        name: '',
        address: '',
        tin: '',
        city: '',
        contactPerson: '',
        contactNumber: '',
        email: ''
      },
      defaultSupplier: {
        id: 0,
        name: '',
        address: '',
        tin: '',
        city: '',
        contactPerson: '',
        contactNumber: '',
        email: ''
      },
      searchTimer: null
    }
  },
  validations: {
    editedSupplier: {
      name: { required },
      email: { email }
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Supplier' : 'Edit Supplier'
    },
    nameErrors () {
      const errors = []
      if (!this.$v.editedSupplier.name.$dirty) return errors
      !this.$v.editedSupplier.name.required && errors.push('Please enter a client name')
      return errors
    },
    emailErrors () {
      const errors = []
      if (!this.$v.editedSupplier.email.$dirty) return errors
      !this.$v.editedSupplier.email.email && errors.push('Email should be in correct format')
      return errors
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    options: {
      handler () {
        this.getDataFromApi('')
      },
      deep: true
    }
  },
  methods: {
    searchDebounced () {
      clearTimeout(this.searchTimer)
      if (this.search === '' || this.search == null || this.search.length >= 3) {
        this.searchTimer = setTimeout(() => {
          this.getDataFromApi(this.search)
        }, 500)
      }
    },
    getDataFromApi (searchTerm) {
      this.loading = true
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (itemsPerPage > 0) {
          let searchParams = {}
          searchParams.page = page
          searchParams.itemsPerPage = itemsPerPage
          if (sortBy.length > 0) {
            searchParams.sortBy = sortBy[0]
          }
          if (sortDesc.length > 0) {
            searchParams.sortDesc = sortDesc[0]
          }
          if (this.search) {
            searchParams.searchTerm = this.search
          }
          return SupplierDataService.retrieveSuppliersPaginated(searchParams).then(response => {
            this.suppliers = response.data.content
            this.totalSuppliers = response.data.totalElements
            this.loading = false
          })
        }
      })
    },
    close () {
      this.dialog = false
      this.$v.$reset()
      setTimeout(() => {
        this.editedSupplier = Object.assign({}, this.defaultSupplier)
        this.editedIndex = -1
      }, 300)
    },
    save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.editedIndex > -1) {
          this.$store.commit('setOverlay', { text: 'Updating Supplier', overlayState: true })
          return SupplierDataService.updateSupplier(this.editedSupplier).then(response => {
            this.$store.commit('setOverlay', { text: 'Updating Supplier', overlayState: false })
            this.$store.commit('showMessage', { message: response.data, color: 'success' })
            this.getDataFromApi('')
            this.close()
          }, reject => {
            this.$store.commit('setOverlay', { text: 'Updating Supplier', overlayState: false })
            this.$store.commit('showMessage', { message: 'Update Supplier failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
            this.close()
          })
        } else {
          this.$store.commit('setOverlay', { text: 'Creating Supplier', overlayState: true })
          return SupplierDataService.createSupplier(this.editedSupplier).then(response => {
            this.$store.commit('setOverlay', { text: 'Creating Supplier', overlayState: false })
            this.$store.commit('showMessage', { message: response.data, color: 'success' })
            this.getDataFromApi('')
            this.close()
          }, reject => {
            this.$store.commit('setOverlay', { text: 'Creating Supplier', overlayState: false })
            this.$store.commit('showMessage', { message: 'Create Supplier failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
            this.close()
          })
        }
      }
    },
    editSupplier (item) {
      this.editedIndex = this.suppliers.indexOf(item)
      this.dialog = true
      this.$store.commit('setOverlay', { text: 'Retrieving Supplier Details', overlayState: true })
      SupplierDataService.getSupplierById(item.id).then(response => {
        this.$store.commit('setOverlay', { text: 'Retrieving Supplier Details', overlayState: false })
        this.editedSupplier = response.data
      }, reject => {
        this.$store.commit('setOverlay', { text: 'Retrieving Supplier Details', overlayState: false })
        this.$store.commit('showMessage', { message: 'Get Supplier details failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
      })
    },
    deleteSupplier (id) {
      if (confirm('Do you want to delete this supplier?')) {
        this.$store.commit('setOverlay', { text: 'Deleting Supplier', overlayState: true })
        SupplierDataService.deleteSupplier(id).then(response => {
          this.$store.commit('setOverlay', { text: 'Deleting Supplier', overlayState: false })
          this.message = 'Delete of Supplier Successful'
          this.getDataFromApi('')
        }, reject => {
          this.$store.commit('setOverlay', { text: 'Deleting Supplier', overlayState: false })
          this.$store.commit('showMessage', { message: 'Delete Supplier failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
          this.close()
        })
      }
    },
    clear () {
      this.$v.$reset()
      this.editedSupplier = this.defaultSupplier
    }
  }
}
</script>

<style scoped>

</style>
