import AuthService from '../../services/AuthService'

export const auth = {
  namespaced: true,
  state: () => ({
    isLoggedIn: false,
    username: null,
    fullName: null,
    authorities: []
  }),
  actions: {
    login ({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user)
          return Promise.resolve(user)
        }, error => {
          commit('loginFailure')
          return Promise.reject(error)
        })
    },
    logout ({ commit }) {
      commit('logout')
    }
  },
  mutations: {
    loginSuccess (state, response) {
      state.isLoggedIn = true
      state.username = response.username
      state.fullName = response.fullName
      state.authorities = response.authorities
    },
    loginFailure (state) {
      state.isLoggedIn = false
      state.username = null
      state.fullName = null
      state.authorities = []
    },
    logout (state) {
      state.isLoggedIn = false
      state.username = null
      state.fullName = null
      state.authorities = []
    }
  },
  getters: {
    isLoggedIn (state) {
      return state.isLoggedIn
    },
    username (state) {
      return state.username
    },
    fullName (state) {
      return state.fullName
    },
    authorities (state) {
      return state.authorities
    }
  }
}
