<template>
  <v-container>
    <v-dialog persistent v-model="dialog" max-width="1500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="6">
                <v-card>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <v-text-field v-model="editedProject.name" label="Project name" required :error-messages="nameErrors"
                          @input="$v.editedProject.name.$touch()" @blur="$v.editedProject.name.$touch()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field required v-model="editedProject.projectType" label="Project Type" :error-messages="projectTypeErrors"
                          @input="$v.editedProject.projectType.$touch()" @blur="$v.editedProject.projectType.$touch()"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-menu v-model="startDateMenu" :close-on-content-click="false" :nudge-right="40"
                          transition="scale-transition" offset-y required max-width="290px" min-width="290px">
                          <template v-slot:activator="{on}">
                            <v-text-field label="Start Date" prepend-inner-icon="mdi-calendar" readonly
                              :value="startDateDisp" v-on="on" required :error-messages="startDateErrors"
                              @input="$v.editedProject.startDate.$touch()" @blur="$v.editedProject.startDate.$touch()"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="editedProject.startDate" no-title @input="startDateMenu = false"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12">
                        <v-menu v-model="endDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                          offset-y max-width="290px" min-width="290px">
                          <template v-slot:activator="{on}">
                            <v-text-field label="End Date" prepend-inner-icon="mdi-calendar" readonly :value="endDateDisp" v-on="on"></v-text-field>
                          </template>
                          <v-date-picker v-model="editedProject.endDate" no-title @input="endDateMenu = false"></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6">
                <v-card>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <v-select v-model="editedProject.clientDisplayDto" :items="$store.getters.clients" item-text="clientName" item-value="id"
                                  hint="Select Client" persistent-hint :error-messages="clientErrors"
                                  @input="$v.editedProject.clientDisplayDto.id.$touch()" @blur="$v.editedProject.clientDisplayDto.id.$touch()"
                                  return-object label="Client">Client</v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field v-model="editedProject.voucherName" label="Voucher Name"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-select required v-model="editedProject.allowedUsers"
                                  :items="$store.getters.users" item-text="fullName" item-value="id" multiple chips hint="Select Users allowed to view this project"
                                  persistent-hint :error-messages="allowedUserErrors" return-object @input="$v.editedProject.allowedUsers.$touch()"
                                  @blur="$v.editedProject.allowedUsers.$touch()" label="Allowed Users">Allowed Users</v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" dark @click="save">Save</v-btn>
          <v-btn color="orange darken-1" dark @click="clear">Clear</v-btn>
          <v-btn color="red darken-1" dark @click="close">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { minValue, required } from 'vuelidate/lib/validators'

export default {
  name: 'SaveProjectDialog',
  data () {
    return {
      errors: {},
      startDateMenu: false,
      endDateMenu: false,
      clients: []
    }
  },
  validations: {
    editedProject: {
      name: { required },
      clientDisplayDto: {
        id: { required, minValue: minValue(1) }
      },
      startDate: { required },
      projectType: { required },
      allowedUsers: { required }
    }
  },
  computed: {
    editedProject: {
      get () {
        return this.$store.state.project.editedProject
      },
      set (value) {
        this.$store.commit('project/setEditedProject', value)
      }
    },
    dialog: {
      get () {
        return this.$store.state.project.editProjectDialog
      },
      set () {
        this.$store.commit('project/toggleDialog')
      }
    },
    formTitle () {
      if (this.editedProject.id > 0) {
        return 'Edit Project ' + this.editedProject.name
      } else {
        return 'New Project'
      }
    },
    startDateDisp () {
      return this.editedProject.startDate
    },
    endDateDisp () {
      return this.editedProject.endDate
    },
    clientErrors () {
      const errors = []
      if (!this.$v.editedProject.clientDisplayDto.id.$dirty) return errors
      !this.$v.editedProject.clientDisplayDto.id.minValue && errors.push('Please select a client')
      return errors
    },
    nameErrors () {
      const errors = []
      if (!this.$v.editedProject.name.$dirty) return errors
      !this.$v.editedProject.name.required && errors.push('Please put a project name')
      return errors
    },
    startDateErrors () {
      const errors = []
      if (!this.$v.editedProject.startDate.$dirty) return errors
      !this.$v.editedProject.startDate.required && errors.push('Please put a start date')
      return errors
    },
    projectTypeErrors () {
      const errors = []
      if (!this.$v.editedProject.projectType.$dirty) return errors
      !this.$v.editedProject.projectType.required && errors.push('Please put a project type')
      return errors
    },
    allowedUserErrors () {
      const errors = []
      if (!this.$v.editedProject.allowedUsers.$dirty) return errors
      !this.$v.editedProject.allowedUsers.required && errors.push('Add users allowed to view this project')
      return errors
    }
  },
  methods: {
    async save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.editedProject.id > 0) {
          try {
            this.$store.commit('setOverlay', { text: 'Updating Project...', overlayState: true })
            let response = await this.$store.dispatch('project/updateProject', this.editedProject)
            this.$store.commit('setOverlay', { text: 'Updating Project...', overlayState: false })
            this.$store.commit('showMessage', { message: response.data, color: 'success' })
          } catch (err) {
            this.$store.commit('setOverlay', { text: 'Updating Project...', overlayState: false })
            this.$store.commit('showMessage', { message: 'Update Project failed with Status ' + err.response.status + ': ' + err.response.data, color: 'error' })
          } finally {
            this.close()
            this.$store.commit('project/setRenderKey')
            this.$store.commit('project/setEditedProject', Object.assign({}, this.$store.getters['project/defaultProject']))
          }
        } else {
          try {
            this.$store.commit('setOverlay', { text: 'Creating Project...', overlayState: true })
            let response = await this.$store.dispatch('project/createProject', this.editedProject)
            this.$store.commit('setOverlay', { text: 'Creating Project...', overlayState: false })
            this.$store.commit('showMessage', { message: response.data, color: 'success' })
          } catch (err) {
            this.$store.commit('setOverlay', { text: 'Creating Project...', overlayState: false })
            this.$store.commit('showMessage', { message: 'Create Project failed with Status ' + err.response.status + ': ' + err.response.data, color: 'error' })
          } finally {
            this.close()
            this.$store.commit('project/setRenderKey')
            this.$store.commit('project/setEditedProject', Object.assign({}, this.$store.getters['project/defaultProject']))
          }
        }
      }
    },
    clear () {
      this.$v.$reset()
      this.editedProject = this.$store.getters['project/defaultProject']
    },
    close () {
      this.clear()
      this.$store.commit('project/hideDialog')
    }
  }
}
</script>

<style scoped>

</style>
