<template>
  <v-card tile>
    <v-card-title>
      <router-link :to="'/project/' + project.id" class="headline text-none">{{ project.name }}</router-link>
      <div class="title">{{ project.projectType }}</div>
    </v-card-title>
    <v-card-text>
      <div class="subtitle-1">Client: {{ project.clientDisplayDto.clientName }}</div>
      <div class="subtitle-1">Start Date: {{ $d(new Date(project.startDate), 'short') }}</div>
      <div class="subtitle-1">End Date: {{ $d(new Date(project.endDate), 'short') }}</div>
    </v-card-text>
    <v-card-actions>
      <v-container>
        <v-row dense>
          <v-col>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn small v-on="on" :to="'/' + project.id + '/vouchers'">
                  <v-icon class="mr-1" small>mdi-cash-minus</v-icon>Expenses
                </v-btn>
              </template>
              <span>Expenses</span>
            </v-tooltip>
          </v-col>
          <v-col>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn small v-on="on" :to="'/' + project.id + '/payments'">
                  <v-icon class="mr-1" small>mdi-cash-plus</v-icon>Collections
                </v-btn>
              </template>
              <span>Collections</span>
            </v-tooltip>
          </v-col>
          <v-col>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn small v-on="on" :to="'/' + project.id + '/pettycash'">
                  <v-icon class="mr-1" small>mdi-note-outline</v-icon>Petty Cash
                </v-btn>
              </template>
              <span>Petty Cash</span>
            </v-tooltip>
          </v-col>
          <v-col>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn small v-on="on" :to="'/' + project.id + '/billofmaterials'">
                  <v-icon class="mr-1" small>mdi-clipboard-text-outline</v-icon>BOM
                </v-btn>
              </template>
              <span>Bill of Materials</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'DisplayProjectDetailsAsCard',
  props: {
    project: Object
  }
}
</script>

<style scoped>

</style>
