import { AXIOS } from './Axios-Common'

const URL = '/purchases'
class PurchaseDataService {
  getPurchaseItemById (id) {
    return AXIOS.get(`${URL}/purchase/${id}`)
  }
  retrievePurchasesPaginated (projectNumber, params) {
    return AXIOS.get(`${URL}/searchpurchaseitemsbybomnumber`, {
      params: {
        offset: params.page - 1,
        limit: params.itemsPerPage,
        searchTerm: params.searchTerm,
        projectNumber: projectNumber,
        sortBy: params.sortBy,
        sortDesc: params.sortDesc
      }
    })
  }
  createPurchaseItem (purchaseItem) {
    return AXIOS.post(`${URL}/purchaseitem`, purchaseItem)
  }
  updatePurchaseItem (purchaseItem) {
    return AXIOS.put(`${URL}/purchaseitem`, purchaseItem)
  }
  deletePurchaseItem (id) {
    return AXIOS.delete(`${URL}/purchaseitem/${id}`)
  }
}
export default new PurchaseDataService()
