var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('v-sheet',{attrs:{"color":"primary lighten-1","dark":"","width":"100%","max-width":"100%"}},[_c('span',{staticClass:"headline ma-2"},[_vm._v("Project List")])])],1),_c('v-card-text',[_c('v-data-table',{key:_vm.renderKey,staticClass:"elevation-1",attrs:{"fixed-header":"","height":"400","headers":_vm.headers,"items":_vm.$store.getters['project/projects'],"options":_vm.options,"server-items-length":_vm.$store.getters['project/totalProjects'],"loading":_vm.loading,"footer-props":{ 'items-per-page-options':[5,10,30,100,300] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},on:{"keyup":_vm.searchProjectsDebounced},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.openDialog}},on),[_c('v-icon',[_vm._v("mdi-plus-box")])],1)]}}])},[_c('span',[_vm._v("Add New Project")])])],1)]},proxy:true},{key:"item.projectNumber",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"body-1 font-weight-bold",attrs:{"to":'/project/' + item.id}},[_vm._v(_vm._s(item.projectNumber))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editProject(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteProject(item.id)}}},[_vm._v("mdi-delete")])]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [(item.startDate != null)?_c('span',[_vm._v(_vm._s(_vm.$d(new Date(item.startDate), 'short')))]):_vm._e()]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [(item.endDate != null)?_c('span',[_vm._v(_vm._s(_vm.$d(new Date(item.endDate), 'short')))]):_vm._e()]}}])})],1)],1),_c('save-project-dialog'),_c('overlay'),_c('snackbar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }