<template>
  <div>
    <div>
      <v-sheet color="primary lighten-1" dark width="100%" max-width="100%">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn dark color="primary lighten-1" v-on="on" :to="'/' + pettyCash.projectId + '/pettycash'" class="ma-3">
              <v-icon large>mdi-arrow-left-circle</v-icon>
            </v-btn>
          </template>
          <span>Back To Petty Cash List</span>
        </v-tooltip>
        <span class="ma-2 headline">Petty Cash Item List for {{ pettyCash.description }}</span>
      </v-sheet>
    </div>
    <div>
      <v-data-table
        fixed-header
        height="400"
        :headers="headers"
        :items="pettyCashItems"
        :options.sync="options"
        :server-items-length="totalPettyCashItems"
        :loading="loading"
        :footer-props="{
        'items-per-page-options':[5,10,30,100,300],
        'showFirstLastPage': true,
        'showCurrentPage': true
      }"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details @keyup="searchDebounced" />
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-dialog persistent v-model="dialog" max-width="1500px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2" v-on="on">New Petty Cash Item</v-btn>
              </template>
              <v-card>
                <v-form v-model="valid">
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row dense>
                        <v-col cols="12" sm="6">
                          <v-card>
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="12" sm="6">
                                  <v-select v-model="editedPettyCashItem.voucherCategory"
                                            :items="$store.getters.voucherCategories"
                                            hint="Select Category"
                                            persistent-hint
                                            autofocus
                                            item-text="description"
                                            item-value="category"
                                            return-object
                                            :error-messages="voucherCategoryErrors"
                                            @input="$v.editedPettyCashItem.voucherCategory.category.$touch()"
                                            @blur="$v.editedPettyCashItem.voucherCategory.category.$touch()"
                                            label="Category">Category</v-select>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <v-menu v-model="pettyCashItemDateMenu"
                                          :close-on-content-click="false"
                                          :nudge-right="40"

                                          transition="scale-transition"
                                          offset-y

                                          max-width="290px"
                                          min-width="290px">
                                    <template v-slot:activator="{ on }">
                                      <v-text-field label="Date"
                                                    prepend-inner-icon="mdi-calendar"
                                                    readonly
                                                    :value="pettyCashItemDateDisp"
                                                    required
                                                    :error-messages="dateErrors"
                                                    @input="$v.editedPettyCashItem.date.$touch()"
                                                    @blur="$v.editedPettyCashItem.date.$touch()"
                                                    v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="editedPettyCashItem.date" no-title @input="pettyCashItemDateMenu = false"></v-date-picker>
                                  </v-menu>
                                </v-col>
                              </v-row>
                              <v-row dense>
                                <v-col cols="12">
                                  <v-textarea v-model="editedPettyCashItem.description" label="Description" clearable outlined></v-textarea>
                                </v-col>
                              </v-row>
                              <v-row dense>
                                <v-col cols="12" md="4">
                                  <v-text-field v-model="editedPettyCashItem.vat" label="VAT" readonly/>
                                </v-col>
                                <v-col cols="12" md="4">
                                  <v-text-field v-model="editedPettyCashItem.vatableAmount" label="VATable Amount" readonly/>
                                </v-col>
                                <v-col cols="12" md="4">
                                  <v-container fluid>
                                    <v-switch v-model="editedPettyCashItem.vatable" label="Vatable"></v-switch>
                                  </v-container>
                                </v-col>
                              </v-row>
                              <v-row dense>
                                <v-col cols="12" sm="6">
                                  <v-currency-field label="Amount" :error-messages="errors.rate" v-model.number="editedPettyCashItem.amount"></v-currency-field>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-card>
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="12">
                                  <v-autocomplete v-model="editedPettyCashItem.supplierDto"
                                                  :items="suppliers"
                                                  item-text="name"
                                                  item-value="id"
                                                  :loading="loadingSuppliers"
                                                  :search-input.sync="searchSupplier"
                                                  @change="onSupplierSelect"
                                                  filled label="Choose a supplier" return-object>
                                  </v-autocomplete>
                                </v-col>
                              </v-row>
                              <v-row dense>
                                <v-col cols="12" sm="6">
                                  <v-text-field v-model="editedPettyCashItem.paidTo" label="Paid To"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <v-text-field v-model="editedPettyCashItem.tin" label="TIN #"></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row dense>
                                <v-col cols="12" sm="6">
                                  <v-text-field v-model="editedPettyCashItem.receiptNo" label="Receipt #"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <v-text-field v-model="editedPettyCashItem.city" label="City"></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row dense>
                                <v-col cols="12" sm="6">
                                  <v-file-input v-model="fileToUpload" label="Attach scanned receipt" clearable></v-file-input>
                                </v-col>
                                <v-col cols="12" sm="6" v-if="editedPettyCashItem.scannedReceipt">
                                  <v-btn color="primary lighten-1" @click="scannedReceiptDialog = !scannedReceiptDialog">View Scanned Receipt</v-btn>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" dark @click="save">Save</v-btn>
                    <v-btn color="orange darken-1" dark @click="clear">Clear</v-btn>
                    <v-btn color="red darken-1" dark @click="close">Cancel</v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="scannedReceiptDialog" width="600px">
              <v-card>
                <v-card-title>
                  <span class="headline">Scanned Receipt</span>
                </v-card-title>
                <v-card-text>
                  <div style="width: 400px">
                    <zoom-on-hover :img-normal="'data:' + editedPettyCashItem.contentType + ';base64,' + editedPettyCashItem.scannedReceipt"></zoom-on-hover>
                    <!--<v-img max-width="50%" max-height="50%" :src="'data:' + editedVoucher.contentType + ';base64,' + editedVoucher.scannedReceipt"></v-img>-->
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer/>
                  <v-btn color="primary" @click="scannedReceiptDialog = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.amount="{ item }">
          {{ $n(item.amount, 'currency') }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-tooltip bottom v-if="!item.hasVoucher">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" small class="mr-2" @click="convertToVoucher(item.id)">mdi-cash</v-icon>
            </template>
            <span>Convert to Voucher</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" small class="mr-2" @click="editPettyCashItem(item)">mdi-pencil</v-icon>
            </template>
            <span>Edit Item</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" small @click="deletePettyCashItem(item.id)">mdi-delete</v-icon>
            </template>
            <span>Delete Item</span>
          </v-tooltip>
        </template>
        <template v-slot:item.date="{ item }">
          {{ $d(new Date(item.date), 'short') }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import PettyCashItemDataService from '../../services/PettyCashItemDataService'
import SupplierDataService from '../../services/SupplierDataService'
import { minValue, required } from 'vuelidate/lib/validators'
import PettyCashDataService from '../../services/PettyCashDataService'

export default {
  name: 'ListPettyCashItemComponent',
  props: ['pettycashnumber'],
  data () {
    return {
      pettyCash: {
        id: 0,
        projectNumber: '',
        projectId: 0,
        pettyCashNumber: '',
        amount: 0,
        description: '',
        date: '',
        paymentSource: ''
      },
      search: '',
      valid: false,
      loading: true,
      totalPettyCashItems: 0,
      pettyCashItems: [],
      options: {},
      errors: {},
      fileToUpload: null,
      headers: [
        { text: 'Petty Cash Item Number', align: 'center', sortable: true, value: 'pettyCashItemNumber' },
        { text: 'Receipt Number', align: 'center', sortable: true, value: 'receiptNo' },
        { text: 'Date', align: 'center', sortable: true, value: 'date' },
        { text: 'Description', align: 'center', sortable: true, value: 'description' },
        { text: 'Amount', align: 'center', sortable: true, value: 'amount' },
        { text: 'Paid To', align: 'center', sortable: true, value: 'paidTo' },
        { text: 'Actions', align: 'center', sortable: false, value: 'action' }
      ],
      dialog: false,
      pettyCashItemDateMenu: false,
      editedIndex: -1,
      editedPettyCashItem: {
        id: 0,
        pettyCashItemNumber: '',
        pettyCashNumber: '',
        description: '',
        date: '',
        amount: 0,
        paidTo: '',
        receiptNo: '',
        tin: '',
        city: '',
        vatableAmount: 0,
        vat: 0,
        vatable: false,
        hasVoucher: false,
        voucherCategory: {
          category: 0,
          description: ''
        },
        pettyCashDto: {
          id: 0,
          pettyCashNumber: ''
        },
        supplierDto: {
          id: 0,
          name: ''
        }
      },
      defaultPettyCashItem: {
        id: 0,
        pettyCashItemNumber: '',
        pettyCashNumber: '',
        description: '',
        date: '',
        amount: 0,
        paidTo: '',
        receiptNo: '',
        tin: '',
        city: '',
        vatableAmount: 0,
        vat: 0,
        vatable: false,
        hasVoucher: false,
        voucherCategory: {
          category: 0,
          description: ''
        },
        pettyCashDto: {
          id: 0,
          pettyCashNumber: ''
        },
        supplierDto: {
          id: 0,
          name: ''
        }
      },
      searchTimer: null,
      searchSupplierTimer: null,
      suppliers: [],
      loadingSuppliers: false,
      searchSupplier: null,
      scannedReceiptDialog: false
    }
  },
  validations: {
    editedPettyCashItem: {
      date: { required },
      voucherCategory: {
        category: { minValue: minValue(1) }
      }
    }
  },
  created () {
    PettyCashDataService.getPettyCashById(this.$route.params.pettycashnumber).then(response => {
      this.setPettyCash(response.data)
    })
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1
        ? 'New Petty Cash Item for ' + this.pettyCash.pettyCashNumber : 'Edit PettyCashItem ' + this.editedPettyCashItem.pettyCashItemNumber
    },
    pettyCashItemDateDisp () {
      return this.editedPettyCashItem.date
    },
    dateErrors () {
      const errors = []
      if (!this.$v.editedPettyCashItem.date.$dirty) return errors
      !this.$v.editedPettyCashItem.date.required && errors.push('Please enter a date')
      return errors
    },
    voucherCategoryErrors () {
      const errors = []
      if (!this.$v.editedPettyCashItem.voucherCategory.category.$dirty) return errors
      !this.$v.editedPettyCashItem.voucherCategory.category.minValue && errors.push('Voucher Category is required')
      return errors
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    options: {
      handler () {
        this.getDataFromApi(this.pettyCash.pettyCashNumber)
      },
      deep: true
    },
    searchSupplier (value) {
      if (!value) {
        return
      }
      this.searchSuppliersDebounced(value)
    }
  },
  methods: {
    setPettyCash (data) {
      this.pettyCash = data
      this.editedPettyCashItem.pettyCashNumber = data.pettyCashNumber
      this.editedPettyCashItem.pettyCashDto.pettyCashNumber = data.pettyCashNumber
      this.defaultPettyCashItem.pettyCashNumber = data.pettyCashNumber
      this.defaultPettyCashItem.pettyCashDto.pettyCashNumber = data.pettyCashNumber
      this.getDataFromApi(data.pettyCashNumber)
    },
    searchSuppliersDebounced (value) {
      clearTimeout(this.searchSupplierTimer)
      if (this.searchSupplier === '' || this.searchSupplier == null || this.searchSupplier.length >= 3) {
        this.searchSupplierTimer = setTimeout(() => {
          this.findSuppliersByName(value)
        }, 500)
      }
    },
    searchDebounced () {
      clearTimeout(this.searchTimer)
      if (this.search === '' || this.search == null || this.search.length >= 3) {
        this.searchTimer = setTimeout(() => {
          this.getDataFromApi(this.pettyCash.pettyCashNumber)
        }, 500)
      }
    },
    findSuppliersByName (value) {
      if (!value) {
        this.suppliers = []
      }

      this.loadingSuppliers = true
      return SupplierDataService.findByName(value).then(response => {
        this.suppliers = response.data
        this.loadingSuppliers = false
      }, reject => {
        this.loadingSuppliers = false
        this.$store.commit('showMessage', { message: 'Search Supplier failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
      })
    },
    getDataFromApi (pettyCashNumber) {
      this.loading = true
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (itemsPerPage > 0) {
          let searchParams = {}
          searchParams.page = page
          searchParams.itemsPerPage = itemsPerPage
          if (sortBy.length > 0) {
            searchParams.sortBy = sortBy[0]
          }
          if (sortDesc.length > 0) {
            searchParams.sortDesc = sortDesc[0]
          }
          searchParams.projectNumber = pettyCashNumber
          if (this.search) {
            searchParams.searchTerm = this.search
          }
          return PettyCashItemDataService.retrievePettyCashItemsPaginated(pettyCashNumber, searchParams).then(response => {
            this.pettyCashItems = response.data.content
            this.totalPettyCashItems = response.data.totalElements
            this.loading = false
          })
        }
      })
    },
    close () {
      this.dialog = false
      this.$v.$reset()
      setTimeout(() => {
        this.editedPettyCashItem = Object.assign({}, this.defaultPettyCashItem)
        this.fileToUpload = null
        this.editedIndex = -1
        this.searchSupplier = null
      }, 300)
    },
    save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let createFormData = new FormData()
        if (this.editedIndex > -1) {
          this.$store.commit('setOverlay', { text: 'Updating Petty Cash Item', overlayState: true })
          createFormData.append('pettyCashItemUpdateDTO', new Blob([JSON.stringify(this.editedPettyCashItem)], { type: 'application/json' }))
          createFormData.append('file', this.fileToUpload)
          return PettyCashItemDataService.updatePettyCashItem(createFormData).then(response => {
            this.$store.commit('setOverlay', { text: 'Updating Petty Cash Item', overlayState: false })
            this.$store.commit('showMessage', { message: response.data, color: 'success' })
            this.getDataFromApi(this.pettyCash.pettyCashNumber)
            this.close()
          }, reject => {
            this.$store.commit('setOverlay', { text: 'Updating Petty Cash Item', overlayState: false })
            this.$store.commit('showMessage', { message: 'Update Petty Cash Item failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
            this.close()
          })
        } else {
          this.$store.commit('setOverlay', { text: 'Creating Petty Cash Item', overlayState: true })
          createFormData.append('pettyCashItemCreateDTO', new Blob([JSON.stringify(this.editedPettyCashItem)], { type: 'application/json' }))
          createFormData.append('file', this.fileToUpload)
          return PettyCashItemDataService.createPettyCashItem(createFormData).then(response => {
            this.$store.commit('setOverlay', { text: 'Creating Petty Cash Item', overlayState: false })
            this.$store.commit('showMessage', { message: response.data, color: 'success' })
            this.getDataFromApi(this.pettyCash.pettyCashNumber)
            this.close()
          }, reject => {
            this.$store.commit('setOverlay', { text: 'Creating Petty Cash Item', overlayState: false })
            this.$store.commit('showMessage', { message: 'Create Petty Cash Item failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
            this.close()
          })
        }
      }
    },
    editPettyCashItem (item) {
      this.editedIndex = this.pettyCashItems.indexOf(item)
      this.dialog = true
      this.$store.commit('setOverlay', { text: 'Retrieving Petty Cash Item Details', overlayState: true })
      PettyCashItemDataService.getPettyCashItemById(item.id).then(response => {
        this.$store.commit('setOverlay', { text: 'Retrieving Petty Cash Item Details', overlayState: false })
        this.editedPettyCashItem = response.data
        this.suppliers = []
        if (this.editedPettyCashItem.supplierDto.id > 0) {
          this.suppliers.push(this.editedPettyCashItem.supplierDto)
        }
      }, reject => {
        this.$store.commit('setOverlay', { text: 'Retrieving Petty Cash Item Details', overlayState: false })
        this.$store.commit('showMessage', { message: 'Get Petty Cash Item details failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
      })
    },
    deletePettyCashItem (id) {
      if (confirm('Do you want to delete this Petty Cash Item?')) {
        this.$store.commit('setOverlay', { text: 'Deleting Petty Cash Item', overlayState: true })
        PettyCashItemDataService.deletePettyCashItem(id).then(response => {
          this.$store.commit('setOverlay', { text: 'Deleting Petty Cash Item', overlayState: false })
          this.$store.commit('showMessage', { message: response.data, color: 'success' })
          this.getDataFromApi(this.pettyCash.pettyCashNumber)
        }, reject => {
          this.$store.commit('setOverlay', { text: 'Deleting Petty Cash Item', overlayState: false })
          this.$store.commit('showMessage', { message: 'Delete Petty Cash Item failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
          this.close()
        })
      }
    },
    convertToVoucher (id) {
      this.$store.commit('setOverlay', { text: 'Converting to Voucher', overlayState: true })
      PettyCashItemDataService.convertToVoucher(id).then(response => {
        this.$store.commit('setOverlay', { text: 'Converting to Voucher', overlayState: false })
        this.$store.commit('showMessage', { message: response.data, color: 'success' })
        this.getDataFromApi(this.pettyCash.pettyCashNumber)
      }, reject => {
        this.$store.commit('setOverlay', { text: 'Converting to Voucher', overlayState: false })
        this.$store.commit('showMessage', { message: 'Convert to Voucher failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
      })
    },
    onSupplierSelect () {
      this.editedPettyCashItem.paidTo = this.editedPettyCashItem.supplierDto.name ? this.editedPettyCashItem.supplierDto.name : ''
      this.editedPettyCashItem.tin = this.editedPettyCashItem.supplierDto.tin ? this.editedPettyCashItem.supplierDto.tin : ''
      this.editedPettyCashItem.city = this.editedPettyCashItem.supplierDto.city ? this.editedPettyCashItem.supplierDto.city : ''
    },
    supplierFilter (item, queryText, itemText) {
      const supplierName = item.name.toLowerCase()
      const searchText = queryText.toLowerCase()

      return searchText.length > 2 && supplierName.indexOf(searchText) > -1
    },
    clear () {
      this.$v.$reset()
      this.editedPettyCashItem = this.defaultPettyCashItem
      this.fileToUpload = null
      this.searchSupplier = null
    }
  }
}
</script>

<style scoped>

</style>
