import { AXIOS } from './Axios-Common'

const URL = '/users'
const qs = require('querystring')
class AuthService {
  login (user) {
    const requestBody = {
      username: user.username,
      password: user.password
    }
    return AXIOS.post(`${URL}/signin`, qs.stringify(requestBody), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then(response => {
      return response.data
    })
  }
  logout () {
    return AXIOS.post(`${URL}/logout`)
  }
}
export default new AuthService()
