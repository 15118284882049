import PettyCashItemDataService from '../../services/PettyCashItemDataService'

export const pettyCashItem = {
  namespaced: true,
  state: {
    pettyCashItems: [],
    totalPettyCashItems: 0,
    editedPettyCashItem: {
      id: 0,
      pettyCashItemNumber: '',
      pettyCashNumber: '',
      description: '',
      date: '',
      amount: 0,
      paidTo: '',
      receiptNo: '',
      tin: '',
      city: '',
      vatableAmount: 0,
      vat: 0,
      vatable: false,
      hasVoucher: false,
      voucherCategory: {
        category: 0,
        description: ''
      },
      pettyCashDto: {
        id: 0,
        pettyCashNumber: ''
      },
      supplierDto: {
        id: 0,
        name: ''
      }
    },
    defaultPettyCashItem: {
      id: 0,
      pettyCashItemNumber: '',
      pettyCashNumber: '',
      description: '',
      date: '',
      amount: 0,
      paidTo: '',
      receiptNo: '',
      tin: '',
      city: '',
      vatableAmount: 0,
      vat: 0,
      vatable: false,
      hasVoucher: false,
      voucherCategory: {
        category: 0,
        description: ''
      },
      pettyCashDto: {
        id: 0,
        pettyCashNumber: ''
      },
      supplierDto: {
        id: 0,
        name: ''
      }
    },
    renderKey: 0,
    editPettyCashItemDialog: false
  },
  actions: {
    getDataFromApi ({ state, commit }, payload) {
      return PettyCashItemDataService.retrievePettyCashItemsPaginated(payload.pettyCashNumber, payload.options).then(response => {
        commit('setPettyCashItems', response.data)
      })
    }
  },
  mutations: {
    setPettyCashItems (state, data) {
      state.pettyCashItems = data.content
      state.totalPettyCashItems = data.totalElements
    },
    setTotalPettyCashItems (state, data) {
      state.totalPettyCashItems = data
    },
    setRenderKey (state) {
      state.renderKey += 1
    },
    setEditedPettyCashItemSuccess (state, data) {
      state.editedPettyCashItem = data
    },
    setEditedPettyCashItemFailure (state) {
      state.editedPettyCashItem = state.defaultPettyCashItem
    },
    setEditedPettyCashItemPettyCashId (state, data) {
      state.editedPettyCashItem.id = data
    },
    showDialog (state) {
      state.editPettyCashItemDialog = true
    },
    hideDialog (state) {
      state.editPettyCashItemDialog = false
    }
  },
  getters: {}
}
