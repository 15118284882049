var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":"400","headers":_vm.headers,"options":_vm.options,"loading":_vm.loading,"items":_vm.contractTermsWithPayments,"server-items-length":_vm.totalContractTermsWithPayments,"single-expand":"","show-expand":"","expanded":_vm.expanded},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.amount, 'currency'))+" ")]}},{key:"item.totalCollected",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.totalCollected, 'currency'))+" ")]}},{key:"item.balanceToCollect",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.balanceToCollect, 'currency'))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":_vm.collectionHeaders,"items":item.collections},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.amount, 'currency'))+" ")]}},{key:"item.paymentDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(new Date(item.paymentDate), 'short'))+" ")]}}],null,true)})],1)]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',[_c('span',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v("Total:")])]),_c('td',{staticClass:"text-right"},[_c('span',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(_vm._s(_vm.$n(_vm.totalContractAmount, 'currency')))])]),_c('td',{staticClass:"text-right"},[_c('span',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(_vm._s(_vm.$n(_vm.totalCollected, 'currency')))])]),_c('td',{staticClass:"text-right"},[_c('span',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(_vm._s(_vm.$n(_vm.totalCollectionBalance, 'currency')))])])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }