<template>
  <projects-list></projects-list>
</template>

<script>
// @ is an alias to /src

import ProjectsList from '../components/project/ListProjectsComponent'
export default {
  name: 'ProjectList',
  components: { ProjectsList }
}
</script>
