import { AXIOS } from './Axios-Common'

const URL = '/suppliers'

class SupplierDataService {
  getSupplierById (id) {
    return AXIOS.get(`${URL}/supplier/${id}`)
  }
  retrieveAllSuppliers () {
    return AXIOS.get(`${URL}`)
  }
  retrieveSuppliersPaginated (params) {
    return AXIOS.get(`${URL}/searchsuppliers`, {
      params: {
        offset: params.page - 1,
        limit: params.itemsPerPage,
        searchTerm: params.searchTerm ? params.searchTerm : '',
        sortBy: params.sortBy,
        sortDesc: params.sortDesc
      }
    })
  }
  createSupplier (supplier) {
    return AXIOS.post(`${URL}/supplier`, supplier)
  }
  updateSupplier (supplier) {
    return AXIOS.put(`${URL}/supplier`, supplier)
  }
  deleteSupplier (id) {
    return AXIOS.delete(`${URL}/supplier/${id}`)
  }
  findByName (name) {
    return AXIOS.get(`${URL}/findbyname`, {
      params: {
        name: name
      }
    })
  }
}
export default new SupplierDataService()
