<template>
  <v-data-table
    fixed-header
    height="300"
    :headers="headers"
    :items="unpaidSuppliers"
    :options.sync="options"
    :server-items-length="totalUnpaidSuppliers"
    :loading="loading"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" @keyup="searchDebounced" single-line hide-details />
      </v-toolbar>
    </template>
    <template v-slot:item.amount="{ item }">
      {{$n(item.amount, 'currency')}}
    </template>
    <template v-slot:item.voucherDate="{ item }">
      {{ $d(new Date(item.voucherDate), 'short') }}
    </template>
    <template v-slot:body.append>
      <tr>
        <td colspan="3">
          <span class="font-weight-bold subtitle-1">Total:</span>
        </td>
        <td>
          <p class="text-right font-weight-bold subtitle-1">{{ $n(unpaidTotal, 'currency') }}</p>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import VoucherDataService from '../../services/VoucherDataService'

export default {
  name: 'ListUnpaidSuppliersComponent',
  props: {
    projectNumber: String
  },
  data () {
    return {
      search: '',
      searchTimer: null,
      unpaidSuppliers: [],
      totalUnpaidSuppliers: 0,
      unpaidTotal: 0,
      loading: true,
      options: {},
      errors: {},
      headers: [
        { text: 'Date', align: 'left', sortable: true, value: 'voucherDate' },
        { text: 'Paid To', align: 'left', sortable: true, value: 'paidTo' },
        { text: 'Description', align: 'left', sortable: true, value: 'description' },
        { text: 'Amount', align: 'right', sortable: true, value: 'amount' }
      ]
    }
  },
  watch: {
    options: {
      handler () {
        this.getDataFromApi(this.projectNumber)
      },
      deep: true
    }
  },
  created () {
    this.getDataFromApi(this.projectNumber)
    this.getUnpaidTotal(this.projectNumber)
  },
  methods: {
    searchDebounced () {
      clearTimeout(this.searchTimer)
      if (this.search === '' || this.search == null || this.search.length >= 3) {
        this.searchTimer = setTimeout(() => {
          this.getDataFromApi(this.projectNumber)
        }, 500)
      }
    },
    getDataFromApi (projectNumber) {
      this.loading = true
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        if (itemsPerPage > 0 && projectNumber) {
          let searchParams = {}
          searchParams.page = page
          searchParams.itemsPerPage = itemsPerPage
          if (sortBy.length > 0) {
            searchParams.sortBy = sortBy[0]
          }
          if (sortDesc.length > 0) {
            searchParams.sortDesc = sortDesc[0]
          }
          searchParams.projectNumber = projectNumber
          if (this.search) {
            searchParams.searchTerm = this.search
          }
          return VoucherDataService.searchUnpaidSuppliers(searchParams).then(response => {
            this.unpaidSuppliers = response.data.content
            this.totalUnpaidSuppliers = response.data.totalElements
            this.loading = false
          })
        }
      })
    },
    getUnpaidTotal (projectNumber) {
      return VoucherDataService.getUnpaidVouchersTotalAmount(projectNumber).then(response => {
        this.unpaidTotal = response.data
      })
    }
  }
}
</script>

<style scoped>

</style>
