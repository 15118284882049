import { AXIOS } from './Axios-Common'

const URL = '/projects'
class ProjectDataService {
  retrieveAllProjects (params) {
    return AXIOS.get(`${URL}`, {
      params: {
        offset: params.page - 1,
        limit: params.itemsPerPage,
        searchTerm: params.searchTerm,
        sortBy: params.sortBy,
        sortDesc: params.sortDesc
      }
    })
  }
  getProjectById (id) {
    return AXIOS.get(`${URL}/project/${id}`)
  }
  deleteProject (id) {
    return AXIOS.delete(`${URL}/project/${id}`)
  }
  createProject (project) {
    return AXIOS.post(`${URL}/project`, project)
  }
  updateProject (project) {
    return AXIOS.put(`${URL}/project`, project)
  }
  retrieveProject (projectNumber) {
    return AXIOS.get(`${URL}/project/${projectNumber}`)
  }
  retrieveYears () {
    return AXIOS.get(`${URL}/years`)
  }
  getPictures (projectNumber) {
    return AXIOS.get(`${URL}/${projectNumber}/pictures`)
  }
  addPicture (projectNumber, description, file) {
    return AXIOS.post(`${URL}/${projectNumber}/picture`, file, {
      params: {
        description: description
      }
    })
  }
  deletePicture (projectNumber, id) {
    return AXIOS.delete(`${URL}/${projectNumber}/${id}`)
  }
  createExpenseReportPerProject (dto) {
    return AXIOS.post(`${URL}/expensereport`, dto, {
      responseType: 'blob'
    })
  }
  getOngoingProjects () {
    return AXIOS.get(`${URL}/ongoingprojects`)
  }
  getAllProjects () {
    return AXIOS.get(`${URL}/allprojects`)
  }
  getContractTerms (projectNumber) {
    return AXIOS.get(`${URL}/contractterms`, {
      params: {
        'projectNumber': projectNumber
      }
    })
  }
  searchContractTerms (params) {
    return AXIOS.get(`${URL}/searchcontractterms`, {
      params: {
        offset: params.page - 1,
        limit: params.itemsPerPage,
        projectNumber: params.projectNumber,
        searchTerm: params.searchTerm,
        sortBy: params.sortBy,
        sortDesc: params.sortDesc
      }
    })
  }
  createContractTerm (term) {
    return AXIOS.post(`${URL}/contractterm`, term)
  }
  updateContractTerm (term) {
    return AXIOS.put(`${URL}/contractterm`, term)
  }
  getContractTermById (id) {
    return AXIOS.get(`${URL}/contractterm/${id}`)
  }
  deleteContractTerm (id) {
    return AXIOS.delete(`${URL}/contractterm/${id}`)
  }
  getSummaries (params) {
    return AXIOS.get(`${URL}/summaries`, {
      params: {
        offset: params.page - 1,
        limit: params.itemsPerPage,
        sortBy: params.sortBy,
        sortDesc: params.sortDesc
      }
    })
  }
  getContractsWithPaymentDetails (params) {
    return AXIOS.get(`${URL}/collections`, {
      params: {
        offset: params.page - 1,
        limit: params.itemsPerPage,
        projectNumber: params.projectNumber,
        searchTerm: params.searchTerm,
        sortBy: params.sortBy,
        sortDesc: params.sortDesc
      }
    })
  }
  getCollectionsReport () {
    return AXIOS.get(`${URL}/collectionsreport`, {
      responseType: 'blob'
    })
  }
  getTotalContractAmount (projectNumber) {
    return AXIOS.get(`${URL}/totalcontractamount`, {
      params: {
        projectNumber: projectNumber
      }
    })
  }
}

export default new ProjectDataService()
