<template>
  <span>
    <v-navigation-drawer
      v-model="drawer"
      app
      :clipped="$vuetify.breakpoint.lgAndUp">
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title text-wrap">{{ $store.getters['auth/fullName'] }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <template v-for="item in items">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>
          <v-list-group v-else-if="item.children" :key="item.text" v-model="item.model" :prepend-icon="item.model ? item.icon : item['icon-alt']" append-icon="">
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{item.text}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item v-for="(child, i) in item.children" :key="i" link>
              <v-list-item-action v-if="child.icon">
                <v-icon>{{child.icon}}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{child.text}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else :key="item.text"
            :to="'/' + item.link">
            <v-list-item-action>
              <v-icon>{{item.icon}}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{item.text}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item v-if="hasRole('ROLE_ADMIN')" :to="'/users'">
            <v-list-item-action>
              <v-icon>mdi-account</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>User Management</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title class="ml-0 pa-4">
        <span class="headline mr-1">{{ appTitle }}</span>
        <span class="subtitle-1 ml-1">{{ appVersion }}</span>
      </v-toolbar-title>
      <v-spacer class="hidden-sm-and-down"></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon class="ma-4" v-on="on" @click="companyInfoDialog = !companyInfoDialog">mdi-information-outline</v-icon>
        </template>
        <span>Company Info</span>
      </v-tooltip>
      <v-dialog persistent v-model="companyInfoDialog" max-width="700px" @keydown.esc="companyInfoDialog = false">
        <v-card>
          <v-row align="center" justify="center">
            <v-img max-height="50%" max-width="50%" class="justify-center" src="../assets/images/logo.jpg"></v-img>
          </v-row>
          <v-card-title>
            <div class="title">{{ $store.getters.companyInfo.companyName }}</div>
          </v-card-title>
          <v-card-text>
            <p class="subtitle-3">{{ address }}</p>
            <p class="subtitle-3">{{ $store.getters.companyInfo.phoneNumber }}</p>
            <p class="subtitle-2">SSS Number: {{ $store.getters.companyInfo.sssNumber }}</p>
            <p class="subtitle-2">TIN Number: {{ $store.getters.companyInfo.tinNumber }}</p>
            <p class="subtitle-2">Philhealth Number: {{ $store.getters.companyInfo.philhealthNumber }}</p>
            <p class="subtitle-2">PAG-IBIG Number: {{ $store.getters.companyInfo.hdmfNumber }}</p>
            <p class="subtitle-2">PCAB License Number: {{ $store.getters.companyInfo.pcabLicenseNumber }}</p>
            <p class="subtitle-2">SEC Registration Number: {{ $store.getters.companyInfo.secRegistrationNumber }}</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" dark @click="companyInfoDialog = false">Close</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" class="ma-4" @click="logout">mdi-logout</v-icon>
        </template>
        <span>Logout</span>
      </v-tooltip>
    </v-app-bar>
  </span>

</template>

<script>
import CompanyDataService from '../services/CompanyDataService'
import AuthService from '../services/AuthService'

export default {
  name: 'AppNavigation',
  data () {
    return {
      companyInfoDialog: false,
      appTitle: 'Royaltek Software',
      dialog: false,
      drawer: null,
      items: [
        {
          icon: 'mdi-view-dashboard',
          text: 'Dashboard',
          link: 'home'
        },
        {
          icon: 'mdi-account-hard-hat',
          text: 'Project List',
          link: 'projects'
        },
        {
          icon: 'mdi-cash-remove',
          model: true,
          text: 'All Expenses',
          link: 'vouchersearch'
        },
        {
          icon: 'mdi-cash-plus',
          text: 'All Collections',
          model: true,
          link: 'summaries'
        },
        {
          icon: 'mdi-contacts',
          'icon-alt': 'mdi-contacts',
          text: 'Client Directory',
          model: true,
          link: 'clients'
        },
        {
          icon: 'mdi-contacts-outline',
          text: 'Supplier Directory',
          model: true,
          link: 'suppliers'
        },
        {
          icon: 'mdi-map-marker',
          text: 'Locations',
          model: true,
          link: 'locations'
        },
        {
          icon: 'mdi-clipboard-list',
          text: 'Inventory List',
          model: true,
          link: 'inventorylist'
        },
        {
          icon: 'mdi-card-account-mail',
          text: 'Employee Directory',
          model: true,
          link: 'employees'
        }
      ]
    }
  },
  created () {
    // hardcoded for now
    this.getCompanyById(1)
  },
  watch: {
    companyInfoDialog (val) {
      val || this.closeCompanyInfo()
    },
    dialog (val) {
      val || this.close()
    }
  },
  computed: {
    appVersion () {
      return this.$store.getters.appVersion
    },
    address () {
      let addressString = []
      addressString.push(this.$store.getters.companyInfo.buildingNumber)
      addressString.push(this.$store.getters.companyInfo.street)
      addressString.push(this.$store.getters.companyInfo.town)
      addressString.push(this.$store.getters.companyInfo.city)
      addressString.push(this.$store.getters.companyInfo.country.countryName)
      return addressString.join(', ')
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logout')
      AuthService.logout().then(response => {
        this.$router.push('/login')
      }, reject => {
        this.$router.push('/login')
      })
    },
    hasRole (role) {
      return this.$store.getters['auth/authorities'].includes(role)
    },
    close () {
      this.dialog = false
    },
    closeCompanyInfo () {
      this.companyInfoDialog = false
    },
    getCompanyById (id) {
      return CompanyDataService.getCompanyById(id).then(response => {
        this.$store.commit('setCompanyInfo', response.data)
      })
    }
  }
}
</script>

<style scoped>

</style>
