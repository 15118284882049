import { AXIOS } from './Axios-Common'

const URL = '/pettycash'
class PettyCashDataService {
  retrievePettyCashPaginated (projectNumber, params) {
    return AXIOS.get(`${URL}/searchpettycash`, {
      params: {
        offset: params.page - 1,
        limit: params.itemsPerPage,
        searchTerm: params.searchTerm,
        projectNumber: projectNumber,
        sortBy: params.sortBy,
        sortDesc: params.sortDesc
      }
    })
  }
  getPettyCashById (id) {
    return AXIOS.get(`${URL}/pettycash/${id}`)
  }
  createPettyCash (pettyCash) {
    return AXIOS.post(`${URL}/pettycash`, pettyCash)
  }
  updatePettyCash (pettyCash) {
    return AXIOS.put(`${URL}/pettycash`, pettyCash)
  }
  deletePettyCash (id) {
    return AXIOS.delete(`${URL}/pettycash/${id}`)
  }
  generatePettyCashReportForSelectedItems (selectedPettyCash) {
    return AXIOS.post(`${URL}/createpettycashreport`, selectedPettyCash, {
      responseType: 'blob'
    })
  }
  generatePettyCashReportForProject (projectNumber) {
    return AXIOS.post(`${URL}/createpettycashreportforproject/${projectNumber}`, '', {
      responseType: 'blob'
    })
  }
}
export default new PettyCashDataService()
