<template>
  <locations-list></locations-list>
</template>

<script>

import LocationsList from '../components/location/ListLocationComponent'

export default {
  name: 'LocationList',
  components: { LocationsList }
}
</script>

<style scoped>

</style>
