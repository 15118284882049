import { AXIOS } from './Axios-Common'

const URL = '/pettycashitems'
class PettyCashItemDataService {
  retrievePettyCashItemsPaginated (pettyCashNumber, params) {
    return AXIOS.get(`${URL}/searchpettycashitems`, {
      params: {
        offset: params.page - 1,
        limit: params.itemsPerPage,
        searchTerm: params.searchTerm,
        projectNumber: pettyCashNumber,
        sortBy: params.sortBy,
        sortDesc: params.sortDesc
      }
    })
  }
  getPettyCashItemById (id) {
    return AXIOS.get(`${URL}/pettycashitem/${id}`)
  }
  createPettyCashItem (pettyCashItem) {
    return AXIOS.post(`${URL}/pettycashitem`, pettyCashItem)
  }
  updatePettyCashItem (pettyCashItem) {
    return AXIOS.put(`${URL}/pettycashitem`, pettyCashItem)
  }
  deletePettyCashItem (id) {
    return AXIOS.delete(`${URL}/pettycashitem/${id}`)
  }
  convertToVoucher (id) {
    return AXIOS.post(`${URL}/converttovoucher/${id}`)
  }
}
export default new PettyCashItemDataService()
