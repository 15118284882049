import { AXIOS } from './Axios-Common'

const URL = '/bom'
class BOMDataService {
  getBomById (id) {
    return AXIOS.get(`${URL}/bom/${id}`)
  }
  retrieveBOMPaginated (projectNumber, params) {
    return AXIOS.get(`${URL}/searchbom`, {
      params: {
        offset: params.page - 1,
        limit: params.itemsPerPage,
        searchTerm: params.searchTerm,
        projectNumber: projectNumber,
        sortBy: params.sortBy,
        sortDesc: params.sortDesc
      }
    })
  }
  createBOM (bom) {
    return AXIOS.post(`${URL}/bom`, bom)
  }
  updateBOM (bom) {
    return AXIOS.put(`${URL}/bom`, bom)
  }
  deleteBOM (id) {
    return AXIOS.delete(`${URL}/bom/${id}`)
  }
  retrieveBOMCategories () {
    return AXIOS.get(`${URL}/bomcategories`)
  }
  retrieveUnits () {
    return AXIOS.get(`${URL}/units`)
  }
  uploadFile (uploadInfo) {
    return AXIOS.post(`${URL}/uploadbomfile`, uploadInfo)
  }
  getBudgetByCategory (bomNumber) {
    return AXIOS.get(`${URL}/budgetbycategory`, {
      params: {
        bomNumber: bomNumber
      }
    })
  }
  generateBOMFile (bomNumber) {
    return AXIOS.get(`${URL}/createbomfile`, {
      params: {
        bomNumber: bomNumber
      },
      responseType: 'blob'
    })
  }
}
export default new BOMDataService()
