<template>
  <div>
    <v-expansion-panels accordion multiple>
      <v-expansion-panel>
        <v-expansion-panel-header class="white--text" color="primary lighten-1">
          <span class="subtitle-1">Contract Terms - {{ $n(totalContractTermAmount, 'currency') }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <save-and-display-contract-terms-component ref="contracts" :projectNumber="parentProject.projectNumber"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header class="white--text" color="primary lighten-1">
          <v-row no-gutters>
            <v-col cols="11">
              <span class="subtitle-1">Collections by Contract - {{ $n(totalCollections, 'currency') }}</span>
            </v-col>
            <v-divider class="mx-4" inset vertical/>
            <v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="primary" dark class="mx-2" @click="openCollectionDialog">
                    <v-icon>mdi-plus-box</v-icon>
                  </v-btn>
                </template>
                <span>Add New Collection</span>
              </v-tooltip>
              <v-btn v-on="on" color="primary" dark class="mx-2" @click="generateCollectionsByContractTermReport">
                Generate Report
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <display-project-collections-component
            ref="collections" :projectNumber="parentProject.projectNumber">
          </display-project-collections-component>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header class="white--text" color="primary lighten-1">
          <v-row no-gutters>
            <v-col cols="11">
              <span class="subtitle-1">Expenses - {{ $n(totalExpenses, 'currency') }}</span>
            </v-col>
            <v-divider class="mx-4" inset vertical/>
            <v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="primary" dark class="mx-2" @click="openExpenseDialog">
                    <v-icon>mdi-plus-box</v-icon>
                  </v-btn>
                </template>
                <span>Add New Expense</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense>
            <v-col cols="12">
              <v-card outlined>
                <v-card-title>
                  <p class="title">Expenses Breakdown</p>
                </v-card-title>
                <v-card-text>
                  <display-project-expenses-component
                    ref="expenses" :projectNumber="parentProject.projectNumber">
                  </display-project-expenses-component>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card outlined>
                <v-card-title>
                  <p class="title">Unpaid Suppliers</p>
                </v-card-title>
                <v-card-text>
                  <list-unpaid-suppliers-component ref="unpaid" :projectNumber="parentProject.projectNumber">
                  </list-unpaid-suppliers-component>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <save-voucher-dialog></save-voucher-dialog>
    <save-collection-dialog></save-collection-dialog>
  </div>
</template>

<script>
import DisplayProjectExpensesComponent from './DisplayProjectExpensesComponent'
import DisplayProjectCollectionsComponent from './DisplayProjectCollectionsComponent'
import SaveAndDisplayContractTermsComponent from './SaveAndDisplayContractTermsComponent'
import ListUnpaidSuppliersComponent from '../voucher/ListUnpaidSuppliersComponent'
import SaveVoucherDialog from '../voucher/SaveVoucherDialog'
import SaveCollectionDialog from '../payment/SaveCollectionDialog'
import PaymentDataService from '@/services/PaymentDataService'

export default {
  name: 'DisplayProjectDetailsComponent',
  components: {
    SaveCollectionDialog,
    SaveVoucherDialog,
    ListUnpaidSuppliersComponent,
    SaveAndDisplayContractTermsComponent,
    DisplayProjectCollectionsComponent,
    DisplayProjectExpensesComponent
  },
  props: {
    parentProject: Object
  },
  data: function () {
    return {
    }
  },
  computed: {
    project () {
      return this.$store.getters['project/project']
    },
    totalCollections () {
      return this.$store.getters['collections/totalPaymentAmount']
    },
    totalExpenses () {
      return this.$store.getters['expenses/getTotalSpendAmount']
    },
    totalContractTermAmount () {
      return this.$store.getters['project/contractTerm/totalContractTermAmount']
    }
  },
  methods: {
    openExpenseDialog () {
      // set voucher to default
      this.$store.commit('expenses/setEditedVoucherSuccess', Object.assign({}, this.$store.getters['expenses/defaultVoucher']))
      this.$store.commit('expenses/setExpenseProjectNumber', this.project.projectNumber)
      // open dialog box
      this.$store.commit('expenses/showDialog')
    },
    openCollectionDialog () {
      this.$store.commit('collections/setEditedPaymentSuccess', Object.assign({}, this.$store.getters['collections/defaultPayment']))
      this.$store.commit('collections/setCollectionProjectNumber', this.project.projectNumber)
      this.$store.commit('collections/showDialog')
    },
    async generateCollectionsByContractTermReport () {
      this.$store.commit('setOverlay', { text: 'Generating Collections By Contract Term report...', overlayState: true })
      let reportResponse = await PaymentDataService.generateCollectionsByContractReport(this.project.projectNumber)
      this.$store.commit('setOverlay', { text: 'Generating Collections By Contract Term report...', overlayState: false })
      const url = window.URL.createObjectURL(new Blob([reportResponse.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'collectionsbycontractterm.xlsx')
      document.body.appendChild(link)
      link.click()
    }
  }
}
</script>

<style scoped>
</style>
