import { AXIOS } from './Axios-Common'

const URL = '/clients'

class ClientDataService {
  getClientById (id) {
    return AXIOS.get(`${URL}/client/${id}`)
  }
  retrieveAllClients () {
    return AXIOS.get(`${URL}`)
  }
  retrieveSuppliersPaginated (params) {
    return AXIOS.get(`${URL}/searchclients`, {
      params: {
        offset: params.page - 1,
        limit: params.itemsPerPage,
        searchTerm: params.searchTerm ? params.searchTerm : '',
        sortBy: params.sortBy,
        sortDesc: params.sortDesc
      }
    })
  }
  createClient (client) {
    return AXIOS.post(`${URL}/client`, client)
  }
  updateClient (client) {
    return AXIOS.put(`${URL}/client`, client)
  }
  deleteClient (id) {
    return AXIOS.delete(`${URL}/client/${id}`)
  }
  findByName (name) {
    return AXIOS.get(`${URL}/findbyname`, {
      params: {
        name: name
      }
    })
  }
}
export default new ClientDataService()
