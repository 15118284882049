<template>
  <div>
    <v-card>
      <v-card-title>
        <v-sheet color="primary lighten-1" dark width="100%" max-width="100%">
          <span class="headline ma-2">Project List</span>
        </v-sheet>
      </v-card-title>
      <v-card-text>
        <v-data-table
          fixed-header
          height="400"
          :headers="headers"
          :key="renderKey"
          :items="$store.getters['project/projects']"
          :options.sync="options"
          :server-items-length="$store.getters['project/totalProjects']"
          :loading="loading"
          :footer-props="{ 'items-per-page-options':[5,10,30,100,300] }"
          class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" @keyup="searchProjectsDebounced" single-line hide-details />
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="primary" dark class="ma-2" @click="openDialog">
                    <v-icon>mdi-plus-box</v-icon>
                  </v-btn>
                </template>
                <span>Add New Project</span>
              </v-tooltip>
            </v-toolbar>
          </template>
          <template v-slot:item.projectNumber="{item}">
            <router-link :to="'/project/' + item.id" class="body-1 font-weight-bold">{{ item.projectNumber }}</router-link>
          </template>
          <template v-slot:item.action="{item}">
            <v-icon small class="mr-2" @click="editProject(item)">mdi-pencil</v-icon>
            <v-icon small @click="deleteProject(item.id)">mdi-delete</v-icon>
          </template>
          <template v-slot:item.startDate="{ item }">
            <span v-if="item.startDate != null">{{ $d(new Date(item.startDate), 'short') }}</span>
          </template>
          <template v-slot:item.endDate="{ item }">
            <span v-if="item.endDate != null">{{ $d(new Date(item.endDate), 'short') }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <save-project-dialog></save-project-dialog>
    <overlay></overlay>
    <snackbar></snackbar>
  </div>
</template>

<script>
import SaveProjectDialog from './SaveProjectDialog'
import Overlay from '../global/Overlay'
import Snackbar from '../global/Snackbar'

export default {
  name: 'ProjectsList',
  components: { Snackbar, Overlay, SaveProjectDialog },
  data () {
    return {
      // datatable variables
      search: '',
      loading: true,
      options: {},
      headers: [
        { text: 'Project Number', align: 'center', sortable: true, value: 'projectNumber' },
        { text: 'Project Name', align: 'left', sortable: true, value: 'name' },
        { text: 'Client', align: 'left', sortable: false, value: 'clientDisplayDto.clientName' },
        { text: 'Start Date', align: 'left', sortable: true, value: 'startDate' },
        { text: 'End Date', align: 'left', sortable: true, value: 'endDate' },
        { text: 'Project Type', align: 'left', sortable: true, value: 'projectType' },
        { text: 'Actions', sortable: false, value: 'action' }
      ],
      searchTimer: null
    }
  },
  computed: {
    renderKey () {
      return this.$store.getters['project/renderKey']
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    options: {
      handler () {
        this.getAllProjects()
      },
      deep: true
    }
  },
  methods: {
    searchProjectsDebounced () {
      clearTimeout(this.searchTimer)
      if (this.search === '' || this.search == null || this.search.length >= 3) {
        this.searchTimer = setTimeout(() => {
          this.getAllProjects()
        }, 500)
      }
    },
    async getAllProjects () {
      this.loading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      if (itemsPerPage > 0) {
        let searchParams = {}
        searchParams.page = page
        searchParams.itemsPerPage = itemsPerPage
        if (sortBy.length > 0) {
          searchParams.sortBy = sortBy[0]
        }
        if (sortDesc.length > 0) {
          searchParams.sortDesc = sortDesc[0]
        }
        if (this.search) {
          searchParams.searchTerm = this.search
        }
        let payload = {}
        payload.options = searchParams
        try {
          let response = await this.$store.dispatch('project/getProjects', payload)
          this.$store.commit('project/setProjectListSuccess', response.data)
        } catch (err) {
          this.$store.commit('project/setProjectListFailure')
        } finally {
          this.loading = false
        }
      }
    },
    async deleteProject (id) {
      if (confirm('Do you want to delete this Project?')) {
        try {
          this.$store.commit('setOverlay', { text: 'Deleting Project...', overlayState: true })
          let response = await this.$store.dispatch('project/deleteProject', id)
          this.$store.commit('setOverlay', { text: 'Deleting Project...', overlayState: false })
          this.$store.commit('showMessage', { message: response.data, color: 'success' })
        } catch (err) {
          this.$store.commit('setOverlay', { text: 'Deleting Project...', overlayState: false })
          this.$store.commit('showMessage', { message: 'Delete Project failed with Status ' + err.response.status + ': ' + err.response.data, color: 'error' })
        } finally {
          this.$store.commit('setRenderKey')
        }
      }
    },
    async editProject (item) {
      try {
        this.$store.commit('setOverlay', { text: 'Retrieving Project Details', overlayState: true })
        let response = await this.$store.dispatch('project/getProject', item.id)
        this.$store.commit('project/setProjectSuccess', response.data)
        this.$store.commit('project/setEditedProject', this.$store.getters['project/project'])
        this.$store.commit('project/showDialog')
      } catch (err) {
        this.$store.commit('project/setProjectFailure')
      } finally {
        this.$store.commit('setOverlay', { text: 'Retrieving Project Details', overlayState: false })
      }
    },
    openDialog () {
      this.$store.commit('project/setEditedProject', Object.assign({}, this.$store.getters['project/defaultProject']))
      this.$store.commit('project/showDialog')
    }
  }
}
</script>

<style>

</style>
