<template>
  <v-app>
    <v-main transition="slide-x-transition">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'

export default {
  name: 'App',
  components: {
  },
  created () {
    let self = this
    axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (err.response.status === 401) {
          self.$store.dispatch('auth/logout').then(() => {
            self.$router.push('/login')
          })
        }
      })
    })
  }
}
</script>
<style>
  .v-data-table th {
    font-size: 18px !important;
  }
  .v-data-table td {
    font-size: 16px !important;
  }
</style>
