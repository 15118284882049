<template>
  <div>
    <v-card>
      <v-card-title>
        <v-sheet color="primary lighten-1" dark width="100%" max-width="100%">
          <span class="headline ma-2">Locations</span>
        </v-sheet>
      </v-card-title>
      <v-card-text>
        <v-data-table
          fixed-header
          height="400"
          :headers="headers"
          :key="renderKey"
          :items="$store.getters['location/locations']"
          :server-items-length="$store.getters['location/totalLocations']"
          :options.sync="options"
          :loading="loading"
          :footer-props="{ 'items-per-page-options':[5,10] }"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-select
                v-model="locationIdsForReport"
                multiple
                item-text="locationName"
                item-value="id"
                :items="$store.getters['location/locations']"
                label="Select Locations for Report"></v-select>
              <v-btn color="primary" dark class="ma-2" @click="generateItemsForLocationReport(locationIdsForReport)">Create Items per Location Report</v-btn>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="primary" dark class="ma-2" @click="openDialog">
                    <v-icon>mdi-plus-box</v-icon>
                  </v-btn>
                </template>
                <span>Add New Location</span>
              </v-tooltip>
            </v-toolbar>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon small class="mr-2" @click="editLocation(item)">mdi-pencil</v-icon>
            <v-icon small @click="deleteLocation(item.id)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <save-location-dialog></save-location-dialog>
    <overlay></overlay>
    <snackbar></snackbar>
  </div>
</template>

<script>
import Overlay from '../global/Overlay'
import Snackbar from '../global/Snackbar'
import SaveLocationDialog from '@/components/location/SaveLocationDialog'
import LocationDataService from '@/services/LocationDataService'

export default {
  name: 'LocationsList',
  components: { SaveLocationDialog, Overlay, Snackbar },
  data () {
    return {
      loading: true,
      options: {},
      headers: [
        { text: 'Name', align: 'center', sortable: true, value: 'locationName' },
        { text: 'Address', align: 'left', sortable: true, value: 'address' },
        { text: 'Actions', sortable: false, value: 'action' }
      ],
      locationIdsForReport: []
    }
  },
  computed: {
    renderKey () {
      return this.$store.getters['location/renderKey']
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    options: {
      handler () {
        this.getAllLocations()
      },
      deep: true
    }
  },
  methods: {
    async getAllLocations () {
      this.loading = true
      const { page, itemsPerPage } = this.options
      if (itemsPerPage > 0) {
        let searchParams = {}
        searchParams.page = page
        searchParams.itemsPerPage = itemsPerPage
        let payload = {}
        payload.options = searchParams
        try {
          let response = await this.$store.dispatch('location/getLocations', payload)
          this.$store.commit('location/setLocationListSuccess', response.data)
        } catch (err) {
          console.error(err)
          this.$store.commit('location/setLocationListFailure')
        } finally {
          this.loading = false
        }
      }
    },
    async deleteLocation (id) {
      if (confirm('Do you want to delete this Location?')) {
        try {
          this.$store.commit('setOverlay', { text: 'Deleting Location...', overlayState: true })
          let response = await this.$store.dispatch('location/deleteLocation', id)
          this.$store.commit('setOverlay', { text: 'Deleting Location...', overlayState: false })
          this.$store.commit('showMessage', { message: response.data, color: 'success' })
          await this.refreshLocations()
        } catch (err) {
          console.error(err)
          this.$store.commit('setOverlay', { text: 'Deleting Location...', overlayState: false })
          this.$store.commit('showMessage', { message: 'Delete Project: ' + err.response.status + ': ' + err.response.data, color: 'error' })
        } finally {
          this.$store.commit('location/setRenderKey')
        }
      }
    },
    async editLocation (item) {
      try {
        this.$store.commit('setOverlay', { text: 'Retrieving Location Details', overlayState: true })
        let response = await this.$store.dispatch('location/getLocation', item.id)
        this.$store.commit('location/setLocationSuccess', response.data)
        this.$store.commit('location/setEditedLocation', response.data)
        this.$store.commit('location/showDialog')
      } catch (err) {
        console.error(err)
        this.$store.commit('location/setLocationFailure')
      } finally {
        this.$store.commit('setOverlay', { text: 'Retrieving Location Details', overlayState: false })
      }
    },
    openDialog () {
      this.$store.commit('location/setEditedLocation', Object.assign({}, this.$store.getters['location/defaultLocation']))
      this.$store.commit('location/showDialog')
    },
    async refreshLocations () {
      let response = await LocationDataService.allLocations()
      this.$store.commit('setLocations', response.data)
    },
    async generateItemsForLocationReport (ids) {
      this.$store.commit('setOverlay', { text: 'Generating Items per Location report...', overlayState: true })
      let reportResponse = await LocationDataService.generateItemsByLocationReport(ids)
      this.$store.commit('setOverlay', { text: 'Generating Items per Location report...', overlayState: false })
      const url = window.URL.createObjectURL(new Blob([reportResponse.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'itemsperlocationreport.xlsx')
      document.body.appendChild(link)
      link.click()
    }
  }
}
</script>

<style scoped>

</style>
