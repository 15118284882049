import { AXIOS } from './Axios-Common'

const URL = '/bomitems'
class BOMItemDataService {
  getBomItemById (id) {
    return AXIOS.get(`${URL}/bomitem/${id}`)
  }
  retrieveBOMItemsPaginated (bomNumber, params) {
    return AXIOS.get(`${URL}/searchbomitems`, {
      params: {
        offset: params.page - 1,
        limit: params.itemsPerPage,
        searchTerm: params.searchTerm,
        projectNumber: bomNumber,
        sortBy: params.sortBy,
        sortDesc: params.sortDesc
      }
    })
  }
  createBOMItem (item) {
    return AXIOS.post(`${URL}/bomitem`, item)
  }
  updateBOMItem (item) {
    return AXIOS.put(`${URL}/bomitem`, item)
  }
  deleteBOMItem (id) {
    return AXIOS.delete(`${URL}/bomitem/${id}`)
  }
  getBomItemsByBomNumber (bomNumber) {
    return AXIOS.get(`${URL}/findbomitems`, {
      params: {
        bomNumber: bomNumber
      }
    })
  }
  getBomCategoriesForBom (bomNumber) {
    return AXIOS.get(`${URL}/bomcategories`, {
      params: {
        bomNumber: bomNumber
      }
    })
  }
}
export default new BOMItemDataService()
