<template>
  <v-overlay :value="showOverlay" z-index="203">
    <span class="headline">{{ overlayText }}</span>
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: 'Overlay',
  data () {
    return {
      showOverlay: false,
      overlayText: ''
    }
  },
  created () {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setOverlay') {
        this.overlayText = state.overlayText + ', Please wait...'
        this.showOverlay = state.overlayState
      }
    })
  }
}
</script>

<style scoped>

</style>
