<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">Employee Directory</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          fixed-header
          height="400"
          :headers="headers"
          :items="employees"
          :options.sync="options"
          :server-items-length="totalEmployees"
          :loading="loading"
          :footer-props="{
        'items-per-page-options':[5,10,30,100,300],
        'showFirstLastPage': true,
        'showCurrentPage': true
      }"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat color="primary lighten-1" dark>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" @keyup="searchDebounced" single-line hide-details />
              <v-divider class="mx-4" inset vertical/>
              <v-dialog persistent v-model="dialog" max-width="1500px">
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" dark class="mb-2" v-on="on">New Employee</v-btn>
                </template>
                <v-card>
                  <v-form>
                    <v-card-title>
                      <span class="display-1">{{ formTitle }}</span>
                      <v-spacer />
                      <v-img v-if="editedEmployee.picture" max-width="5%" max-height="5%" :src="'data:' + editedEmployee.contentType + ';base64,' + editedEmployee.picture"></v-img>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row dense>
                          <v-col cols="12" sm="6" md="6">
                            <v-card>
                              <v-card-title>
                                <h4>General Info</h4>
                              </v-card-title>
                              <v-card-text>
                                <v-row dense>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-col cols="12">
                                      <v-text-field
                                        autofocus
                                        v-model="editedEmployee.employeeNumber"
                                        label="Employee Number" required
                                        :error-messages="employeeNumberErrors"
                                        @input="$v.editedEmployee.employeeNumber.$touch()"
                                        @blur="$v.editedEmployee.employeeNumber.$touch()"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-menu
                                        v-model="birthDateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y

                                        max-width="290px"
                                        min-width="290px">
                                        <template v-slot:activator="{on}">
                                          <v-text-field
                                            label="Birth Date"
                                            prepend-inner-icon="mdi-calendar"
                                            readonly
                                            :value="birthDateDisp"
                                            v-on="on"
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="editedEmployee.birthDate" no-title @input="birthDateMenu = false"></v-date-picker>
                                      </v-menu>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-file-input v-model="fileToUpload" label="Attach picture" clearable></v-file-input>
                                    </v-col>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-col cols="12">
                                      <v-text-field
                                        v-model="editedEmployee.firstName"
                                        label="First Name" required
                                        :error-messages="firstNameErrors"
                                        @input="$v.editedEmployee.firstName.$touch()"
                                        @blur="$v.editedEmployee.firstName.$touch()"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-text-field v-model="editedEmployee.middleName" label="Middle Name"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-text-field
                                        v-model="editedEmployee.lastName"
                                        label="Last Name" required
                                        :error-messages="lastNameErrors"
                                        @input="$v.editedEmployee.lastName.$touch()"
                                        @blur="$v.editedEmployee.lastName.$touch()"
                                      ></v-text-field>
                                    </v-col>
                                  </v-col>
                                </v-row>
                                <v-row dense>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-textarea
                                      v-model="editedEmployee.notes"
                                      label="notes"
                                      clearable
                                      outlined
                                    ></v-textarea>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-row dense>
                                      <v-col cols="12">
                                        <v-menu
                                          v-model="startDateMenu"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          transition="scale-transition"
                                          offset-y

                                          max-width="290px"
                                          min-width="290px">
                                          <template v-slot:activator="{on}">
                                            <v-text-field
                                              label="Start Date"
                                              prepend-inner-icon="mdi-calendar"
                                              readonly
                                              :value="startDateDisp"
                                              v-on="on"
                                            ></v-text-field>
                                          </template>
                                          <v-date-picker v-model="editedEmployee.startDate" no-title @input="startDateMenu = false"></v-date-picker>
                                        </v-menu>
                                      </v-col>
                                      <v-col cols="12">
                                        <v-menu
                                          v-model="endDateMenu"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          transition="scale-transition"
                                          offset-y

                                          max-width="290px"
                                          min-width="290px">
                                          <template v-slot:activator="{on}">
                                            <v-text-field
                                              label="End Date"
                                              prepend-inner-icon="mdi-calendar"
                                              readonly
                                              :value="endDateDisp"
                                              v-on="on"
                                            ></v-text-field>
                                          </template>
                                          <v-date-picker v-model="editedEmployee.endDate" no-title @input="endDateMenu = false"></v-date-picker>
                                        </v-menu>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-card>
                              <v-card-title>
                                <h4>Contact info</h4>
                              </v-card-title>
                              <v-card-text>
                                <v-row dense>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field v-model="editedEmployee.address" label="Address"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field v-model="editedEmployee.email" label="Email"></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row dense>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field v-model="editedEmployee.contactNumber" label="Contact Number"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field v-model="editedEmployee.designation" label="Designation"></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                            <v-card>
                              <v-card-title>
                                <h4>ID Numbers</h4>
                                <v-spacer />
                                <v-btn color="primary" dark @click="addId">Add ID</v-btn>
                              </v-card-title>
                              <v-card-text>
                                <template v-for="(item, index) in editedEmployee.employeeIDDtos">
                                  <v-row v-if="item" :key="index">
                                    <v-col cols="12" sm="6" md="6" v-if="item" :key="index">
                                      <v-text-field v-if="item" v-model="item.idNumber" label="ID Number"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" v-if="item" :key="index">
                                      <v-select
                                        v-if="item"
                                        v-model="item.idType"
                                        :items="$store.getters.employeeIdTypes"
                                        item-text="description"
                                        label="ID Type"
                                        return-object
                                      ></v-select>
                                    </v-col>
                                  </v-row>
                                </template>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary darken-1" dark @click="save">Save</v-btn>
                      <v-btn color="orange darken-1" dark @click="clear">Clear</v-btn>
                      <v-btn color="red darken-1" dark @click="close">Cancel</v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.firstName="{ item }">
            <v-btn class="text-none" text @click="editEmployee(item)">{{ item.firstName }}</v-btn>
          </template>
          <template v-slot:item.lastName="{ item }">
            <v-btn class="text-none" text @click="editEmployee(item)">{{ item.lastName }}</v-btn>
          </template>
          <template v-slot:item.action="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small class="mr-2" @click="editEmployee(item)">mdi-pencil</v-icon>
              </template>
              <span>Edit Employee</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small @click="deleteEmployee(item.id)">mdi-delete</v-icon>
              </template>
              <span>Delete Employee</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <overlay></overlay>
    <snackbar></snackbar>
  </div>
</template>

<script>
import EmployeeDataService from '../../services/EmployeeDataService'
import Overlay from '../global/Overlay'
import Snackbar from '../global/Snackbar'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'ListEmployeesComponent',
  components: { Snackbar, Overlay },
  data () {
    return {
      search: '',
      loading: true,
      totalEmployees: 0,
      employees: [],
      options: {},
      errors: {},
      headers: [
        { text: 'Employee Number', align: 'left', sortable: true, value: 'employeeNumber' },
        { text: 'First Name', align: 'left', sortable: true, value: 'firstName' },
        { text: 'Last Name', align: 'left', sortable: true, value: 'lastName' },
        { text: 'E-Mail', align: 'left', sortable: true, value: 'email' },
        { text: 'Contact Number', align: 'left', sortable: false, value: 'contactNumber' },
        { text: 'SSS Number', align: 'left', sortable: false, value: 'sssNumber' },
        { text: 'TIN', align: 'left', sortable: false, value: 'tin' },
        { text: 'Designation', align: 'left', sortable: true, value: 'designation' },
        { text: 'Actions', align: 'center', sortable: false, value: 'action' }
      ],
      dialog: false,
      startDateMenu: false,
      endDateMenu: false,
      birthDateMenu: false,
      editedIndex: -1,
      fileToUpload: null,
      editedEmployee: {
        id: 0,
        employeeNumber: '',
        firstName: '',
        middleName: '',
        lastName: '',
        address: '',
        email: '',
        contactNumber: '',
        startDate: '',
        endDate: '',
        notes: '',
        designation: '',
        contentType: '',
        picture: '',
        employeeIDDtos: [],
        birthDate: ''
      },
      defaultEmployee: {
        id: 0,
        employeeNumber: '',
        firstName: '',
        middleName: '',
        lastName: '',
        address: '',
        email: '',
        contactNumber: '',
        startDate: '',
        endDate: '',
        notes: '',
        designation: '',
        contentType: '',
        picture: '',
        employeeIDDtos: [],
        birthDate: ''
      },
      defaultEmployeeIDDto: {
        id: 0,
        idNumber: '',
        idType: {
          description: ''
        }
      },
      searchTimer: null
    }
  },
  validations: {
    editedEmployee: {
      employeeNumber: { required },
      firstName: { required },
      lastName: { required }
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1
        ? 'New Employee ' : 'Edit Employee ' + this.editedEmployee.firstName + ' ' + this.editedEmployee.lastName
    },
    birthDateDisp () {
      return this.editedEmployee.birthDate
    },
    startDateDisp () {
      return this.editedEmployee.startDate
    },
    endDateDisp () {
      return this.editedEmployee.endDate
    },
    employeeNumberErrors () {
      const errors = []
      if (!this.$v.editedEmployee.employeeNumber.$dirty) return errors
      !this.$v.editedEmployee.employeeNumber.required && errors.push('Employee Number is required')
      return errors
    },
    firstNameErrors () {
      const errors = []
      if (!this.$v.editedEmployee.firstName.$dirty) return errors
      !this.$v.editedEmployee.firstName.required && errors.push('Employee First Name is required')
      return errors
    },
    lastNameErrors () {
      const errors = []
      if (!this.$v.editedEmployee.lastName.$dirty) return errors
      !this.$v.editedEmployee.lastName.required && errors.push('Employee Last Name is required')
      return errors
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true
    }
  },
  methods: {
    searchDebounced () {
      clearTimeout(this.searchTimer)
      if (this.search === '' || this.search == null || this.search.length >= 3) {
        this.searchTimer = setTimeout(() => {
          this.getDataFromApi()
        }, 500)
      }
    },
    getDataFromApi () {
      this.loading = true
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (itemsPerPage > 0) {
          let searchParams = {}
          searchParams.page = page
          searchParams.itemsPerPage = itemsPerPage
          if (sortBy.length > 0) {
            searchParams.sortBy = sortBy[0]
          }
          if (sortDesc.length > 0) {
            searchParams.sortDesc = sortDesc[0]
          }
          if (this.search) {
            searchParams.searchTerm = this.search
          }
          return EmployeeDataService.retrieveEmployeesPaginated(searchParams).then(response => {
            this.employees = response.data.content
            this.totalEmployees = response.data.totalElements
            this.loading = false
          })
        }
      })
    },
    close () {
      this.dialog = false
      this.$v.$reset()
      setTimeout(() => {
        this.editedEmployee = Object.assign({}, this.defaultEmployee)
        this.fileToUpload = null
        this.editedIndex = -1
      }, 300)
    },
    save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let createFormData = new FormData()
        if (this.editedIndex > -1) {
          this.$store.commit('setOverlay', { text: 'Updating Employee', overlayState: true })
          createFormData.append('employeeUpdateDTO', new Blob([JSON.stringify(this.editedEmployee)], { type: 'application/json' }))
          createFormData.append('file', this.fileToUpload)
          return EmployeeDataService.updateEmployee(createFormData).then(response => {
            this.$store.commit('setOverlay', { text: 'Updating Employee', overlayState: false })
            this.$store.commit('showMessage', { message: response.data, color: 'success' })
            this.getDataFromApi()
            this.close()
          }, reject => {
            this.$store.commit('setOverlay', { text: 'Updating Employee', overlayState: false })
            this.$store.commit('showMessage', { message: 'Update Employee failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
            this.close()
          })
        } else {
          this.$store.commit('setOverlay', { text: 'Creating Employee', overlayState: true })
          createFormData.append('employeeCreateDTO', new Blob([JSON.stringify(this.editedEmployee)], { type: 'application/json' }))
          createFormData.append('file', this.fileToUpload)
          return EmployeeDataService.createEmployee(createFormData).then(response => {
            this.$store.commit('setOverlay', { text: 'Creating Employee', overlayState: false })
            this.$store.commit('showMessage', { message: response.data, color: 'success' })
            this.getDataFromApi()
            this.close()
          }, reject => {
            this.$store.commit('setOverlay', { text: 'Create Employee', overlayState: false })
            this.$store.commit('showMessage', { message: 'Create Employee failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
            this.close()
          })
        }
      }
    },
    editEmployee (item) {
      this.editedIndex = this.employees.indexOf(item)
      this.dialog = true
      this.$store.commit('setOverlay', { text: 'Retrieving Employee Details', overlayState: true })
      EmployeeDataService.getEmployeeById(item.id).then(response => {
        this.$store.commit('setOverlay', { text: 'Retrieving Employee Details', overlayState: false })
        this.editedEmployee = response.data
      }, reject => {
        this.$store.commit('setOverlay', { text: 'Retrieving Employee Details', overlayState: false })
        this.$store.commit('showMessage', { message: 'Get Employee details failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
      })
    },
    deleteEmployee (id) {
      if (confirm('Do you want to delete this Employee?')) {
        this.$store.commit('setOverlay', { text: 'Deleting Employee', overlayState: true })
        EmployeeDataService.deleteEmployee(id).then(response => {
          this.$store.commit('setOverlay', { text: 'Deleting Employee', overlayState: false })
          this.$store.commit('showMessage', { message: response.data, color: 'success' })
          this.getDataFromApi()
        }, reject => {
          this.$store.commit('setOverlay', { text: 'Deleting Employee', overlayState: false })
          this.$store.commit('showMessage', { message: 'Delete Employee failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
          this.close()
        })
      }
    },
    addId () {
      const employeeIdDto = Object.assign({}, this.defaultEmployeeIDDto)
      this.editedEmployee.employeeIDDtos.push(employeeIdDto)
    },
    clear () {
      this.$v.$reset()
      this.editedEmployee = this.defaultEmployee
      this.fileToUpload = null
    }
  }
}
</script>

<style scoped>

</style>
