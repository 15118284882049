import { AXIOS } from './Axios-Common'

const URL = '/payments'
class PaymentDataService {
  retrievePaymentsPaginated (projectNumber, params) {
    return AXIOS.get(`${URL}/searchpayments`, {
      params: {
        offset: params.page - 1,
        limit: params.itemsPerPage,
        searchTerm: params.searchTerm,
        projectNumber: projectNumber,
        sortBy: params.sortBy,
        sortDesc: params.sortDesc
      }
    })
  }
  getPaymentById (id) {
    return AXIOS.get(`${URL}/payment/${id}`)
  }
  createPayment (payment) {
    return AXIOS.post(`${URL}/payment`, payment)
  }
  updatePayment (payment) {
    return AXIOS.put(`${URL}/payment`, payment)
  }
  deletePayment (id) {
    return AXIOS.delete(`${URL}/payment/${id}`)
  }
  getTotalPayment (projectNumber) {
    return AXIOS.get(`${URL}/totalpayment`, {
      params: {
        projectNumber: projectNumber
      }
    })
  }
  getLatestPayments (projectNumber) {
    return AXIOS.get(`${URL}/latestpayments`, {
      params: {
        projectNumber: projectNumber
      }
    })
  }
  getCollectionReportForProject (projectNumber) {
    return AXIOS.get(`${URL}/collectionreport`, {
      params: {
        projectNumber: projectNumber
      },
      responseType: 'blob'
    })
  }
  generateCollectionsByContractReport (projectNumber) {
    return AXIOS.get(`${URL}/collectionsbycontracttermreport`, {
      params: {
        projectNumber: projectNumber
      },
      responseType: 'blob'
    })
  }
}
export default new PaymentDataService()
