import { AXIOS } from './Axios-Common'

const URL = '/locations'
class LocationDataService {
  retrieveLocations (params) {
    return AXIOS.get(`${URL}/alllocations`, {
      params: {
        offset: params.page - 1,
        limit: params.itemsPerPage
      }
    })
  }
  getLocationById (id) {
    return AXIOS.get(`${URL}/location/${id}`)
  }
  deleteLocation (id) {
    return AXIOS.delete(`${URL}/location/${id}`)
  }
  createLocation (location) {
    return AXIOS.post(`${URL}/location`, location)
  }
  updateLocation (location) {
    return AXIOS.put(`${URL}/location`, location)
  }
  allLocations () {
    return AXIOS.get(`${URL}/alllocations2`)
  }
  generateItemsByLocationReport (ids) {
    return AXIOS.post(`${URL}/createitemsbylocationreport`, ids, {
      responseType: 'blob'
    })
  }
}

export default new LocationDataService()
