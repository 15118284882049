import PettyCashDataService from '../../services/PettyCashDataService'
import { pettyCashItem } from './pettyCashItem.module'

export const pettyCash = {
  namespaced: true,
  state: {
    pettyCash: [],
    totalPettyCash: 0,
    editPettyCashDialog: false,
    editedPettyCash: {
      id: 0,
      projectId: 0,
      projectNumber: '',
      pettyCashNumber: '',
      amount: 0,
      description: '',
      date: '',
      paymentSource: '',
      employeeDto: {
        id: 0,
        firstName: '',
        lastName: '',
        fullName: ''
      }
    },
    defaultPettyCash: {
      id: 0,
      projectId: 0,
      projectNumber: '',
      pettyCashNumber: '',
      amount: 0,
      description: '',
      date: '',
      paymentSource: '',
      employeeDto: {
        id: 0,
        firstName: '',
        lastName: '',
        fullName: ''
      }
    },
    renderKey: 0
  },
  actions: {
    getDataFromApi ({ state, commit }, payload) {
      return PettyCashDataService.retrievePettyCashPaginated(payload.projectNumber, payload.options).then(response => {
        commit('setPettyCashSuccess', response.data)
      }, reject => {
        commit('setPettyCashFailure')
      })
    },
    getPettyCash ({ state, commit }, id) {
      commit('setOverlay', { text: 'Fetching Petty Cash Details', overlayState: true }, { root: true })
      return PettyCashDataService.getPettyCashById(id).then(response => {
        commit('setOverlay', { text: 'Fetching Petty Cash Details', overlayState: false }, { root: true })
        commit('setEditedPettyCashSuccess', response.data)
      }, reject => {
        commit('setOverlay', { text: 'Fetching Petty Cash Details', overlayState: false }, { root: true })
        commit('setEditedPettyCashFailure')
      })
    },
    createPettyCash ({ state, commit, rootState }, payload) {
      commit('setOverlay', { text: 'Creating Petty Cash...', overlayState: true }, { root: true })
      return PettyCashDataService.createPettyCash(payload).then(response => {
        commit('setOverlay', { text: 'Creating Petty Cash...', overlayState: false }, { root: true })
        commit('showMessage', { message: response.data, color: 'success' }, { root: true })
        commit('setRenderKey')
        commit('setEditedPettyCashSuccess', Object.assign({}, state.defaultPettyCash))
        commit('setEditedPettyCashProjectNumber', rootState.project.project.projectNumber)
        commit('setEditedPettyCashProjectId', rootState.project.project.id)
      }, reject => {
        commit('setOverlay', { text: 'Creating Petty Cash...', overlayState: false }, { root: true })
        commit('showMessage', { message: 'Create Petty Cash failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' }, { root: true })
        commit('setRenderKey')
        commit('setEditedPettyCashSuccess', Object.assign({}, state.defaultPettyCash))
        commit('setEditedPettyCashProjectNumber', rootState.project.project.projectNumber)
        commit('setEditedPettyCashProjectId', rootState.project.project.id)
      })
    },
    updatePettyCash ({ state, commit, rootState }, payload) {
      commit('setOverlay', { text: 'Updating Petty Cash...', overlayState: true }, { root: true })
      return PettyCashDataService.updatePettyCash(payload).then(response => {
        commit('setOverlay', { text: 'Updating Petty Cash...', overlayState: false }, { root: true })
        commit('showMessage', { message: response.data, color: 'success' }, { root: true })
        commit('setRenderKey')
        commit('setEditedPettyCashSuccess', Object.assign({}, state.defaultPettyCash))
        commit('setEditedPettyCashProjectNumber', rootState.project.project.projectNumber)
        commit('setEditedPettyCashProjectId', rootState.project.project.id)
      }, reject => {
        commit('setOverlay', { text: 'Updating Petty Cash...', overlayState: false }, { root: true })
        commit('showMessage', { message: 'Update Petty Cash failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' }, { root: true })
        commit('setRenderKey')
        commit('setEditedPettyCashSuccess', Object.assign({}, state.defaultPettyCash))
        commit('setEditedPettyCashProjectNumber', rootState.project.project.projectNumber)
        commit('setEditedPettyCashProjectId', rootState.project.project.id)
      })
    },
    deletePettyCash ({ commit, state }, id) {
      commit('setOverlay', { text: 'Deleting Petty Cash...', overlayState: true }, { root: true })
      return PettyCashDataService.deletePettyCash(id).then(response => {
        commit('setOverlay', { text: 'Deleting Petty Cash...', overlayState: false }, { root: true })
        commit('showMessage', { message: response.data, color: 'success' }, { root: true })
        commit('setRenderKey')
      }, reject => {
        commit('setOverlay', { text: 'Deleting Petty Cash...', overlayState: false }, { root: true })
        commit('showMessage', { message: 'Delete Petty Cash failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' }, { root: true })
        commit('setRenderKey')
      })
    }
  },
  mutations: {
    setPettyCashSuccess (state, data) {
      state.pettyCash = data.content
      state.totalPettyCash = data.totalElements
    },
    setPettyCashFailure (state) {
      state.pettyCash = []
      state.totalPettyCash = 0
    },
    setRenderKey (state) {
      state.renderKey += 1
    },
    setEditedPettyCashSuccess (state, data) {
      state.editedPettyCash = data
    },
    setEditedPettyCashFailure (state) {
      state.editedPettyCash = state.defaultPettyCash
    },
    setEditedPettyCashProjectNumber (state, data) {
      state.editedPettyCash.projectNumber = data
    },
    setEditedPettyCashProjectId (state, data) {
      state.editedPettyCash.id = data
    },
    toggleDialog (state) {
      state.editPettyCashDialog = !state.editPettyCashDialog
    },
    setDialog (state, dialog) {
      state.editPettyCashDialog = dialog
    },
    showDialog (state) {
      state.editPettyCashDialog = true
    },
    hideDialog (state) {
      state.editPettyCashDialog = false
    }
  },
  getters: {
    pettyCash (state) {
      return state.pettyCash
    },
    totalPettyCash (state) {
      return state.totalPettyCash
    },
    editedPettyCash (state) {
      return state.editedPettyCash
    },
    editPettyCashDialog (state) {
      return state.editPettyCashDialog
    },
    pettyCashProjectNumber (state) {
      return state.editedPettyCash.projectNumber
    },
    renderKey (state) {
      return state.renderKey
    },
    defaultPettyCash (state) {
      return state.defaultPettyCash
    },
    pettyCashProjectId (state) {
      return state.editedPettyCash.projectId
    }
  },
  modules: {
    pettyCashItem
  }
}
