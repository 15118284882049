import InventoryItemDataService from '@/services/InventoryItemDataService'

export const inventoryitem = {
  namespaced: true,
  state: {
    inventoryItem: {
      id: 0,
      item: '',
      currentLocation: {
        id: 0,
        locationName: '',
        address: ''
      },
      dateAcquired: '',
      quantity: 0,
      serialNumber: '',
      manufacturer: '',
      purchaseAmount: 0,
      contentType: '',
      picture: '',
      description: '',
      inventoryId: '',
      imagePath: '',
      currentLocations: []
    },
    editedInventoryItem: {
      id: 0,
      item: '',
      currentLocation: {
        id: 0,
        locationName: '',
        address: ''
      },
      dateAcquired: '',
      quantity: 0,
      serialNumber: '',
      manufacturer: '',
      purchaseAmount: 0,
      contentType: '',
      picture: '',
      description: '',
      inventoryId: '',
      imagePath: ''
    },
    defaultInventoryItem: {
      id: 0,
      item: '',
      currentLocation: {
        id: 0,
        locationName: '',
        address: ''
      },
      dateAcquired: '',
      quantity: 0,
      serialNumber: '',
      manufacturer: '',
      purchaseAmount: 0,
      contentType: '',
      picture: '',
      description: '',
      inventoryId: '',
      imagePath: ''
    },
    inventoryItems: [],
    totalInventoryItems: 0,
    editInventoryItemDialog: false,
    renderKey: 0,
    inventoryLog: {
      inventoryItemId: 0,
      dateMoved: '',
      destination: {
        id: 0,
        locationName: '',
        address: ''
      },
      origin: {
        locationDisplayDTO: {
          id: 0,
          locationName: '',
          quantity: 0,
          address: ''
        }
      },
      receiver: '',
      sender: '',
      quantity: 0
    },
    defaultInventoryLog: {
      inventoryItemId: 0,
      dateMoved: '',
      destination: {
        id: 0,
        locationName: '',
        address: ''
      },
      origin: {
        id: 0,
        locationName: '',
        address: ''
      },
      receiver: '',
      sender: '',
      quantity: 0
    },
    addInventoryLogDialog: false,
    inventoryLogs: [],
    inventoryLogRenderKey: 0
  },
  actions: {
    getInventoryItems ({ state, commit }, payload) {
      return InventoryItemDataService.retrieveInventoryItems(payload.options)
    },
    getInventoryItem ({ state, commit }, id) {
      return InventoryItemDataService.getInventoryItemById(id)
    },
    createInventoryItem ({ state, commit }, payload) {
      return InventoryItemDataService.createInventoryItem(payload)
    },
    updateInventoryItem ({ state, commit }, payload) {
      return InventoryItemDataService.updateInventoryItem(payload)
    },
    deleteInventoryItem ({ state, commit }, id) {
      return InventoryItemDataService.deleteInventoryItem(id)
    },
    addInventoryLog ({ state, commit }, payload) {
      return InventoryItemDataService.addInventoryLog(payload)
    },
    getLogForItem ({ state, commit }, id) {
      return InventoryItemDataService.getLogsForInventoryItem(id)
    }
  },
  mutations: {
    setInventoryLogRenderKey (state) {
      state.inventoryLogRenderKey += 1
    },
    setRenderKey (state) {
      state.renderKey += 1
    },
    setInventoryItemSuccess (state, data) {
      state.inventoryItem = data
      state.inventoryLogs = data.inventoryLog
    },
    setInventoryItemFailure (state) {
      state.inventoryItem = state.defaultInventoryItem
      state.inventoryLogs = []
    },
    setInventoryItemListSuccess (state, data) {
      state.inventoryItems = data.content
      state.totalInventoryItems = data.totalElements
    },
    setInventoryItemListFailure (state) {
      state.inventoryItems = []
      state.totalInventoryItems = 0
    },
    toggleDialog (state) {
      state.editInventoryItemDialog = !state.editInventoryItemDialog
    },
    showDialog (state) {
      state.editInventoryItemDialog = true
    },
    hideDialog (state) {
      state.editInventoryItemDialog = false
    },
    setEditedInventoryItem (state, data) {
      state.editedInventoryItem = data
    },
    setInventoryLog (state, data) {
      state.inventoryLog = data
    },
    showInventoryLogDialog (state) {
      state.addInventoryLogDialog = true
    },
    hideInventoryLogDialog (state) {
      state.addInventoryLogDialog = false
    },
    toggleInventoryLogDialog (state) {
      state.addInventoryLogDialog = !state.addInventoryLogDialog
    },
    setInventoryItemIdForLog (state, data) {
      state.inventoryLog.inventoryItemId = data
    },
    setInventoryLogs (state, data) {
      state.inventoryLogs = data
    },
    setOriginForLog (state, data) {
      state.inventoryLog.origin = data
    }
  },
  getters: {
    inventoryItem (state) {
      return state.inventoryItem
    },
    totalInventoryItems (state) {
      return state.totalInventoryItems
    },
    inventoryItems (state) {
      return state.inventoryItems
    },
    editedInventoryItem (state) {
      return state.editedInventoryItem
    },
    renderKey (state) {
      return state.renderKey
    },
    inventoryLogRenderKey (state) {
      return state.inventoryLogRenderKey
    },
    defaultInventoryItem (state) {
      return state.defaultInventoryItem
    },
    inventoryLog (state) {
      return state.inventoryLog
    },
    defaultInventoryLog (state) {
      return state.defaultInventoryLog
    },
    inventoryLogs (state) {
      return state.inventoryLogs
    }
  }
}
