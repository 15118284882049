<template>
  <v-container>
    <div>
      <v-sheet color="primary lighten-1" dark width="100%" max-width="100%">
        <span class="ma-1 headline">Collections for {{ $store.getters['project/project'].name }}</span>
      </v-sheet>
    </div>
    <div>
      <v-data-table
        fixed-header
        height="400"
        :headers="headers"
        :items="payments"
        :options.sync="options"
        :server-items-length="totalPayments"
        :loading="loading"
        :key="renderKey"
        :footer-props="{
        'items-per-page-options':[5,10,30,100,300],
         'showFirstLastPage': true,
          'showCurrentPage': true }"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-btn color="primary" class="ma-2" @click="getCollectionReportForProject(projectNumber)">Generate Report</v-btn>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details @keyup="searchDebounced" />
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="primary" dark class="ma-2" @click="openDialog">
                  <v-icon>mdi-plus-box</v-icon>
                </v-btn>
              </template>
              <span>Add New Collection</span>
            </v-tooltip>
          </v-toolbar>
        </template>
        <template v-slot:item.paymentDate="{ item }">
          {{ $d(new Date(item.paymentDate), 'short') }}
        </template>
        <template v-slot:item.receiptNumber="{ item }">
          <v-icon v-if="item.contentType">mdi-paperclip</v-icon>
          {{ item.receiptNumber }}
        </template>
        <template v-slot:item.amount="{ item }">
          {{$n(item.amount, 'currency')}}
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon small class="mr-2" @click="editPayment(item)">mdi-pencil</v-icon>
          <v-icon small @click="deletePayment(item.id)">mdi-delete</v-icon>
        </template>
      </v-data-table>
      <save-collection-dialog></save-collection-dialog>
    </div>
  </v-container>
</template>

<script>
import PaymentDataService from '../../services/PaymentDataService'
import SaveCollectionDialog from './SaveCollectionDialog'

export default {
  name: 'ListPaymentsComponent',
  components: { SaveCollectionDialog },
  data () {
    return {
      search: '',
      loading: true,
      options: {},
      errors: {},
      headers: [
        { text: 'Receipt Number', align: 'center', sortable: true, value: 'receiptNumber' },
        { text: 'RCDC OR Number', align: 'center', sortable: true, value: 'rcdcORNumber' },
        { text: 'Particulars', align: 'center', sortable: true, value: 'clientDto.clientName' },
        { text: 'Payment Date', align: 'center', sortable: true, value: 'paymentDate' },
        { text: 'Description', align: 'center', sortable: true, value: 'description' },
        { text: 'Amount', align: 'right', sortable: true, value: 'amount' },
        { text: 'Actions', align: 'center', sortable: false, value: 'action' }
      ],
      searchTimer: null
    }
  },
  computed: {
    projectNumber () {
      return this.$store.getters['project/project'].projectNumber
    },
    renderKey () {
      return this.$store.getters['collections/renderKey']
    },
    payments () {
      return this.$store.getters['collections/collections']
    },
    totalPayments () {
      return this.$store.getters['collections/totalCollections']
    }
  },
  created () {
    this.getDataFromApi(this.projectNumber)
  },
  watch: {
    options: {
      handler () {
        this.getDataFromApi(this.projectNumber)
      },
      deep: true
    }
  },
  methods: {
    searchDebounced () {
      clearTimeout(this.searchTimer)
      if (this.search === '' || this.search == null || this.search.length >= 3) {
        this.searchTimer = setTimeout(() => {
          this.getDataFromApi(this.projectNumber)
        }, 500)
      }
    },
    getDataFromApi (projectNumber) {
      this.loading = true
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (itemsPerPage > 0 && projectNumber) {
          let searchParams = {}
          searchParams.page = page
          searchParams.itemsPerPage = itemsPerPage
          if (sortBy.length > 0) {
            searchParams.sortBy = sortBy[0]
          }
          if (sortDesc.length > 0) {
            searchParams.sortDesc = sortDesc[0]
          }
          if (this.search) {
            searchParams.searchTerm = this.search
          }
          let payload = {}
          payload.projectNumber = projectNumber
          payload.options = searchParams
          this.$store.dispatch('collections/getDataFromApi', payload)
          this.loading = false
        }
      })
    },
    editPayment (item) {
      this.$store.dispatch('collections/getCollection', item.id).then(response => {
        this.$store.commit('collections/showDialog')
      })
    },
    deletePayment (id) {
      if (confirm('Do you want to delete this Payment?')) {
        this.$store.dispatch('collections/deleteCollection', id).then(response => {
          this.$store.dispatch('collections/getTotalPaymentAmount', this.$store.getters['project/project'].projectNumber)
        })
      }
    },
    getCollectionReportForProject (projectNumber) {
      this.$store.commit('setOverlay', { text: 'Generating Collection Report for project', overlayState: true })
      PaymentDataService.getCollectionReportForProject(projectNumber).then(response => {
        this.$store.commit('setOverlay', { text: 'Generating Collection Report for project', overlayState: false })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.projectNumber + '_collectionreport.xlsx')
        document.body.appendChild(link)
        link.click()
      })
    },
    openDialog () {
      this.$store.commit('collections/setEditedPaymentSuccess', Object.assign({}, this.$store.getters['collections/defaultPayment']))
      this.$store.commit('collections/setCollectionProjectNumber', this.projectNumber)
      this.$store.commit('collections/showDialog')
    }
  }
}
</script>

<style scoped>

</style>
