<template>
  <v-data-table
    fixed-header
    height="400"
    :headers="headers"
    :options.sync="options"
    :loading="loading"
    :items="contractTermsWithPayments"
    :server-items-length="totalContractTermsWithPayments"
    single-expand
    show-expand
    :expanded.sync="expanded"
    class="elevation-1"
  >
    <template v-slot:top>
      <!--<v-toolbar flat>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details @keyup="searchDebounced"/>
      </v-toolbar>-->
    </template>
    <!--<template v-slot:item.collections.paymentDate="{ item }">
      <template v-for="item in item.collections">
        <div :key="item.id">{{ item.paymentDate }}</div>
      </template>
    </template>-->
    <!--<template v-slot:item.description="{ item }">
      <template v-for="collection in item.collections">
        <div :key="collection.id">{{ item.description }}</div>
      </template>
    </template>-->
    <!--<template v-slot:item.collections.description="{ item }">
      <template v-for="collection in item.collections">
        <div :key="collection.id">{{ collection.description }}</div>
      </template>
    </template>
    <template v-slot:item.collections.amount="{ item }">
      <template v-for="collection in item.collections">
        <div :key="collection.id">{{ $n(collection.amount, 'currency') }}</div>
      </template>
    </template>-->
    <template v-slot:item.amount="{ item }">
      {{ $n(item.amount, 'currency') }}
    </template>
    <template v-slot:item.totalCollected="{ item }">
      {{ $n(item.totalCollected, 'currency') }}
    </template>
    <template v-slot:item.balanceToCollect="{ item }">
      {{ $n(item.balanceToCollect, 'currency') }}
    </template>
    <template v-slot:expanded-item="{ headers, item}">
      <td :colspan="headers.length">
        <v-data-table :headers="collectionHeaders" :items="item.collections">
          <template v-slot:item.amount="{ item }">
            {{ $n(item.amount, 'currency') }}
          </template>
          <template v-slot:item.paymentDate="{ item }">
            {{ $d(new Date(item.paymentDate), 'short') }}
          </template>
        </v-data-table>
      </td>
    </template>
    <template v-slot:body.append>
      <tr>
        <td>
          <span class="subtitle-1 font-weight-bold">Total:</span>
        </td>
        <td class="text-right">
          <span class="subtitle-1 font-weight-bold">{{ $n(totalContractAmount, 'currency') }}</span>
        </td>
        <td class="text-right">
          <span class="subtitle-1 font-weight-bold">{{ $n(totalCollected, 'currency') }}</span>
        </td>
        <td class="text-right">
          <span class="subtitle-1 font-weight-bold">{{ $n(totalCollectionBalance, 'currency') }}</span>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import ProjectDataService from '../../services/ProjectDataService'
export default {
  name: 'DisplayProjectCollectionsComponent',
  props: {
    projectNumber: String
  },
  data: function () {
    return {
      search: '',
      searchTimer: null,
      totalContractTermsWithPayments: 0,
      contractTermsWithPayments: [],
      loading: true,
      options: {},
      errors: {},
      headers: [
        { text: 'Contract Term', align: 'left', sortable: false, value: 'description' },
        { text: 'Contract Amount', align: 'right', sortable: true, value: 'amount' },
        /* { text: 'Collection Date', align: 'left', sortable: true, value: 'collections.paymentDate' },
        { text: 'Description', align: 'left', sortable: false, value: 'collections.description' },
        { text: 'Collected Amounts', align: 'left', sortable: true, value: 'collections.amount' }, */
        { text: 'Total Collected', align: 'right', sortable: false, value: 'totalCollected' },
        { text: 'Balance to collect', align: 'right', sortable: false, value: 'balanceToCollect' },
        { text: 'Collection Details', align: 'left', sortable: false, value: 'data-table-expand' }
      ],
      collectionHeaders: [
        { text: 'Collection Date', align: 'left', sortable: true, value: 'paymentDate' },
        { text: 'RCDC OR Number', align: 'left', sortable: true, value: 'rcdcORNumber' },
        { text: 'Description', align: 'left', sortable: false, value: 'description' },
        { text: 'Collected Amounts', align: 'left', sortable: true, value: 'amount' }
      ],
      latestPayments: [],
      expanded: [],
      totalContractAmount: 0,
      totalCollectionBalance: 0,
      totalCollected: 0,
      adder: function (accumulator, currentValue) {
        return accumulator + currentValue
      }
    }
  },
  computed: {
    renderKey () {
      return this.$store.getters['project/contractTerm/paginated/contractTermsRenderKey']
    }
  },
  watch: {
    options: {
      handler () {
        this.getDataFromApi(this.projectNumber)
      },
      deep: true
    }
  },
  created () {
    this.getDataFromApi(this.projectNumber)
  },
  methods: {
    searchDebounced () {
      clearTimeout(this.searchTimer)
      if (this.search === '' || this.search == null || this.search.length >= 3) {
        this.searchTimer = setTimeout(() => {
          this.getDataFromApi(this.projectNumber)
        }, 500)
      }
    },
    getDataFromApi (projectNumber) {
      this.loading = true
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        if (itemsPerPage > 0 && projectNumber) {
          let searchParams = {}
          searchParams.page = page
          searchParams.itemsPerPage = itemsPerPage
          if (sortBy.length > 0) {
            searchParams.sortBy = sortBy[0]
          }
          if (sortDesc.length > 0) {
            searchParams.sortDesc = sortDesc[0]
          }
          searchParams.projectNumber = projectNumber
          if (this.search) {
            searchParams.searchTerm = this.search
          }
          return ProjectDataService.getContractsWithPaymentDetails(searchParams).then(response => {
            this.contractTermsWithPayments = response.data.content
            this.totalContractTermsWithPayments = response.data.totalElements
            this.totalContractAmount = this.contractTermsWithPayments.length > 0
              ? this.contractTermsWithPayments.reduce((sum, value) => sum + value.amount, 0) : 0
            this.totalCollected = this.contractTermsWithPayments.length > 0
              ? this.contractTermsWithPayments.reduce((sum, value) => sum + value.totalCollected, 0) : 0
            this.totalCollectionBalance = this.contractTermsWithPayments.length > 0
              ? this.contractTermsWithPayments.reduce((sum, value) => sum + value.balanceToCollect, 0) : 0
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
