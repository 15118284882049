<template>
  <div>
    <v-data-table
      fixed-header
      height="300"
      :headers="headers"
      :items="contractTerms"
      :options.sync="options"
      :server-items-length="totalContractTerms"
      :loading="loading"
      :key="renderKey"
      :footer-props="{
        'items-per-page-options':[5,10,30,100,300],
        'showFirstLastPage': true,
        'showCurrentPage': true
      }"
      class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details @keyup="searchDebounced"/>
          <v-divider class="mx-4" inset vertical />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" @click="openDialog">
                <v-icon>mdi-plus-box</v-icon>
              </v-btn>
            </template>
            <span>Add New Contract Term</span>
          </v-tooltip>
        </v-toolbar>
      </template>
      <template v-slot:item.amount="{ item }">
        {{$n(item.amount, 'currency')}}
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" small class="mr-2" @click="editContractTerm(item)">mdi-pencil</v-icon>
          </template>
          <span>Edit Contract Term</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" small @click="deleteContractTerm(item.id)">mdi-delete</v-icon>
          </template>
          <span>Delete Contract Term</span>
        </v-tooltip>
      </template>
      <template v-slot:body.append>
        <tr>
          <td colspan="2">
            <span class="subtitle-1 font-weight-bold">Total:</span>
          </td>
          <td>
            <p class="text-right font-weight-bold subtitle-1">{{ $n(totalContract, 'currency') }}</p>
          </td>
        </tr>
      </template>
      <template v-slot:item.date="{ item }">
        {{ $d(new Date(item.date), 'short') }}
      </template>
    </v-data-table>
    <save-contract-term-dialog></save-contract-term-dialog>
  </div>
</template>

<script>
import SaveContractTermDialog from './SaveContractTermDialog'

export default {
  name: 'SaveAndDisplayContractTermsComponent',
  components: { SaveContractTermDialog },
  data () {
    return {
      search: '',
      searchTimer: null,
      loading: true,
      options: {},
      headers: [
        { text: 'Date', align: 'left', sortable: true, value: 'date' },
        { text: 'Item', align: 'left', sortable: true, value: 'description' },
        { text: 'Amount', align: 'right', sortable: true, value: 'amount' },
        { text: 'Actions', align: 'center', sortable: false, value: 'action' }
      ]
    }
  },
  computed: {
    projectNumber () {
      return this.$store.getters['project/project'].projectNumber
    },
    contractTerms () {
      return this.$store.getters['project/contractTerm/paginated/contractTerms']
    },
    totalContractTerms () {
      return this.$store.getters['project/contractTerm/paginated/totalContractTerms']
    },
    totalContract () {
      return this.$store.getters['project/contractTerm/totalContractTermAmount']
    },
    renderKey () {
      return this.$store.getters['project/contractTerm/paginated/contractTermsRenderKey']
    }
  },
  watch: {
    options: {
      handler () {
        this.getDataFromApi(this.projectNumber)
      },
      deep: true
    }
  },
  created () {
    this.getDataFromApi(this.projectNumber)
  },
  methods: {
    searchDebounced () {
      clearTimeout(this.searchTimer)
      if (this.search === '' || this.search == null || this.search.length >= 3) {
        this.searchTimer = setTimeout(() => {
          this.getDataFromApi(this.projectNumber)
        }, 500)
      }
    },
    getDataFromApi (projectNumber) {
      this.loading = true
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        if (itemsPerPage > 0 && projectNumber) {
          let searchParams = {}
          searchParams.page = page
          searchParams.itemsPerPage = itemsPerPage
          if (sortBy.length > 0) {
            searchParams.sortBy = sortBy[0]
          }
          if (sortDesc.length > 0) {
            searchParams.sortDesc = sortDesc[0]
          }
          searchParams.projectNumber = projectNumber
          if (this.search) {
            searchParams.searchTerm = this.search
          }
          let payload = {}
          payload.projectNumber = projectNumber
          payload.options = searchParams
          this.$store.dispatch('project/contractTerm/paginated/searchContractTerms', payload).then(response => {
            this.$store.dispatch('project/contractTerm/getTotalContractTermAmount', projectNumber)
          })
          this.loading = false
        }
      })
    },
    editContractTerm (item) {
      this.$store.dispatch('project/contractTerm/getContractTerm', item.id).then(response => {
        this.$store.commit('project/contractTerm/showContractTermDialog')
      })
    },
    deleteContractTerm (id) {
      if (confirm('Do you want to delete this Contract Term?')) {
        this.$store.dispatch('project/contractTerm/deleteContractTerm', id).then(response => {
          this.$store.dispatch('project/contractTerm/unPaginated/getContractTerms', this.editedContractTerm.projectNumber)
          this.$store.commit('project/contractTerm/paginated/setContractTermsRenderKey')
          this.$store.dispatch('project/contractTerm/getTotalContractTermAmount', this.projectNumber)
        })
      }
    },
    openDialog () {
      this.$store.commit('project/contractTerm/setEditedContractTerm', Object.assign({}, this.$store.getters['project/contractTerm/defaultContractTerm']))
      this.$store.commit('project/contractTerm/setContractTermProjectNumber', this.projectNumber)
      this.$store.commit('project/contractTerm/showContractTermDialog')
    }
  }
}
</script>

<style scoped>

</style>
