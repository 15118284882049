<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-left">Category</th>
        <th class="text-left">Budget</th>
        <th class="text-left">Expense</th>
        <th class="text-left">Budget Remaining</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in budgets" :key="item.bomCategory.description">
        <td class="text-left">{{ item.bomCategory.description }}</td>
        <td class="text-left">{{ $n(item.totalBudget, 'currency') }}</td>
        <td class="text-left">{{ $n(item.totalExpense, 'currency') }}</td>
        <td class="text-left">{{ $n(item.difference, 'currency') }}</td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import BOMDataService from '../../services/BOMDataService'

export default {
  name: 'DisplayBOMBudgetTable',
  props: {
    bomNumber: String
  },
  data () {
    return {
      budgets: []
    }
  },
  methods: {
    getData (bomNumber) {
      BOMDataService.getBudgetByCategory(bomNumber).then(response => {
        this.budgets = response.data
      })
    }
  }
}
</script>

<style scoped>

</style>
