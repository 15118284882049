<template>
  <div>
    <div>
      <v-sheet color="primary lighten-1" dark width="100%" max-width="100%">
        <span class="headline ma-2">Purchase Item List for {{ this.bom.description }}</span>
      </v-sheet>
    </div>
    <div>
      <v-data-table
        fixed-header
        height="400"
        :headers="headers"
        :items="purchaseItems"
        :options.sync="options"
        :server-items-length="totalPurchases"
        :loading="loading"
        :footer-props="{ 'items-per-page-options':[5,10,30,100,300] }"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details @keyup="searchDebounced" />
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-dialog persistent v-model="dialog" max-width="1500px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2" v-on="on">New Purchase Item</v-btn>
              </template>
              <v-card>
                <v-form v-model="valid">
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row dense>
                        <v-col cols="12" sm="6">
                          <v-card>
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="12">
                                  <v-select
                                    v-model="editedPurchaseItem.bomItemDto"
                                    :items="bomItems"
                                    autofocus
                                    item-text="description"
                                    item-value="id"
                                    hint="Select BOM Item"
                                    persistent-hint
                                    return-object
                                    label="BOM Item"
                                    @change="onItemSelect(item)"
                                  >
                                    BOM Item
                                  </v-select>
                                </v-col>
                                <v-col cols="12">
                                  <v-currency-field label="Quantity" :error-messages="errors.rate" v-model.number="editedPurchaseItem.quantity"></v-currency-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-select
                                    v-model="editedPurchaseItem.unit"
                                    :items="$store.getters.units"
                                    hint="Select Unit"
                                    persistent-hint
                                    item-text="description"
                                    item-value="description"
                                    return-object
                                    label="Unit"
                                    readonly
                                  >
                                    Unit
                                  </v-select>
                                </v-col>
                              </v-row>
                              <v-row dense>
                                <v-col cols="12" sm="6">
                                  <v-currency-field label="VAT" :error-messages="errors.rate" v-model.number="editedPurchaseItem.vat"></v-currency-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <v-currency-field label="Total Amount" :error-messages="errors.rate" v-model.number="editedPurchaseItem.amount"></v-currency-field>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-card>
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="12">
                                  <v-menu v-model="purchaseItemDateMenu"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          transition="scale-transition"
                                          offset-y
                                          max-width="290px"
                                          min-width="290px">
                                    <template v-slot:activator="{ on }">
                                      <v-text-field label="Purchase Date"
                                                    prepend-inner-icon="mdi-calendar"
                                                    readonly
                                                    :value="purchaseDateDisp"
                                                    required
                                                    :error-messages="dateErrors"
                                                    @input="$v.editedPurchaseItem.purchaseDate.$touch()"
                                                    @blur="$v.editedPurchaseItem.purchaseDate.$touch()"
                                                    v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="editedPurchaseItem.purchaseDate" no-title @input="purchaseItemDateMenu = false"></v-date-picker>
                                  </v-menu>
                                </v-col>
                                <v-col cols="12">
                                  <v-autocomplete v-model="editedPurchaseItem.supplierDto"
                                                  :items="suppliers"
                                                  item-text="name"
                                                  item-value="id"
                                                  :loading="loadingSuppliers"
                                                  :search-input.sync="searchSupplier"
                                                  :error-messages="supplierErrors"
                                                  @input="$v.editedPurchaseItem.supplierDto.id.$touch()"
                                                  @blur="$v.editedPurchaseItem.supplierDto.id.$touch()"
                                                  filled label="Choose a supplier" return-object>
                                  </v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                  <v-textarea v-model="editedPurchaseItem.description" label="Description" clearable outlined></v-textarea>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" dark @click="save">Save</v-btn>
                    <v-btn color="orange darken-1" dark @click="clear">Clear</v-btn>
                    <v-btn color="red darken-1" dark @click="close">Cancel</v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon small class="mr-2" @click="editPurchaseItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deletePurchaseItem(item.id)">mdi-delete</v-icon>
        </template>
        <template v-slot:item.amount="{ item }">
          {{$n(item.amount, 'currency')}}
        </template>
        <template v-slot:item.purchaseDate="{ item }">
          {{ $d(new Date(item.purchaseDate), 'short') }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import PurchaseDataService from '../../services/PurchaseDataService'
import BOMItemDataService from '../../services/BOMItemDataService'
import SupplierDataService from '../../services/SupplierDataService'
import { required, minValue } from 'vuelidate/lib/validators'

export default {
  name: 'ListPurchasesComponent',
  props: ['bomnumber'],
  data () {
    return {
      bom: {},
      search: '',
      valid: false,
      loading: true,
      totalPurchases: 0,
      purchaseItems: [],
      options: {},
      errors: {},
      bomItems: [],
      headers: [
        { text: 'BOM Item Number', align: 'center', sortable: true, value: 'bomItemNumber' },
        { text: 'BOM Item', align: 'center', sortable: true, value: 'bomItemDto.description' },
        { text: 'Purchase Number', align: 'center', sortable: true, value: 'purchaseItemNumber' },
        { text: 'Description', align: 'center', sortable: true, value: 'description' },
        { text: 'Quantity', align: 'center', sortable: false, value: 'quantity' },
        { text: 'Unit', align: 'center', sortable: true, value: 'unit.description' },
        { text: 'Purchase Date', align: 'center', sortable: true, value: 'purchaseDate' },
        { text: 'Amount', align: 'center', sortable: true, value: 'amount' },
        { text: 'Actions', align: 'center', sortable: false, value: 'action' }
      ],
      dialog: false,
      purchaseItemDateMenu: false,
      editedIndex: -1,
      editedPurchaseItem: {
        id: 0,
        purchaseItemNumber: '',
        bomItemNumber: '',
        amount: 0,
        vat: 0,
        quantity: 0,
        unit: {
          description: '',
          abbreviation: ''
        },
        supplierDto: {
          id: 0,
          name: 0
        },
        purchaseDate: '',
        description: '',
        bomNumber: '',
        projectNumber: '',
        bomItemDto: {
          id: 0,
          itemNumber: '',
          bomCategory: {
            description: ''
          },
          description: '',
          quantity: 0,
          unit: {
            description: '',
            abbreviation: ''
          }
        }
      },
      defaultPurchaseItem: {
        id: 0,
        purchaseItemNumber: '',
        bomItemNumber: '',
        amount: 0,
        vat: 0,
        quantity: 0,
        unit: {
          description: '',
          abbreviation: ''
        },
        supplierDto: {
          id: 0,
          name: 0
        },
        purchaseDate: '',
        description: '',
        bomNumber: '',
        projectNumber: '',
        bomItemDto: {
          id: 0,
          itemNumber: '',
          bomCategory: {
            description: ''
          },
          description: '',
          quantity: 0,
          unit: {
            description: '',
            abbreviation: ''
          }
        }
      },
      searchTimer: null,
      searchSupplierTimer: null,
      suppliers: [],
      loadingSuppliers: false,
      searchSupplier: null
    }
  },
  validations: {
    editedPurchaseItem: {
      purchaseDate: { required },
      supplierDto: {
        id: { minValue: minValue(1) }
      },
      bomItemDto: {
        id: { minValue: minValue(1) }
      }
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1
        ? 'New Purchase Item for ' + this.bom.description : 'Edit Purchase Item ' + this.editedPurchaseItem.purchaseItemNumber
    },
    purchaseDateDisp () {
      return this.editedPurchaseItem.purchaseDate
    },
    dateErrors () {
      const errors = []
      if (!this.$v.editedPurchaseItem.purchaseDate.$dirty) return errors
      !this.$v.editedPurchaseItem.purchaseDate.required && errors.push('Please enter a date')
      return errors
    },
    supplierErrors () {
      const errors = []
      if (!this.$v.editedPurchaseItem.supplierDto.id.$dirty) return errors
      !this.$v.editedPurchaseItem.supplierDto.id.minValue && errors.push('Please select a supplier')
      return errors
    },
    bomItemErrors () {
      const errors = []
      if (!this.$v.editedPurchaseItem.bomItemDto.id.$dirty) return errors
      !this.$v.editedPurchaseItem.bomItemDto.id.minValue && errors.push('Please select a supplier')
      return errors
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    options: {
      handler () {
        this.getDataFromApi(this.bom.bomNumber)
      },
      deep: true
    },
    searchSupplier (value) {
      if (!value) {
        return
      }
      this.searchSuppliersDebounced(value)
    }
  },
  methods: {
    setBom (bom) {
      this.bom = bom
      this.editedPurchaseItem.bomNumber = this.bom.bomNumber
      this.editedPurchaseItem.projectNumber = this.bom.projectNumber
      this.defaultPurchaseItem.bomNumber = this.bom.bomNumber
      this.defaultPurchaseItem.projectNumber = this.bom.projectNumber
    },
    searchSuppliersDebounced (value) {
      clearTimeout(this.searchSupplierTimer)
      if (this.searchSupplier === '' || this.searchSupplier == null || this.searchSupplier.length >= 3) {
        this.searchSupplierTimer = setTimeout(() => {
          this.findSuppliersByName(value)
        }, 500)
      }
    },
    searchDebounced () {
      clearTimeout(this.searchTimer)
      if (this.search === '' || this.search == null || this.search.length >= 3) {
        this.searchTimer = setTimeout(() => {
          this.getDataFromApi(this.bom.bomNumber)
        }, 500)
      }
    },
    findSuppliersByName (value) {
      if (!value) {
        this.suppliers = []
      }

      this.loadingSuppliers = true
      return SupplierDataService.findByName(value).then(response => {
        this.suppliers = response.data
        this.loadingSuppliers = false
      }, reject => {
        this.loadingSuppliers = false
        this.$store.commit('showMessage', { message: 'Search Supplier failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
      })
    },
    bomItemText (item) {
      return item.bomCategory.description + ': ' + item.description + ' - ' + item.quantity + ' ' + item.unit.abbreviation
    },
    getDataFromApi (bomNumber) {
      this.loading = true
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (itemsPerPage > 0 && bomNumber) {
          let searchParams = {}
          searchParams.page = page
          searchParams.itemsPerPage = itemsPerPage
          if (sortBy.length > 0) {
            searchParams.sortBy = sortBy[0]
          }
          if (sortDesc.length > 0) {
            searchParams.sortDesc = sortDesc[0]
          }
          searchParams.projectNumber = bomNumber
          if (this.search) {
            searchParams.searchTerm = this.search
          }
          return PurchaseDataService.retrievePurchasesPaginated(bomNumber, searchParams).then(response => {
            this.purchaseItems = response.data.content
            this.totalPurchases = response.data.totalElements
            this.loading = false
          })
        }
      })
    },
    getBomItemsByBomNumber (bomNumber) {
      return new Promise((resolve, reject) => {
        return BOMItemDataService.getBomItemsByBomNumber(bomNumber).then(response => {
          this.bomItems = response.data
        })
      })
    },
    close () {
      this.dialog = false
      this.$v.$reset()
      setTimeout(() => {
        this.editedPurchaseItem = Object.assign({}, this.defaultPurchaseItem)
        this.editedIndex = -1
        this.searchSupplier = null
      }, 300)
    },
    save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.editedIndex > -1) {
          this.$store.commit('setOverlay', { text: 'Updating Purchase Item', overlayState: true })
          return PurchaseDataService.updatePurchaseItem(this.editedPurchaseItem).then(response => {
            this.$store.commit('setOverlay', { text: 'Updating Purchase Item', overlayState: false })
            this.$store.commit('showMessage', { message: response.data, color: 'success' })
            this.getDataFromApi(this.bom.bomNumber)
            this.updateBomItemsComponent()
            this.close()
          }, reject => {
            this.$store.commit('setOverlay', { text: 'Updating Purchase Item', overlayState: false })
            this.$store.commit('showMessage', { message: 'Update Purchase Item failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
            this.close()
          })
        } else {
          this.$store.commit('setOverlay', { text: 'Creating Purchase Item', overlayState: true })
          return PurchaseDataService.createPurchaseItem(this.editedPurchaseItem).then(response => {
            this.$store.commit('setOverlay', { text: 'Creating Purchase Item', overlayState: false })
            this.$store.commit('showMessage', { message: response.data, color: 'success' })
            this.getDataFromApi(this.bom.bomNumber)
            this.updateBomItemsComponent()
            this.close()
          }, reject => {
            this.$store.commit('setOverlay', { text: 'Creating Purchase Item', overlayState: false })
            this.$store.commit('showMessage', { message: 'Create Purchase Item failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
            this.close()
          })
        }
      }
    },
    editPurchaseItem (item) {
      this.editedIndex = this.purchaseItems.indexOf(item)
      this.dialog = true
      this.$store.commit('setOverlay', { text: 'Retrieving Purchase Item Details', overlayState: true })
      PurchaseDataService.getPurchaseItemById(item.id).then(response => {
        this.$store.commit('setOverlay', { text: 'Retrieving Purchase Item Details', overlayState: false })
        this.editedPurchaseItem = response.data
        this.suppliers = []
        if (this.editedPurchaseItem.supplierDto.id > 0) {
          this.suppliers.push(this.editedPurchaseItem.supplierDto)
        }
      }, reject => {
        this.$store.commit('setOverlay', { text: 'Retrieving Purchase Item Details', overlayState: false })
        this.$store.commit('showMessage', { message: 'Get Purchase Item details failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
      })
    },
    deletePurchaseItem (id) {
      if (confirm('Do you want to delete this Purchase Item?')) {
        this.$store.commit('setOverlay', { text: 'Deleting Purchase Item', overlayState: true })
        PurchaseDataService.deletePurchaseItem(id).then(response => {
          this.$store.commit('setOverlay', { text: 'Deleting Purchase Item', overlayState: false })
          this.$store.commit('showMessage', { message: response.data, color: 'success' })
          this.getDataFromApi(this.bom.bomNumber)
          this.updateBomItemsComponent()
        }, reject => {
          this.$store.commit('setOverlay', { text: 'Deleting Purchase Item', overlayState: false })
          this.$store.commit('showMessage', { message: 'Delete Purchase Item failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
        })
      }
    },
    supplierFilter (item, queryText, itemText) {
      const supplierName = item.name.toLowerCase()
      const searchText = queryText.toLowerCase()

      return searchText.length > 2 && supplierName.indexOf(searchText) > -1
    },
    clear () {
      this.$v.$reset()
      this.editedPurchaseItem = this.defaultPurchaseItem
      this.searchSupplier = null
    },
    updateBomItemsComponent () {
      this.$emit('bomItems')
    },
    onItemSelect () {
      this.editedPurchaseItem.unit = this.editedPurchaseItem.bomItemDto.unit
    }
  }
}
</script>

<style scoped>

</style>
