<template>
  <v-container v-if="projectHasBeenFetched">
    <v-card class="mx-auto" flat>
      <v-card-title>
        <v-toolbar flat>
          <v-breadcrumbs large :items="items" divider="|">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item :to="item.to">
                <v-icon large class="mr-1">{{ item.icon }}</v-icon>
                <span class="subtitle-1">{{ item.text }}</span>
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-toolbar>
        <v-sheet width="100%">
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="display-1">{{ project.name }}</v-list-item-title>
                <v-list-item-title class="title">{{ project.projectType }}</v-list-item-title>
                <v-list-item-title class="title">Client: {{ project.clientDisplayDto.clientName }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-card-title>
      <v-card-text>
        <v-expansion-panels v-model="panel" accordion>
          <v-expansion-panel>
            <v-expansion-panel-header  class="white--text title" color="primary lighten-1">
              <span class="subtitle-1"> More Details</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-sheet width="100%">
                <v-row dense>
                  <v-col cols="12" sm="6" lg="4">
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="subtitle-1">Project Start: {{ $d(new Date(project.startDate), 'short') }}</v-list-item-title>
                          <v-list-item-title class="subtitle-1">Project End: {{ $d(new Date(project.endDate), 'short') }}</v-list-item-title>
                          <v-list-item-title class="subtitle-1">Total Contract Amount: {{ $n(totalContractTerms, 'currency') }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4">
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="subtitle-1">Total Collection: {{ $n(totalCollections, 'currency') }}</v-list-item-title>
                          <v-list-item-title class="subtitle-1">Total Expenses: {{ $n(totalExpenses, 'currency') }}</v-list-item-title>
                          <v-list-item-title class="subtitle-1">Profit/(Loss): <span class="font-weight-bold">{{ $n(profitOrLossAmount, 'currency') }}</span></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <router-view v-if="projectHasBeenFetched" :parentProject="project">
        </router-view>
      </v-card-text>
    </v-card>
    <snackbar></snackbar>
    <overlay></overlay>
  </v-container>
</template>

<script>
import Snackbar from '../components/global/Snackbar'
import Overlay from '../components/global/Overlay'

export default {
  name: 'ProjectHome',
  components: { Overlay, Snackbar },
  props: {
    projectnumber: String
  },
  computed: {
    project () {
      return this.$store.getters['project/project']
    },
    projectHasBeenFetched () {
      return this.project.id > 0
    },
    totalCollections () {
      return this.$store.getters['collections/totalPaymentAmount']
    },
    totalExpenses () {
      return this.$store.getters['expenses/getTotalSpendAmount']
    },
    profitOrLossAmount () {
      return this.totalCollections - this.totalExpenses
    },
    totalContractTerms () {
      return this.$store.getters['project/contractTerm/totalContractTermAmount']
    }
  },
  data () {
    return {
      panel: 0,
      items: [
        { text: 'Details', link: true, to: '/project/' + this.projectnumber, icon: 'mdi-home' },
        { text: 'Expenses', link: true, to: '/' + this.projectnumber + '/vouchers', icon: 'mdi-cash-minus' },
        { text: 'Collections', link: true, to: '/' + this.projectnumber + '/payments', icon: 'mdi-cash-plus' },
        { text: 'Petty Cash', link: true, to: '/' + this.projectnumber + '/pettycash', icon: 'mdi-note-outline' },
        { text: 'Bill of Materials', link: true, to: '/' + this.projectnumber + '/billofmaterials', icon: 'mdi-clipboard-text-outline' }
      ]
    }
  },
  created () {
    this.setProject(this.$route.params.projectnumber)
  },
  methods: {
    setProject (id) {
      this.$store.dispatch('project/getProject', id).then(response => {
        this.$store.commit('project/setProjectSuccess', response.data)
        this.$store.dispatch('project/contractTerm/unPaginated/getContractTerms', response.data.projectNumber)
        this.$store.dispatch('expenses/getTotalSpendAmount', response.data.projectNumber)
        this.$store.dispatch('collections/getTotalPaymentAmount', response.data.projectNumber)
        this.$store.dispatch('project/contractTerm/getTotalContractTermAmount', response.data.projectNumber)
      })
    }
  }
}
</script>

<style scoped>

</style>
