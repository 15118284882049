var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',[_c('v-sheet',{attrs:{"color":"primary lighten-1","dark":"","width":"100%","max-width":"100%"}},[_c('span',{staticClass:"ma-2 headline"},[_vm._v("Expense List for "+_vm._s(_vm.$store.getters['project/project'].name))])])],1),_c('div',[_c('v-data-table',{key:_vm.renderKey,staticClass:"elevation-1",attrs:{"fixed-header":"","height":"400","headers":_vm.headers,"items":_vm.vouchers,"options":_vm.options,"server-items-length":_vm.totalVouchers,"loading":_vm.loading,"footer-props":{
      'items-per-page-options':[5,10,30,100,300],
      'showFirstLastPage': true,
      'showCurrentPage': true
    },"show-select":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.printSelectedVouchers}},on),[_c('v-icon',[_vm._v("mdi-printer")])],1)]}}])},[_c('span',[_vm._v("Print Selected Vouchers")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.generateExpenseReportForProject}},on),[_c('v-icon',[_vm._v("mdi-table-arrow-down")])],1)]}}])},[_c('span',[_vm._v("Generate Project Expense Report")])]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},on:{"keyup":_vm.searchDebounced},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.openDialog}},on),[_c('v-icon',[_vm._v("mdi-plus-box")])],1)]}}])},[_c('span',[_vm._v("Add New Voucher")])])],1)]},proxy:true},{key:"item.voucherNumber",fn:function(ref){
    var item = ref.item;
return [(item.contentType)?_c('v-icon',[_vm._v("mdi-paperclip")]):_vm._e(),_vm._v(" "+_vm._s(item.voucherNumber)+" ")]}},{key:"item.amount",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.amount, 'currency'))+" ")]}},{key:"item.voucherDate",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(new Date(item.voucherDate), 'short'))+" ")]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editVoucher(item)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Edit Voucher")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.deleteVoucher(item.id)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Delete Voucher")])])]}}]),model:{value:(_vm.selectedVouchers),callback:function ($$v) {_vm.selectedVouchers=$$v},expression:"selectedVouchers"}}),_c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"ma-2",attrs:{"label":"Date From","prepend-inner-icon":"mdi-calendar","readonly":"","value":_vm.expenseReportDateStartDisp}},on))]}}]),model:{value:(_vm.expenseReportDateStartMenu),callback:function ($$v) {_vm.expenseReportDateStartMenu=$$v},expression:"expenseReportDateStartMenu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.expenseReportDateStartMenu = false}},model:{value:(_vm.expenseReportDates.dateStart),callback:function ($$v) {_vm.$set(_vm.expenseReportDates, "dateStart", $$v)},expression:"expenseReportDates.dateStart"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"ma-2",attrs:{"label":"Date To","prepend-inner-icon":"mdi-calendar","readonly":"","value":_vm.expenseReportDateEndDisp}},on))]}}]),model:{value:(_vm.expenseReportDateEndMenu),callback:function ($$v) {_vm.expenseReportDateEndMenu=$$v},expression:"expenseReportDateEndMenu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.expenseReportDateEndMenu = false}},model:{value:(_vm.expenseReportDates.dateEnd),callback:function ($$v) {_vm.$set(_vm.expenseReportDates, "dateEnd", $$v)},expression:"expenseReportDates.dateEnd"}})],1)],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary lighten-1","dark":""},on:{"click":_vm.generateExpenseReport}},[_vm._v("Generate Expense Report by date")]),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary lighten-1","dark":""},on:{"click":_vm.printVouchersByDate}},[_vm._v("Print Vouchers by date")])],1)],1)],1)],1),_c('save-voucher-dialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }