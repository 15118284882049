import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import Vuelidate from 'vuelidate'
import VCurrencyField from 'v-currency-field'
import vuetify from './plugins/vuetify'
import { VTextField } from 'vuetify/lib'
import ZoomOnHover from 'vue-zoom-on-hover'
import './registerServiceWorker'
import { i18n } from './plugins/i18nformatter'

Vue.config.devtools = false
Vue.config.productionTip = true
Vue.component('v-text-field', VTextField)
Vue.use(Vuelidate)
Vue.use(ZoomOnHover)
Vue.use(VCurrencyField, {
  locale: 'en',
  decimalLength: 2,
  autoDecimalMode: true,
  min: 0.00,
  max: 999999999999.99
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
