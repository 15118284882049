<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">User List</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          fixed-header
          height="400"
          :headers="headers"
          :items="users"
          :options.sync="options"
          :server-items-length="totalUsers"
          :loading="loading"
          :footer-props="{ 'items-per-page-options':[5,10,30,100,300] }"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat color="primary lighten-1" dark>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" @keyup="searchProjectsDebounced" single-line hide-details />
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-dialog persistent v-model="dialog" max-width="750px">
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" dark class="mb-2" v-on="on">New User</v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{formTitle}}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row dense>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedUser.username"
                            label="Username" required
                            autofocus
                            :error-messages="usernameErrors"
                            @input="$v.editedUser.username.$touch()"
                            @blur="$v.editedUser.username.$touch()"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="editedUser.fullName" label="Full Name"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="editedUser.password" label="Password" type="password"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-select required v-model="editedUser.roles"
                                    :items="$store.getters.roles"
                                    multiple chips
                                    hint="User Roles"
                                    persistent-hint
                                    :error-messages="roleErrors"
                                    @input="$v.editedUser.roles.$touch()"
                                    @blur="$v.editedUser.roles.$touch()"
                                    label="User Roles"
                          >
                            Roles
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" dark @click="save">Save</v-btn>
                    <v-btn color="orange darken-1" dark @click="clear">Clear</v-btn>
                    <v-btn color="red darken-1" dark @click="close">Cancel</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.action="{item}">
            <v-icon small class="mr-2" @click="editUser(item)">mdi-pencil</v-icon>
            <v-icon small @click="deleteUser(item.id)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <snackbar></snackbar>
    <overlay></overlay>
  </div>
</template>

<script>
import UserDataService from '../../services/UserDataService'
import { required, minLength } from 'vuelidate/lib/validators'
import Snackbar from '../global/Snackbar'
import Overlay from '../global/Overlay'
export default {
  name: 'ListUsersComponent',
  components: { Overlay, Snackbar },
  data () {
    return {
      search: '',
      totalUsers: 0,
      users: [],
      loading: true,
      options: {},
      errors: {},
      headers: [
        { text: 'Username', align: 'left', sortable: true, value: 'username' },
        { text: 'Full Name', align: 'left', sortable: true, value: 'fullName' },
        { text: 'Actions', sortable: false, value: 'action' }
      ],
      dialog: false,
      editedIndex: -1,
      editedUser: {
        id: 0,
        username: '',
        fullName: '',
        password: '',
        roles: []
      },
      defaultUser: {
        id: 0,
        username: '',
        fullName: '',
        password: '',
        roles: []
      },
      searchTimer: null
    }
  },
  validations: {
    editedUser: {
      username: { required },
      roles: { minLength: minLength(1) }
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New User' : 'Edit User' + this.editedUser.fullName
    },
    usernameErrors () {
      const errors = []
      if (!this.$v.editedUser.username.$dirty) return errors
      !this.$v.editedUser.username.required && errors.push('Username is required')
      return errors
    },
    roleErrors () {
      const errors = []
      if (!this.$v.editedUser.roles.$dirty) return errors
      !this.$v.editedUser.roles.minLength && errors.push('Username is required')
      return errors
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true
    }
  },
  methods: {
    searchProjectsDebounced () {
      clearTimeout(this.searchTimer)
      if (this.search === '' || this.search == null || this.search.length >= 3) {
        this.searchTimer = setTimeout(() => {
          this.getDataFromApi()
        }, 500)
      }
    },
    getDataFromApi () {
      this.loading = true
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (itemsPerPage > 0) {
          let searchParams = {}
          searchParams.page = page
          searchParams.itemsPerPage = itemsPerPage
          if (sortBy.length > 0) {
            searchParams.sortBy = sortBy[0]
          }
          if (sortDesc.length > 0) {
            searchParams.sortDesc = sortDesc[0]
          }
          if (this.search) {
            searchParams.searchTerm = this.search
          }
          return UserDataService.retrieveUsersPaginated(searchParams).then(response => {
            this.users = response.data.content
            this.totalUsers = response.data.totalElements
            this.loading = false
            if (!this.search || this.search === '') {
              this.$store.commit('setUsers', response.data)
            }
          }, reject => {
            this.$store.commit('showMessage', { message: reject.response.data, color: 'error' })
            this.loading = false
          })
        }
      })
    },
    editUser (item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedUser = Object.assign({}, item)
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.$v.$reset()
      setTimeout(() => {
        this.editedUser = Object.assign({}, this.defaultUser)
        this.editedIndex = -1
      }, 300)
    },
    save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.editedIndex > -1) {
          this.$store.commit('setOverlay', { text: 'Updating User', overlayState: true })
          return UserDataService.updateUser(this.editedUser).then(response => {
            this.$store.commit('showMessage', { message: response.data, color: 'success' })
            this.getDataFromApi()
            this.$store.commit('setOverlay', { text: 'Updating User', overlayState: false })
            this.close()
          }, reject => {
            this.$store.commit('setOverlay', { text: 'Updating User', overlayState: false })
            this.$store.commit('showMessage', { message: 'Update User failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
            this.close()
          })
        } else {
          this.$store.commit('setOverlay', { text: 'Creating User', overlayState: true })
          return UserDataService.createUser(this.editedUser).then(response => {
            this.$store.commit('showMessage', { message: response.data, color: 'success' })
            this.getDataFromApi()
            this.$store.commit('setOverlay', { text: 'Creating User', overlayState: false })
            this.close()
          }, reject => {
            this.$store.commit('setOverlay', { text: 'Creating User', overlayState: false })
            this.$store.commit('showMessage', { message: 'Creating User failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
            this.close()
          })
        }
      }
    },
    deleteUser (id) {
      if (confirm('Do you want to delete this User?')) {
        this.$store.commit('setOverlay', { text: 'Deleting User', overlayState: true })
        UserDataService.deleteUser(id).then(response => {
          this.$store.commit('setOverlay', { text: 'Deleting User', overlayState: false })
          this.$store.commit('showMessage', { message: response.data, color: 'success' })
          this.getDataFromApi()
        }, reject => {
          this.$store.commit('setOverlay', { text: 'Deleting User', overlayState: false })
          this.$store.commit('showMessage', { message: 'Delete User failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
          this.close()
        })
      }
    },
    clear () {
      this.$v.$reset()
      this.editedUser = this.defaultUser
    }
  }
}
</script>

<style scoped>

</style>
