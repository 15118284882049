<template>
  <v-container>
    <div>
      <v-sheet color="primary lighten-1" dark width="100%" max-width="100%">
        <span class="ma-2 headline">Expense List for {{ $store.getters['project/project'].name }}</span>
      </v-sheet>
    </div>
    <div>
      <v-data-table fixed-header height="400" :key="renderKey" v-model="selectedVouchers" :headers="headers"
        :items="vouchers" :options.sync="options" :server-items-length="totalVouchers" :loading="loading"
        :footer-props="{
        'items-per-page-options':[5,10,30,100,300],
        'showFirstLastPage': true,
        'showCurrentPage': true
      }" show-select class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="primary" dark class="ma-2" @click="printSelectedVouchers">
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              </template>
              <span>Print Selected Vouchers</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="primary" dark class="ma-2" @click="generateExpenseReportForProject">
                  <v-icon>mdi-table-arrow-down</v-icon>
                </v-btn>
              </template>
              <span>Generate Project Expense Report</span>
            </v-tooltip>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" @keyup="searchDebounced" single-line hide-details />
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="primary" dark class="ma-2" @click="openDialog">
                  <v-icon>mdi-plus-box</v-icon>
                </v-btn>
              </template>
              <span>Add New Voucher</span>
            </v-tooltip>
          </v-toolbar>
        </template>
        <template v-slot:item.voucherNumber="{ item }">
          <v-icon v-if="item.contentType">mdi-paperclip</v-icon>
          {{ item.voucherNumber }}
        </template>
        <template v-slot:item.amount="{ item }">
          {{$n(item.amount, 'currency')}}
        </template>
        <template v-slot:item.voucherDate="{ item }">
          {{ $d(new Date(item.voucherDate), 'short') }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" small class="mr-2" @click="editVoucher(item)">mdi-pencil</v-icon>
            </template>
            <span>Edit Voucher</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" small @click="deleteVoucher(item.id)">mdi-delete</v-icon>
            </template>
            <span>Delete Voucher</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <div>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-row dense>
              <v-menu v-model="expenseReportDateStartMenu" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{on}">
                  <v-text-field class="ma-2" label="Date From" prepend-inner-icon="mdi-calendar" readonly
                    :value="expenseReportDateStartDisp" v-on="on">
                  </v-text-field>
                </template>
                <v-date-picker v-model="expenseReportDates.dateStart" no-title @input="expenseReportDateStartMenu = false"></v-date-picker>
              </v-menu>
              <v-menu v-model="expenseReportDateEndMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{on}">
                  <v-text-field class="ma-2" label="Date To" prepend-inner-icon="mdi-calendar" readonly
                    :value="expenseReportDateEndDisp" v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="expenseReportDates.dateEnd" no-title @input="expenseReportDateEndMenu = false"></v-date-picker>
              </v-menu>
            </v-row>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-btn color="primary lighten-1" dark class="ma-2" @click="generateExpenseReport">Generate Expense Report by date</v-btn>
            <v-btn color="primary lighten-1" dark class="ma-2" @click="printVouchersByDate">Print Vouchers by date</v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <save-voucher-dialog></save-voucher-dialog>
  </v-container>
</template>

<script>
import VoucherDataService from '../../services/VoucherDataService'
import SaveVoucherDialog from './SaveVoucherDialog'

export default {
  name: 'ListVouchersComponent',
  components: { SaveVoucherDialog },
  data () {
    return {
      search: '',
      loading: true,
      selectedVouchers: [],
      options: {},
      errors: {},
      headers: [
        { text: 'Voucher Number', align: 'left', sortable: true, value: 'voucherNumber' },
        { text: 'Date', align: 'left', sortable: true, value: 'voucherDate' },
        { text: 'Paid To', align: 'left', sortable: true, value: 'paidTo' },
        { text: 'Description', align: 'left', sortable: true, value: 'description' },
        { text: 'Receipt Number', align: 'left', sortable: true, value: 'receiptNo' },
        { text: 'Category', align: 'left', sortable: true, value: 'categoryDescription' },
        { text: 'Amount', align: 'right', sortable: true, value: 'amount' },
        { text: 'Actions', align: 'center', sortable: false, value: 'action' }
      ],
      expenseReportDateStartMenu: false,
      expenseReportDateEndMenu: false,
      expenseReportDates: {
        projectNumbers: [],
        dateStart: '',
        dateEnd: ''
      },
      searchTimer: null
    }
  },
  computed: {
    projectNumber () {
      return this.$store.getters['project/project'].projectNumber
    },
    renderKey () {
      return this.$store.getters['expenses/renderKey']
    },
    vouchers () {
      return this.$store.getters['expenses/expenses']
    },
    totalVouchers () {
      return this.$store.getters['expenses/totalExpenses']
    },
    expenseReportDateStartDisp () {
      return this.expenseReportDates.dateStart
    },
    expenseReportDateEndDisp () {
      return this.expenseReportDates.dateEnd
    }
  },
  created () {
    this.getDataFromApi(this.projectNumber)
  },
  watch: {
    options: {
      handler () {
        this.getDataFromApi(this.projectNumber)
      },
      deep: true
    }
  },
  methods: {
    searchDebounced () {
      clearTimeout(this.searchTimer)
      if (this.search === '' || this.search == null || this.search.length >= 3) {
        this.searchTimer = setTimeout(() => {
          this.getDataFromApi(this.projectNumber)
        }, 500)
      }
    },
    getDataFromApi (projectNumber) {
      this.loading = true
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (itemsPerPage > 0) {
          let searchParams = {}
          searchParams.page = page
          searchParams.itemsPerPage = itemsPerPage
          if (sortBy.length > 0) {
            searchParams.sortBy = sortBy[0]
          }
          if (sortDesc.length > 0) {
            searchParams.sortDesc = sortDesc[0]
          }
          searchParams.projectNumber = projectNumber
          if (this.search) {
            searchParams.searchTerm = this.search
          }
          let payload = {}
          payload.projectNumber = projectNumber
          payload.options = searchParams
          this.$store.dispatch('expenses/getDataFromApi', payload)
          this.loading = false
        }
      })
    },
    editVoucher (item) {
      this.$store.dispatch('expenses/getExpense', item.id).then(response => {
        this.$store.commit('expenses/showDialog')
      })
    },
    deleteVoucher (id) {
      if (confirm('Do you want to delete this Voucher?')) {
        this.$store.dispatch('expenses/deleteExpense', id).then(response => {
          this.$store.commit('expenses/setRenderKey')
        })
        this.$store.dispatch('expenses/getTotalSpendAmount', this.$store.getters['project/project'].projectNumber)
      }
    },
    printSelectedVouchers () {
      let voucherNumbers = []
      this.selectedVouchers.forEach(function (voucher) {
        voucherNumbers.push(voucher.voucherNumber)
      })
      this.$store.commit('setOverlay', { text: 'Generating Vouchers to be Printed', overlayState: true })
      return VoucherDataService.printSelectedVouchers(voucherNumbers).then(response => {
        this.$store.commit('setOverlay', { text: 'Generating Vouchers to be Printed', overlayState: false })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.projectNumber + '_vouchers.pdf')
        document.body.appendChild(link)
        link.click()
      }, reject => {
        this.$store.commit('setOverlay', { text: 'Generating Vouchers to be Printed', overlayState: false })
        this.$store.commit('showMessage', { message: 'Print Selected Voucher/s failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
      })
    },
    generateExpenseReport () {
      this.$store.commit('setOverlay', { text: 'Generating Expense Report', overlayState: true })
      return VoucherDataService.generateExpenseReport(this.expenseReportDates).then(response => {
        this.$store.commit('setOverlay', { text: 'Generating Expense Report', overlayState: false })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.projectNumber + '_expensereport.xlsx')
        document.body.appendChild(link)
        link.click()
      }, reject => {
        this.$store.commit('setOverlay', { text: 'Generating Expense Report', overlayState: false })
        this.$store.commit('showMessage', { message: 'Generate Expense Report failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
      })
    },
    generateExpenseReportForProject () {
      this.$store.commit('setOverlay', { text: 'Generating Expense Report', overlayState: true })
      return VoucherDataService.generateExpenseReportForProject(this.projectNumber).then(response => {
        this.$store.commit('setOverlay', { text: 'Generating Expense Report', overlayState: false })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.projectNumber + '_expensereport.xlsx')
        document.body.appendChild(link)
        link.click()
      }, reject => {
        this.$store.commit('setOverlay', { text: 'Generating Expense Report', overlayState: false })
        this.$store.commit('showMessage', { message: 'Generate Expense Report failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
      })
    },
    printVouchersByDate () {
      this.$store.commit('setOverlay', { text: 'Generating Vouchers to be Printed', overlayState: true })
      return VoucherDataService.printVouchersForDate(this.expenseReportDates).then(response => {
        this.$store.commit('setOverlay', { text: 'Generating Vouchers to be Printed', overlayState: false })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.projectNumber + '_vouchers.pdf')
        document.body.appendChild(link)
        link.click()
      })
    },
    openDialog () {
      // set voucher to default
      this.$store.commit('expenses/setEditedVoucherSuccess', Object.assign({}, this.$store.getters['expenses/defaultVoucher']))
      this.$store.commit('expenses/setExpenseProjectNumber', this.projectNumber)
      // open dialog box
      this.$store.commit('expenses/showDialog')
    }
  }
}
</script>

<style scoped>

</style>
