import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const numberFormats = {
  'en-US': {
    currency: {
      // currencyDisplay must be symbol - narrowSymbol is not supported on iOS :(
      style: 'currency', currency: 'PHP', currencyDisplay: 'symbol', currencySign: 'accounting'
    },
    percentage: {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  }
}
const dateTimeFormats = {
  'en-US': {
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric', weekday: 'short', hour: 'numeric', minute: 'numeric'
    }
  }
}

export const i18n = new VueI18n({ numberFormats, dateTimeFormats })
