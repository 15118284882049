<template>
  <v-snackbar top right :color="color" v-model="snackbar" timeout="10000">
    {{message}}
    <v-btn dark text @click="snackbar = false">Close</v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Snackbar',
  data () {
    return {
      snackbar: false,
      message: '',
      color: ''
    }
  },
  created () {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'showMessage') {
        this.message = state.snackbarMessage
        this.color = state.snackbarColor
        this.snackbar = true
      }
    })
  }
}
</script>

<style scoped>

</style>
