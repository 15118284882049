<template>
  <v-card class="mx-auto" flat>
    <v-card-title>
      <span class="display-1">Welcome!</span>
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="tab" class="elevation-1" background-color="primary" dark>
        <v-tabs-slider></v-tabs-slider>
        <v-tab>All Projects</v-tab>
        <v-tab>Ongoing Projects</v-tab>
        <v-tab>Completed Projects</v-tab>
        <v-tab-item>
          <v-row dense>
            <template v-for="(project, index) in allProjects">
              <v-col :key="index" cols="12" md="4">
                <display-project-details-as-card :project="project"></display-project-details-as-card>
              </v-col>
            </template>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row dense>
            <template v-for="(project, index) in allProjects.filter(project => project.ongoing)">
              <v-col :key="index" cols="12" md="4">
                <display-project-details-as-card :project="project"></display-project-details-as-card>
              </v-col>
            </template>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row dense>
            <template v-for="(project, index) in allProjects.filter(project => !project.ongoing)">
              <v-col :key="index" cols="12" md="4">
                <display-project-details-as-card :project="project"></display-project-details-as-card>
              </v-col>
            </template>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-card-text>
  </v-card>
</template>
<script>
import ProjectDataService from '../services/ProjectDataService'
import DisplayProjectDetailsAsCard from '../components/project/DisplayProjectDetailsAsCard'
import ClientDataService from '../services/ClientDataService'
import UserDataService from '../services/UserDataService'
import VoucherDataService from '../services/VoucherDataService'
import BOMDataService from '../services/BOMDataService'
import EmployeeDataService from '../services/EmployeeDataService'
import LocationDataService from '@/services/LocationDataService'

export default {
  name: 'LandingPage',
  components: { DisplayProjectDetailsAsCard },
  data () {
    return {
      tab: null,
      allProjects: []
    }
  },
  created () {
    this.getAllProjects()
    this.getAllClients()
    this.getAllUsers()
    this.getBOMCategories()
    this.getVoucherCategories()
    this.getUnits()
    this.getYears()
    this.getEmployeeIdTypes()
    this.getRoles()
    this.getLocations()
  },
  methods: {
    getAllProjects () {
      this.$store.commit('setOverlay', { text: 'Fetching Projects, please wait...', overlayState: true })
      return ProjectDataService.getAllProjects().then(response => {
        this.$store.commit('setOverlay', { text: 'Fetching Projects, please wait...', overlayState: false })
        this.allProjects = response.data
      }, reject => {
        this.$store.commit('setOverlay', { text: 'Fetching Projects, please wait...', overlayState: false })
        this.$store.commit('showMessage', { message: 'Fetching Projects failed with Status ' + reject.response.status + ': ' + reject.response.data, color: 'error' })
      })
    },
    getAllClients () {
      return ClientDataService.retrieveAllClients().then(response => {
        this.$store.commit('setClients', response.data)
      })
    },
    getAllUsers () {
      return UserDataService.retrieveAllUsers().then(response => {
        this.$store.commit('setUsers', response.data)
      })
    },
    getVoucherCategories () {
      if (this.$store.getters.voucherCategories.length === 0) {
        return VoucherDataService.retrieveVoucherCategories().then(response => {
          this.$store.commit('setVoucherCategories', response.data)
        })
      }
    },
    getBOMCategories () {
      if (this.$store.getters.bomCategories.length === 0) {
        return BOMDataService.retrieveBOMCategories().then(response => {
          this.$store.commit('setBOMCategories', response.data)
        })
      }
    },
    getUnits () {
      if (this.$store.getters.units.length === 0) {
        return BOMDataService.retrieveUnits().then(response => {
          this.$store.commit('setUnits', response.data)
        })
      }
    },
    getYears () {
      return ProjectDataService.retrieveYears().then(response => {
        this.$store.commit('setYears', response.data)
      })
    },
    getEmployeeIdTypes () {
      if (this.$store.getters.employeeIdTypes.length === 0) {
        return EmployeeDataService.getEmployeeIdTypes().then(response => {
          this.$store.commit('setEmployeeIdTypes', response.data)
        })
      }
    },
    getRoles () {
      if (this.$store.getters.roles.length === 0) {
        return UserDataService.getRoles().then(response => {
          this.$store.commit('setRoles', response.data)
        })
      }
    },
    async getLocations () {
      let response = await LocationDataService.allLocations()
      this.$store.commit('setLocations', response.data)
    }
  }
}
</script>

<style scoped>
  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
</style>
