<template>
  <div>
    <list-b-o-m-items-component ref="bomitems" :parentProject="parentProject" :bomnumber="bomnumber" @purchases="updatePurchases()"></list-b-o-m-items-component>
    <list-purchases-component ref="purchases" :parentProject="parentProject" :bomnumber="bomnumber" @bomItems="updateBomItems()"></list-purchases-component>
    <v-btn color="primary" dark class="mb-2"
           :to="'/' + bom.projectDto.id + '/billofmaterials'">Back to Bill Of Materials</v-btn>
  </div>
</template>

<script>
import ListBOMItemsComponent from '../components/bom/ListBOMItemsComponent'
import ListPurchasesComponent from '../components/purchase/ListPurchasesComponent'
import BOMDataService from '../services/BOMDataService'
export default {
  name: 'BOMDetails',
  props: {
    parentProject: Object,
    bomnumber: String
  },
  components: { ListPurchasesComponent, ListBOMItemsComponent },
  data () {
    return {
      bom: {
        projectDto: {}
      }
    }
  },
  created () {
    BOMDataService.getBomById(this.$route.params.bomnumber).then((response, reject) => {
      this.setData(response.data)
    })
  },
  methods: {
    setData (data) {
      this.bom = data
      this.$refs.bomitems.setBom(this.bom)
      this.$refs.bomitems.getDataFromApi(this.bom.bomNumber)
      this.$refs.purchases.setBom(this.bom)
      this.$refs.purchases.getDataFromApi(this.bom.bomNumber)
      this.$refs.purchases.getBomItemsByBomNumber(this.bom.bomNumber)
    },
    updatePurchases () {
      this.$refs.purchases.getBomItemsByBomNumber(this.bom.bomNumber)
      this.$refs.purchases.getDataFromApi(this.bom.bomNumber)
    },
    updateBomItems () {
      this.$refs.bomitems.getDataFromApi(this.bom.bomNumber)
    }
  }
}
</script>

<style scoped>

</style>
